import React, { useState } from 'react';

import { Layers } from '@carbon/icons-react';
import { IconOnlyButton } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuCheckboxItem from 'components/DropdownMenu/DropdownMenuCheckboxItem';
import DropdownMenuHeading from 'components/DropdownMenu/DropdownMenuHeading';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { formatMessage } from 'utils/messages/utils';

interface LayersToggleProps {
  isMapLoaded: boolean;
}

const LayersToggle: React.FC<LayersToggleProps> = ({ isMapLoaded }) => {
  const { isHighwaysLayerVisible, setIsHighwaysLayerVisible } = useDedicatedMapProvider();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownMenu
      placement="top-end"
      open={isDropdownOpen}
      onOpenChange={() => isMapLoaded && setIsDropdownOpen((prev) => !prev)}
      content={
        <>
          <DropdownMenuHeading>
            <>{formatMessage('LAYERS')}</>
          </DropdownMenuHeading>
          <DropdownMenuCheckboxItem
            checked={isHighwaysLayerVisible}
            textValue={formatMessage('HIGHWAYS')}
            onCheckedChange={() => setIsHighwaysLayerVisible((prev) => !prev)}
          />
        </>
      }
      target={
        <IconOnlyButton
          text={null}
          minimal
          disabled={!isMapLoaded}
          type="button"
          icon={Layers}
          data-testid="highlight-layers-button"
          active={isDropdownOpen}
        />
      }
    />
  );
};

export default LayersToggle;
