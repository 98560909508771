import React, { useState, useEffect } from 'react';

import SearchableTagInput from 'app/components/SearchableTagInput/SearchableTagInput';

import { useScope } from 'app/contexts/scopeProvider';

import { HierarchyItem, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandedHierarchyRule.module.pcss';

const b = block(style);

export const getHierarchyItemStrings = (hierarchyItems: HierarchyItem[]): string[] => {
  return hierarchyItems?.map((inclusionOrExclusion) => {
    return inclusionOrExclusion?.name;
  });
};

interface ExpandedHierarchyRuleProps {
  rootHierarchyName: string;
  rootHierarchyId: number;
  hierarchyType: HierarchyType;
  inclusions: HierarchyItem[];
  onUpdate: (remoteFilter: HierarchyItem[], hierarchyId: number) => void;
}

const ExpandedHierarchyRule: React.FC<ExpandedHierarchyRuleProps> = ({
  rootHierarchyName,
  rootHierarchyId,
  hierarchyType,
  onUpdate,
  inclusions = []
}: ExpandedHierarchyRuleProps) => {
  const [newInclusions, setNewInclusions] = useState([...inclusions]);
  const { selectedPlanningCycle } = useScope();

  // This allows us to clear the inclusions from outside the component
  useEffect(() => {
    !inclusions.length && setNewInclusions([]);
  }, [inclusions]);

  const handleInclusionsChange = (values: HierarchyItem[]) => {
    setNewInclusions([...values]);
    onUpdate([...values], rootHierarchyId);
  };

  return (
    <div className={b()} data-testid="expanded-hierarchy-rule">
      <span className={b('hierarchyTitle')} data-testid="expanded-hierarchy-rule-title">
        {formatMessage('HIERARCHY', { rootHierarchyName })}
      </span>

      <SearchableTagInput
        items={newInclusions}
        valueFormatter={getHierarchyItemStrings}
        rootHierarchyId={rootHierarchyId}
        planningCycleId={selectedPlanningCycle?.id}
        hierarchyType={hierarchyType}
        onChange={handleInclusionsChange}
        data-testid="expanded-hierarchy-rule-inclusions-input"
        isRuleTypeTerritory={false}
        isOverrideRule={false}
        isDefinitionFilters={true}
      />
    </div>
  );
};

export default ExpandedHierarchyRule;
