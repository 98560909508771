import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetMapVisualizationSettings, GetMapVisualizationSettingsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_MAP_VISUALIZATION_SETTINGS = gql`
  query GetMapVisualizationSettings($input: GetDeploymentModelSpecInput!, $planningCycleId: Int!) {
    getDeploymentModelSpec(input: $input) {
      battlecards {
        mapVisualizationSettings {
          customHierarchyQuantifier
          customHierarchyId
          territoryGroupType
        }
      }
    }
    getRootHierarchies(planningCycleId: $planningCycleId, version: 1) {
      rootHierarchyId
      rootKey
      rootName
    }
  }
`;

export const useGetMapVisualizationSettings = createUseQuery<
  GetMapVisualizationSettings,
  GetMapVisualizationSettingsVariables
>(GET_MAP_VISUALIZATION_SETTINGS);
