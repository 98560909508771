import { GetAccountRuleGrid_getAccountRuleGrid_accountRules } from 'app/graphql/generated/apolloTypes';

import { AccountRuleRow, GridFields } from 'app/models';

export const formatAccountList = (
  accountList: GetAccountRuleGrid_getAccountRuleGrid_accountRules[]
): AccountRuleRow[] => {
  return accountList.map((account) => {
    const dynamicColumnData = {};
    for (const column of account.dynamicColumns) {
      dynamicColumnData[column.colId] = parseOrNull(column.value);
    }

    return {
      [GridFields.ACCOUNT_ID]: account.accountId,
      [GridFields.ACCOUNT_KEY]: account.accountKey,
      [GridFields.ACCOUNT_NAME]: account.accountName,
      [GridFields.RULE_ID]: account.rule?.ruleId,
      [GridFields.TERRITORY_ID]: account.rule?.territoryId,
      [GridFields.TERRITORY_NAME]: account.rule?.territoryName,
      [GridFields.TERRITORY_GROUP]: account.rule?.territoryGroupName,
      dynamic: dynamicColumnData
    };
  });
};

const parseOrNull = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};
