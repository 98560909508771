import React, { useEffect, useState } from 'react';

import { Filter, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyQuerySpec, HierarchyType, CollectionFilterKind, HierarchySpec, HierarchyItem } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import ExpandedHierarchyRule from './BalancingPanel/ExpandedHierarchyRule/ExpandedHierarchyRule';
import style from './TerritoryBalancingGridRuleFilter.module.pcss';

const b = block(style);

export interface CustomHierarchyFilter {
  items: HierarchyItem[];
  kind: CollectionFilterKind;
}

export interface CustomHierarchiesFilters {
  [key: string]: CustomHierarchyFilter;
}

const TerritoryBalancingGridRuleFilter: React.FC<{
  handleDefinitionFiltersUpdate: (selectedFilters: CustomHierarchiesFilters) => void;
}> = ({ handleDefinitionFiltersUpdate }) => {
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();
  const { selectedBattleCardId } = useBattleCard();
  const [customRootHierarchies, setCustomRootHierarchies] = useState<HierarchySpec[]>([]);
  const [allHierarchiesFilters, setAllHierarchiesFilters] = useState<CustomHierarchiesFilters>({});

  const useLazyGetRootHierarchies = createUseLazyQuery<HierarchyQuerySpec, { planningCycleId: number }>(
    GET_ROOT_HIERARCHIES
  );

  const BASE_CUSTOM_HIERARCHY_FILTER: CustomHierarchyFilter = { items: [], kind: CollectionFilterKind.EQUALS };

  const activeFiltersCount = Object.keys(allHierarchiesFilters).reduce((count, key) => {
    return allHierarchiesFilters[key].items.length > 0 ? count + 1 : count;
  }, 0);

  const [getRootHierarchies, { data: rootHierarchies, loading: isRootHierarchiesLoading }] = useLazyGetRootHierarchies({
    fetchPolicy: 'network-only',
    variables: { planningCycleId: selectedPlanningCycle?.id },
    onError() {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
    }
  });

  useEffect(() => {
    getRootHierarchies();
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (rootHierarchies?.getRootHierarchies) {
      const fetchedRootCustomHierarchies = rootHierarchies?.getRootHierarchies.filter(
        (hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomHierarchy
      );
      setCustomRootHierarchies(fetchedRootCustomHierarchies);
    }
  }, [rootHierarchies]);

  const getInclusions = (hierarchyId: number): HierarchyItem[] => {
    return allHierarchiesFilters[hierarchyId]?.items || BASE_CUSTOM_HIERARCHY_FILTER.items;
  };

  const handleHierarchiesFilterUpdate = (remoteFilter: HierarchyItem[], hierarchyId: number): void => {
    setAllHierarchiesFilters((prevState) => ({
      ...prevState,
      [hierarchyId]: {
        kind: CollectionFilterKind.EQUALS,
        items: remoteFilter
      }
    }));
  };

  const handleFiltersClear = (): void => {
    setAllHierarchiesFilters({});
    handleDefinitionFiltersUpdate({});
  };

  const getFiltersTextButton = (): JSX.Element => {
    const isButtonDisabled = isRootHierarchiesLoading || !customRootHierarchies.length;
    const shouldShowNoCHTooltip = !isRootHierarchiesLoading && !customRootHierarchies.length;

    const buttonText = isRootHierarchiesLoading ? (
      <div className="bp3-skeleton" data-testid="searchable-select-menu-loading" />
    ) : (
      <div data-testid="definition-filters-button">
        {formatMessage('FILTERS')}
        {activeFiltersCount ? (
          <span data-testid="filter-count" className={b('filterCount')}>
            {activeFiltersCount}
          </span>
        ) : null}
      </div>
    );

    return (
      <MessageTooltip
        data-testid="definition-filters-tooltip"
        content={formatMessage('NO_CUSTOM_HIERARCHIES_TOOLTIP')}
        placement="top"
        disabled={!shouldShowNoCHTooltip}
        target={
          <TextButton
            text={buttonText}
            type="button"
            minimal
            large={false}
            icon={<Filter size={20} />}
            rightIcon={<ChevronDown size={20} />}
            testId={`filter-button${isButtonDisabled ? '-disabled' : ''}`}
            disabled={isButtonDisabled}
          />
        }
      />
    );
  };

  return (
    <div className={b()}>
      <div className={b('container')}>
        <Popover
          content={
            <div className={b('filterWrapper')} data-testid="custom-hierarchy-filter">
              {customRootHierarchies.map((customHierarchyItem, index) => (
                <div key={index}>
                  <ExpandedHierarchyRule
                    rootHierarchyId={customHierarchyItem.rootHierarchyId}
                    rootHierarchyName={customHierarchyItem.rootName}
                    hierarchyType={HierarchyType.CustomHierarchy}
                    inclusions={getInclusions(customHierarchyItem.rootHierarchyId)}
                    onUpdate={handleHierarchiesFilterUpdate}
                    key={`expanded-hierarchy-rule-${index}`}
                    data-testid={`expanded-hierarchy-rule-${index}`}
                  />
                </div>
              ))}
              <div className={b('buttonContainer')}>
                <TextButton
                  text={formatMessage('APPLY')}
                  testId="apply-button"
                  type="button"
                  large={false}
                  onClick={() => handleDefinitionFiltersUpdate(allHierarchiesFilters)}
                />
              </div>
            </div>
          }
          placement="bottom-start"
          minimal
        >
          {getFiltersTextButton()}
        </Popover>
        {activeFiltersCount > 0 && (
          <TextButton
            text={formatMessage('CLEAR_ALL')}
            type="button"
            minimal
            large={false}
            testId={'clear-button'}
            onClick={handleFiltersClear}
          />
        )}
      </div>
    </div>
  );
};

export default TerritoryBalancingGridRuleFilter;
