import { useQuery } from '@apollo/client';

import {
  GetInsightsCardStats,
  GetInsightsCardStatsVariables,
  TGTAggregationInput
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_INSIGHTS_CARD_STATS } from 'app/graphql/queries/getInsightsCardStats';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useInsightsCardStatsForGeoSelection = (
  skip: boolean,
  input: TGTAggregationInput
): {
  measureValue: number;
  isLoading: boolean;
  numberOfAccounts: number;
} => {
  const { data, loading, error } = useQuery<GetInsightsCardStats, GetInsightsCardStatsVariables>(
    GET_INSIGHTS_CARD_STATS,
    {
      skip,
      variables: { input },
      fetchPolicy: 'network-only',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('MAP_SELECTION_STATS_ERROR'), 'danger');
      }
    }
  );

  if (skip || error)
    return {
      measureValue: 0,
      numberOfAccounts: 0,
      isLoading: false
    };

  return {
    measureValue: 0,
    numberOfAccounts: 0,
    ...data?.getTGTAggregate,
    isLoading: loading
  };
};
