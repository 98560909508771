import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useScope } from 'app/contexts/scopeProvider';

import { GetGeoCatalogAndSelection, GetGeoCatalogAndSelectionVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_GEO_CATALOG_AND_SELECTION } from 'app/graphql/queries/getGeoCatalogAndSelection';

import { GetCatalogItem } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export interface GeoCatalogAndSelection {
  loading: boolean;
  availableItems: GetCatalogItem[];
  inUseItems: GetCatalogItem[];
}

export const useGeoCatalogAndSelection = (): GeoCatalogAndSelection => {
  const { selectedPlanningCycle } = useScope();

  const { loading, data } = useQuery<GetGeoCatalogAndSelection, GetGeoCatalogAndSelectionVariables>(
    GET_GEO_CATALOG_AND_SELECTION,
    {
      skip: !selectedPlanningCycle?.id,
      variables: {
        planningCycleId: selectedPlanningCycle?.id
      },
      onError({ graphQLErrors, networkError }) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('GEO_HIERARCHY_GET_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const formattedData = useMemo(() => {
    const availableItems = data?.getPlanningCycleSpec.availableCountrySourceCatalogItems ?? [];
    const inUseItems = data?.getPlanningCycleSpec.inUseCountrySourceCatalogItems ?? [];
    return { availableItems, inUseItems };
  }, [data]);

  return {
    loading,
    ...formattedData
  };
};
