import React, { FC, useMemo } from 'react';

import { PrimaryHierarchyTypeForMap } from 'app/workers/MapWorker/MapWorkerProtocol';

import { useMapVisualizationSettings } from 'app/components/TerritoryMap/hooks/useMapVisualizationSettings';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { BattleCardInfo, CommandCenterDrawerState, HierarchyType } from 'app/models';

import {
  MapContextRedistributorContextValues,
  MapContextRedistributorProvider
} from './mapContextRedistributorProvider';

type NativeMcrLoaderProps = {
  selectedBattleCardId?: string | null;
  selectedQuotaComponentId?: number | null;
};

export const NativeMcrLoader: FC<NativeMcrLoaderProps> = ({
  selectedBattleCardId: selectedBattleCardIdOverride,
  selectedQuotaComponentId: selectedQuotaComponentIdOverride,
  children
}) => {
  const {
    selectedBattleCardId: defaultSelectedBattleCardId,
    battleCardLookupMap,
    selectedQuotaComponentId: defaultSelectedQuotaComponentId
  } = useBattleCard();
  const { coinsortProgressLookupMap } = useCoinsort();
  const { commandCenterDrawerState } = useCommandCenter();
  const { optimizationTargetTgId } = useTerritoryOptimization();
  const { selectedDeploymentModelId } = useScope();

  const selectedBattleCardId = selectedBattleCardIdOverride ?? defaultSelectedBattleCardId;
  const selectedQuotaComponentId = selectedQuotaComponentIdOverride ?? defaultSelectedQuotaComponentId;

  // Transformed values
  const isMapPointerDisabled = commandCenterDrawerState === CommandCenterDrawerState.HALF;
  const coinsortStatus = coinsortProgressLookupMap[selectedBattleCardId]?.coinsortStatus ?? null;
  const selectedBattleCard = battleCardLookupMap[selectedBattleCardId] ?? null;

  const { savedCustomHierarchyPreference, shouldShowAccountFirstMap } = useMapVisualizationSettings();

  const { selectedTerritoryGroupTypeId, selectedTerritoryGroupId, primaryHierarchy } = useTgAndTgtForMap(
    selectedBattleCard,
    +optimizationTargetTgId,
    shouldShowAccountFirstMap
  );

  const contextValue = useMemo(
    (): MapContextRedistributorContextValues => ({
      // From useBattleCard
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      // Transformed
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      isEmbedded: false,
      selectedDeploymentModelId,
      isBattlecardDataLoading: false,
      externalControlSuffix: '',
      primaryHierarchy,
      savedCustomHierarchyPreference
    }),
    [
      selectedDeploymentModelId,
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      primaryHierarchy,
      savedCustomHierarchyPreference
    ]
  );

  return <MapContextRedistributorProvider value={contextValue}> {children}</MapContextRedistributorProvider>;
};

const useTgAndTgtForMap = (
  selectedBattleCard: BattleCardInfo | null,
  optimizationTargetTgId: number | null,
  shouldShowAccountFirstMap: boolean
): {
  selectedTerritoryGroupId: number | null;
  selectedTerritoryGroupTypeId: number | null;
  primaryHierarchy: PrimaryHierarchyTypeForMap;
} => {
  return useMemo(() => {
    const geoTgtId = selectedBattleCard?.territoryGroupTypes.find(
      (tgt) => tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy
    )?.territoryGroupId;

    const accountTgtId = selectedBattleCard?.territoryGroupTypes.find(
      (tgt) => tgt.hierarchyType === HierarchyType.CustomerAccountHierarchy
    )?.territoryGroupId;

    if (shouldShowAccountFirstMap && accountTgtId && !optimizationTargetTgId) {
      return {
        selectedTerritoryGroupId: accountTgtId,
        selectedTerritoryGroupTypeId: accountTgtId,
        primaryHierarchy: HierarchyType.CustomerAccountHierarchy
      };
    }

    return {
      selectedTerritoryGroupId: optimizationTargetTgId || geoTgtId || null,
      selectedTerritoryGroupTypeId: geoTgtId || null,
      primaryHierarchy: HierarchyType.GeographicTerritoryHierarchy
    };
  }, [selectedBattleCard, optimizationTargetTgId, shouldShowAccountFirstMap]);
};
