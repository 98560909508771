import React from 'react';

import { LazyQueryExecFunction, useApolloClient } from '@apollo/client';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useGrid } from 'app/contexts/gridProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { ApolloErrorContainer } from 'app/graphql/apolloTypeOverrides';
import { GetJobProgress, GetJobProgressVariables, PCJobType } from 'app/graphql/generated/apolloTypes';
import { useGetJobProgressLazy } from 'app/graphql/queries/getJobProgress';
import { GET_PLANNING_CYCLES } from 'app/graphql/queries/getPlanningCycles';
import { GET_TENANT_WIDE_INFO } from 'app/graphql/queries/getTenantWideInfo';

import { JobStatus, LongRunningJob } from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseGetJobProgressProps {
  jobDetail: LongRunningJob;
  onPollSuccess: (data: GetJobProgress, stopPolling: () => void) => void;
  onPollError: (error: ApolloErrorContainer) => void;
}

export const useGetJobProgress = ({
  jobDetail,
  onPollSuccess,
  onPollError
}: UseGetJobProgressProps): {
  getJobProgress: LazyQueryExecFunction<GetJobProgress, GetJobProgressVariables>;
  data: GetJobProgress;
} => {
  const { pcJobsInProgress, setPcJobsInProgress } = useUser();
  const { setBulkDeleteTerritoryJobKey, setRefreshGrid } = useGrid();
  const apolloClient = useApolloClient();

  let planningCycleId;
  let planningCycleName;
  let jobType;
  let territoryRuleDeleteCount;
  let territoryRuleJobKey;

  if (jobDetail) {
    if ('planningCycleId' in jobDetail) {
      planningCycleId = jobDetail.planningCycleId;
      planningCycleName = jobDetail.planningCycleName;
      jobType = jobDetail.jobType;
    }
    if ('jobKey' in jobDetail) {
      // TODO TQP-8994 https://varicent.atlassian.net/browse/TQP-8994
      // currently this value will always be undefine need to set this value in order to show toast with number of rules that are deleted
      territoryRuleDeleteCount = jobDetail?.deleteRuleCount;
      territoryRuleJobKey = jobDetail.jobKey;
    }
  }

  const handlePcJobComplete = (stopPolling) => {
    stopPolling();
    apolloClient.refetchQueries({ include: [GET_TENANT_WIDE_INFO, GET_PLANNING_CYCLES] });
    setPcJobsInProgress(
      pcJobsInProgress?.filter((pc) => pc.planningCycleId !== planningCycleId || pc.jobType !== jobType)
    );
  };

  const handleTerritoryRuleDeleteJobComplete = (stopPolling) => {
    stopPolling();
    setRefreshGrid(true);
    setBulkDeleteTerritoryJobKey(null);
  };

  const [getJobProgress, { data, stopPolling }] = useGetJobProgressLazy({
    pollInterval: 2000,
    fetchPolicy: 'network-only',
    onCompleted(response) {
      onPollSuccess?.(response, stopPolling);

      /* ******************************************************************************************** */
      /* NB: THE CODE BELOW CONTAINS VERY SPECIFIC BUSINESS LOGIC THAT SHOULD NOT BE IN THIS FILE.    */
      /* FUTURE ATTEMPTS TO USE THIS HOOK SHOULD MAKE USE OF THE ONPOLLSUCCESS AND ONERROR CALLBACKS. */
      /* WE SHOULD NOT BE ADDING SIDE EFFECTS TO A HOOK THAT IS MEANT TO BE GENERIC.                  */
      /* PLEASE ASK @lauraalkhoury-varicent FOR HELP IF NEEDED.                                       */
      /* ******************************************************************************************** */

      // show toast for planningCycleJob
      if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.COMPLETED) {
        handlePcJobComplete(stopPolling);
        if (jobType === PCJobType.PLANNING_CYCLE_CLONE) {
          const messageCopied = formatMessage('PLANNING_CYCLE_COPIED_DESCRIPTION', {
            pcName: planningCycleName
          });
          // eslint-disable-next-line deprecation/deprecation
          showToast(
            <ToastMessage title={formatMessage('PLANNING_CYCLE_COPIED')} message={messageCopied} />,
            'success',
            'top-right'
          );
        }
      }
      if (response?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.FAILED) {
        handlePcJobComplete(stopPolling);
        if (jobType === PCJobType.PLANNING_CYCLE_CLONE) {
          // eslint-disable-next-line deprecation/deprecation
          showToast(formatMessage('PLANNING_CYCLE_COPIED_FAILED'), 'danger', 'top-right');
        }
      }

      // show toast for territory rule deletion job
      if (response?.getJobProgress?.territoryRuleDeleteJobOutput?.territoryRulesDeleteStatus === JobStatus.COMPLETED) {
        handleTerritoryRuleDeleteJobComplete(stopPolling);
        const title = formatMessage('TERRITORIES_DELETED_TOAST_TITLE');
        const message = territoryRuleDeleteCount
          ? formatMessage('TERRITORIES_DELETED_TOAST_MESSAGE', { count: territoryRuleDeleteCount })
          : formatMessage('TERRITORIES_DELETED_TOAST_MESSAGE_GENERIC');
        // eslint-disable-next-line deprecation/deprecation
        showToast(<ToastMessage title={title} message={message} />, 'success', 'top-right');
      }
      if (response?.getJobProgress?.territoryRuleDeleteJobOutput?.territoryRulesDeleteStatus === JobStatus.FAILED) {
        handleTerritoryRuleDeleteJobComplete(stopPolling);
        const responseErrorMessage = response?.getJobProgress?.territoryRuleDeleteJobOutput?.message;
        const title = formatMessage('FAIL_BULK_DELETE_TERRITORY_TITLE');
        const message = responseErrorMessage.includes('overlay')
          ? formatMessage('DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE_GENERIC')
          : formatMessage('FAIL_BULK_DELETE_TERRITORY_GENERIC');
        const timeout = responseErrorMessage.includes('overlay') ? 8000 : 5000;
        // eslint-disable-next-line deprecation/deprecation
        showToast(<ToastMessage title={title} message={message} />, 'danger', 'top-right', timeout);
      }
    },
    onError(error) {
      onPollError?.(error);
      stopPolling();

      /* ******************************************************************************************** */
      /* NB: THE CODE BELOW CONTAINS VERY SPECIFIC BUSINESS LOGIC THAT SHOULD NOT BE IN THIS FILE.    */
      /* FUTURE ATTEMPTS TO USE THIS HOOK SHOULD MAKE USE OF THE ONPOLLSUCCESS AND ONERROR CALLBACKS. */
      /* WE SHOULD NOT BE ADDING SIDE EFFECTS TO A HOOK THAT IS MEANT TO BE GENERIC.                  */
      /* PLEASE ASK @lauraalkhoury-varicent FOR HELP IF NEEDED.                                       */
      /* ******************************************************************************************** */

      if (planningCycleId) {
        handlePcJobComplete(stopPolling);
      } else if (territoryRuleJobKey) {
        handleTerritoryRuleDeleteJobComplete(stopPolling);
      }
      if (jobType === PCJobType.PLANNING_CYCLE_CLONE) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('PLANNING_CYCLE_COPIED_FAILED'), 'danger');
      } else if (jobType === PCJobType.START_DATE_CHANGE) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(
          <ToastMessage
            title={formatMessage('EDIT_PLANNING_CYCLE_FAILURE_TITLE')}
            message={formatMessage('UPDATE_PC_FAILURE', { planningCycleName })}
          />,
          'danger',
          'top-right'
        );
      } else if (territoryRuleJobKey) {
        const title = formatMessage('FAIL_BULK_DELETE_TERRITORY_TITLE');
        const message = formatMessage('FAIL_BULK_DELETE_TERRITORY_GENERIC');
        // eslint-disable-next-line deprecation/deprecation
        showToast(<ToastMessage title={title} message={message} />, 'danger', 'top-right');
      }
    }
  });
  return { getJobProgress, data };
};
