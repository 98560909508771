import { useCallback } from 'react';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { ApolloOverrides } from 'app/graphql/apolloTypeOverrides';
import { UpsertGeoHierarchySelectionInput, UpsertGeoSelection } from 'app/graphql/generated/apolloTypes';
import { useUpsertGeoSelectionRaw } from 'app/graphql/mutations/upsertGeoSelection';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useUpsertGeoSelection = ({
  onCompleted
}: {
  onCompleted: () => void;
}): [
  (input: Partial<UpsertGeoHierarchySelectionInput>) => Promise<void>,
  ApolloOverrides.MutationResult<UpsertGeoSelection>
] => {
  const { selectedPlanningCycle } = useScope();

  // Command center queries are not connected to listeners, and must be manually re-invoked
  const { setForceRefresh } = useCommandCenter();
  const forceCommandCenterRefresh = () => setForceRefresh(true);

  const [rawUpsert, rest] = useUpsertGeoSelectionRaw({
    onCompleted: () => {
      forceCommandCenterRefresh();
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('GEO_HIERARCHY_UPDATED_SUCCESS'), 'success');
      onCompleted();
    },
    onError(error) {
      if (error.knownError?.errorCode !== 'REMOVE_GEO_HIER_HAS_DEPENDENCY') {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('GEO_HIERARCHY_UPDATED_ERROR'), 'danger');
      }
    },
    refetchQueries: ['HierarchyPolygonId', 'GetGeoCatalogAndSelection', 'GetRootHierarchies', 'GetInUseCountryLevels']
  });

  const upsertSelection = useCallback(
    async (input: Partial<UpsertGeoHierarchySelectionInput>) => {
      await rawUpsert({ variables: { input: { planningCycleId: selectedPlanningCycle?.id, ...input } } });
    },
    [rawUpsert, selectedPlanningCycle?.id]
  );

  return [upsertSelection, rest];
};
