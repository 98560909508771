import React, { useMemo } from 'react';

import { Layer } from 'react-map-gl';

import {
  createCountryCodeFilter,
  getAllCountriesFromSourceGroups,
  highwaysPaint
} from 'app/components/TerritoryMap/PolygonLayerHelpers';

import { SourceGroup } from 'app/models';

import { LayerIds } from './MapStyleTokens';

interface HighwaysLayerProps {
  sourceGroups: SourceGroup[];
  isClustersVisible: boolean;
  isHeatMapVisible: boolean;
}

const HighwaysLayer: React.FC<HighwaysLayerProps> = ({ sourceGroups, isClustersVisible, isHeatMapVisible }) => {
  let targetLayerId: string;

  if (isClustersVisible) {
    targetLayerId = LayerIds.account.cluster.interactiveCircle;
  } else if (isHeatMapVisible) {
    targetLayerId = LayerIds.account.heat.heat;
  }

  const countryFilter = useMemo(
    () => createCountryCodeFilter(getAllCountriesFromSourceGroups(sourceGroups)),
    [sourceGroups]
  );

  return (
    <Layer
      id={LayerIds.mapboxLayers.highways}
      type="line"
      source="composite"
      source-layer="road"
      paint={highwaysPaint}
      filter={countryFilter}
      beforeId={targetLayerId}
    />
  );
};

export default HighwaysLayer;
