import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetAccountRuleBindingsForQuotaDrillIn,
  GetAccountRuleBindingsForQuotaDrillInVariables
} from 'app/graphql/generated/apolloTypes';

export const GET_ACCOUNT_RULE_BINDINGS_FOR_QUOTA_DRILL_IN = gql`
  query GetAccountRuleBindingsForQuotaDrillIn($input: GetAccountRuleBindingsInput!) {
    getAccountRuleBindings(input: $input) {
      totalCount
      bindings {
        hierarchies {
          key
          name
          hierarchyId
        }
        measures {
          measureId
          measureValue
        }
      }
      measureTotals {
        measureTotal
      }
    }
  }
`;

export const useGetAccountRuleBindingsForQuotaDrillInLazy = createUseLazyQuery<
  GetAccountRuleBindingsForQuotaDrillIn,
  GetAccountRuleBindingsForQuotaDrillInVariables
>(GET_ACCOUNT_RULE_BINDINGS_FOR_QUOTA_DRILL_IN);
