import React, { ReactElement, useMemo } from 'react';

import { ArrowRight } from '@carbon/icons-react';
import { Radio, RadioGroup, Text, Spinner, Intent } from '@varicent/components';
import { Form, Formik } from 'formik';

import { BasicSelectField } from 'components/BasicSelect/BasicSelectField';
import Dialog from 'components/Dialog/Dialog';

import { useGetRootCustomHierarchiesInfo } from 'app/components/TerritoryMap/hooks/useGetRootCustomHierarchies';
import Combinator from 'app/components/TerritoryRuleBuilder/Combinator';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { CustomHierarchyQuantifierEnum, VisualizationTerritoryGroupTypeEnum } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useUpsertBattleCard } from 'app/graphql/mutations/upsertBattlecard';
import { GET_MAP_VISUALIZATION_SETTINGS } from 'app/graphql/queries/getMapVisualizationSettings';
import { useGetTerritoryGroupTypesForMap } from 'app/graphql/queries/getTerritoryGroupTypesForMap';

import useTreatment from 'app/hooks/useTreatment';

import { CombinatorType, HierarchyType, MchQuantity, MchQuantityType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapVisualizationSettingsDialog.module.pcss';

const b = block(style);

interface MapVisualizationSettingsDialogProps {
  onClose: () => void;
  onComplete: () => void;
  isLastStep: boolean;
}

interface FormValues {
  territoryGroupType: string;
  quantity: MchQuantityType;
  customHierarchyRoot: number;
}

const MapVisualizationSettingsDialog: React.FC<MapVisualizationSettingsDialogProps> = ({
  onClose,
  onComplete,
  isLastStep
}) => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedDeploymentModelId } = useScope();
  const { primaryHierarchy } = useMapContextRedistributor();
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const [isSingleCustomHierarchyMapOn] = useTreatment(SplitFeatures.MAP_SINGLE_CUSTOM_HIERARCHY);
  const { rootCustomHierarchies } = useGetRootCustomHierarchiesInfo(!isSingleCustomHierarchyMapOn);

  const [upsertBattlecard, { loading: upsertBattlecardLoading }] = useUpsertBattleCard({
    onCompleted() {
      onComplete();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const { data: battleCardData, loading: battleCardLoading } = useGetTerritoryGroupTypesForMap({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        battlecardId: +selectedBattleCardId,
        isTQM: false,
        deploymentModelId: selectedDeploymentModelId
      }
    }
  });

  const mappableTerritoryGroupTypes = useMemo(() => {
    return (
      battleCardData?.getDeploymentModelSpec.battlecards[0].territoryGroupTypes.filter(
        (tgt) =>
          tgt.hierarchyType === HierarchyType.CustomerAccountHierarchy ||
          tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy
      ) ?? []
    );
  }, [battleCardData]);

  const formattedTerritoryGroupTypes = mappableTerritoryGroupTypes.map((tgt) => ({
    text: tgt.hierarchyRootName,
    value: tgt.hierarchyType
  }));

  const formattedRootItems = useMemo(() => {
    return rootCustomHierarchies.map((root) => ({ text: root.rootName, value: root.rootHierarchyId }));
  }, [rootCustomHierarchies]);

  const handleSubmit = (values: FormValues) => {
    const vizSettings = {
      territoryGroupType: values.territoryGroupType as VisualizationTerritoryGroupTypeEnum,
      customHierarchyQuantifier: values.quantity as CustomHierarchyQuantifierEnum,
      customHierarchyId:
        values.customHierarchyRoot && values.quantity !== CustomHierarchyQuantifierEnum.NONE
          ? values.customHierarchyRoot
          : null
    };

    upsertBattlecard({
      variables: {
        input: {
          battlecardId: +selectedBattleCardId,
          deploymentModelId: selectedDeploymentModelId,
          mapVisualizationSettings: vizSettings
        }
      },
      refetchQueries: [GET_MAP_VISUALIZATION_SETTINGS]
    });
  };

  const renderCustomHierarchyName = (customHierarchyRootId: number, quantity: MchQuantityType): ReactElement => {
    if (!isSingleCustomHierarchyMapOn) return <Text>{formatMessage('CUSTOM_HIERARCHY_RULES')}</Text>;

    const customHierarchyRootName = rootCustomHierarchies.find(
      (root) => root.rootHierarchyId === customHierarchyRootId
    )?.rootName;

    const customHierarchyNameText =
      quantity === CustomHierarchyQuantifierEnum.NONE || !customHierarchyRootId
        ? formatMessage('DYNAMIC_HIERARCHY_PLACEHOLDER')
        : customHierarchyRootName;

    return <Text data-testid="custom-hierarchy-name">{customHierarchyNameText}</Text>;
  };

  const initialCustomHierarchyRoot =
    chosenCustomHierarchy.quantity === MchQuantity.SINGULAR
      ? chosenCustomHierarchy.details.rootHierarchyId
      : formattedRootItems[0]?.value;
  const initialFormValues: FormValues = {
    territoryGroupType: primaryHierarchy,
    quantity: chosenCustomHierarchy.quantity,
    customHierarchyRoot: initialCustomHierarchyRoot ?? null
  };

  return (
    <Formik initialValues={initialFormValues} enableReinitialize={true} onSubmit={handleSubmit}>
      {({ setFieldValue, values }) => (
        <Dialog
          portalClassName={b('dialog')}
          isOpen
          title={formatMessage('RULE_VIZ_SETTINGS_DIALOG_TITLE')}
          onClose={onClose}
          confirmButtonText={isLastStep ? formatMessage('MAP') : formatMessage('NEXT')}
          onSubmit={() => handleSubmit(values)}
          confirmButtonLoading={upsertBattlecardLoading}
          confirmButtonIcon={isLastStep ? null : <ArrowRight />}
        >
          {!battleCardLoading ? (
            <div className={b('dialogContainer')}>
              {mappableTerritoryGroupTypes.length > 1 && (
                <Text className={b('dialogBodyHeader')}>{formatMessage('RULE_VIZ_SETTINGS_DIALOG_BODY_HEADER')}</Text>
              )}
              <Form>
                <div className={b('formContainer')}>
                  {mappableTerritoryGroupTypes.length > 1 && (
                    <>
                      <Text className={b('formLabel')}>{formatMessage('TERRITORY_GROUP_TYPE')}</Text>
                      <BasicSelectField
                        name="territoryGroupType"
                        label="territoryGroupType"
                        items={formattedTerritoryGroupTypes}
                        placement="bottom-start"
                        fill
                        showIconOnButton={false}
                      />
                    </>
                  )}
                  <div className={b('radioGroupContainer')}>
                    <Text className={b('formLabel', { isRadioLabel: true })}>
                      {formatMessage('RULE_VIZ_SETTINGS_DIALOG_BODY_DESCRIPTION')}
                    </Text>
                    <RadioGroup
                      name="quantity"
                      className={b('radioGroup')}
                      selectedValue={values.quantity}
                      onChange={(event) => setFieldValue('quantity', event.currentTarget.value as MchQuantityType)}
                    >
                      <Radio
                        className={b('radio', {
                          isAccount: values?.territoryGroupType === HierarchyType.CustomerAccountHierarchy
                        })}
                        inline
                        value={CustomHierarchyQuantifierEnum.NONE}
                        labelElement={
                          <div>
                            <strong>
                              {formatMessage('RULE_VIZ_SETTINGS_DIALOG_RULE_TYPE_LABEL', {
                                ruleType:
                                  values?.territoryGroupType === HierarchyType.GeographicTerritoryHierarchy
                                    ? formatMessage('GEOGRAPHY')
                                    : formatMessage('ACCOUNT')
                              })}
                            </strong>
                            {values?.territoryGroupType === HierarchyType.GeographicTerritoryHierarchy && (
                              <div className={b('radioDescription')}>
                                <Text className={b('formLabel')}>{formatMessage('GEOGRAPHY')}</Text>
                                <Combinator
                                  data-testid="geography-rules-combinator-or"
                                  combinatorType={CombinatorType.OR}
                                />
                                <Text className={b('formLabel')}>{formatMessage('ACCOUNT_OVERRIDES')}</Text>
                              </div>
                            )}
                          </div>
                        }
                      />
                      <Radio
                        data-testid="complex-visual-radio"
                        value={
                          isSingleCustomHierarchyMapOn
                            ? CustomHierarchyQuantifierEnum.SINGULAR
                            : CustomHierarchyQuantifierEnum.ALL
                        }
                        disabled={isSingleCustomHierarchyMapOn && rootCustomHierarchies.length === 0}
                        inline
                        className={b('radio')}
                        labelElement={
                          <div>
                            <strong>{formatMessage('COMPLEX_RULES')}</strong>
                            <div className={b('radioDescription')}>
                              {values?.territoryGroupType === HierarchyType.GeographicTerritoryHierarchy
                                ? formatMessage('GEOGRAPHY')
                                : formatMessage('ACCOUNT')}
                              <Combinator
                                data-testid="complex-rules-combinator-and"
                                combinatorType={CombinatorType.AND}
                              />
                              {renderCustomHierarchyName(values.customHierarchyRoot, values.quantity)}
                              {values?.territoryGroupType === HierarchyType.GeographicTerritoryHierarchy && (
                                <>
                                  <Combinator
                                    data-testid="complex-rules-combinator-or"
                                    combinatorType={CombinatorType.OR}
                                  />
                                  {formatMessage('ACCOUNT_OVERRIDES')}
                                </>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </RadioGroup>

                    {isSingleCustomHierarchyMapOn && values.quantity !== CustomHierarchyQuantifierEnum.NONE && (
                      <label className={b('hierarchyLabel')}>
                        {formatMessage('HIERARCHY')}
                        <BasicSelectField
                          name="customHierarchyRoot"
                          label="customHierarchyRoot"
                          items={formattedRootItems}
                          placement="bottom-start"
                          fill
                          showIconOnButton={false}
                          disabled={values.quantity !== CustomHierarchyQuantifierEnum.SINGULAR}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <div data-testid="spinner" className={b('spinnerContainer')}>
              <Spinner intent={Intent.PRIMARY} />
            </div>
          )}
        </Dialog>
      )}
    </Formik>
  );
};

export default MapVisualizationSettingsDialog;
