import enUS from './en-US';

/* allow anonymous default export */
/* eslint-disable import/no-anonymous-default-export */

/* disallow template strings in messages file */
/*eslint quotes: ["error", "single", { "avoidEscape": true }]*/

/* messages from this file should not be imported directly */

const ptPT: Partial<Record<keyof typeof enUS, string>> = {
  VARICENT_SP: 'Sales Planning da Varicent',
  SALES_PLANNING: 'Sales Planning',
  PLANNING_CYCLES: 'Ciclos de planeamento',
  TEXT_FILTERS: 'Filtros de texto',
  NEW_CYCLE: 'Novo ciclo',
  DATA: 'Dados',
  CREATED_WITH_DATE: 'Criado: {date}',
  DELETE: 'Eliminar',
  NEW: 'Novo',
  RUN: 'Executar',
  NEW_PLANNING_CYCLE: 'Novo ciclo de planeamento',
  PLANNING_CYCLE: 'Ciclo de planeamento',
  DONT_SAVE: 'Não guardar',
  SAVE: 'Guardar',
  CANCEL: 'Cancelar',
  VISUALIZE: 'Visualizar',
  VISUALIZATION_SETTINGS_DIALOG_EXPLANATION: 'Escolha os tipos de regras de território que deseja ver no mapa.',
  VISUALIZATION_SETTINGS_DIALOG_WARNING: 'Algumas ações não estão disponíveis quando se visualizam regras complexas.',
  BACK: 'Retroceder',
  COMPLETE: 'Concluir',
  UNTITLED: 'Sem título',
  START_DATE_REQUIRED_MARK: 'Data de início*',
  START_DATE_MONTH_REQUIRED_MARK: 'Data de início (mês) *',
  DATE_FORMAT_PLACEHOLDER: 'MM/DD/AAAA',
  INTERVAL_CYCLE_REQUIRED_MARK: 'Ciclo de intervalo *',
  PERIODICITY: 'Periodicidade',
  PLAN_DURATION: 'Duração do plano',
  CREATE: 'Criar',
  CREATE_USER_PROFILE: 'Criar perfil de utilizador',
  SOMETHING_WENT_WRONG: 'Ocorreu um erro',
  BULK_INVITE_ERROR_MESSAGE: 'Não foi possível enviar nenhum convite. Tente novamente',
  INVITE_EXCEEDS_BATCH_LIMIT: 'Não é possível convidar mais de 100 utilizadores de cada vez.',
  USER_PROFILE_ADDED: 'Perfil de utilizador adicionado',
  USER_PROFILE_CREATION_SUCCESS: 'Adicionámos {name} aos seus utilizadores.',
  ACCESS_GRANTED_TITLE: 'acesso de {systemRoleName} concedido',
  INVITATION_SENT: 'Foi enviado um convite.',
  INVITATIONS_SENT: 'Foram enviados convites a {count} utilizadores.',
  ENTER_EMPLOYEE_ID: 'Introduza uma ID de colaborador.',
  ENTER_FIRST_NAME: 'Introduza um nome próprio.',
  ENTER_LAST_NAME: 'Introduza um apelido.',
  ENTER_EMAIL: 'Introduza um e-mail.',
  ROLE_REQUIRED: 'Função *',
  CONTRIBUTOR_INVITE_CALLOUT: 'Este utilizador receberá acesso de contribuidor. Será enviado um convite.',
  ADMIN_INVITE_CALLOUT: 'Este utilizador receberá acesso de administrador. Será enviado um convite.',
  AN_ERROR_OCCURRED: 'Ocorreu um erro.',
  PLANNING_CYCLE_ERROR_EMPTY: 'Introduza um título para o seu ciclo de planeamento.',
  PLANNING_CYCLE_ERROR: 'Não foi possível recuperar os seus ciclos de planeamento do servidor.',
  PLANNING_CYCLE_COPY_PROGRESS_DESCRIPTION:
    'Estamos a copiar o seu ciclo de planeamento {name}. Não faça alterações ao seu ciclo de planeamento até que este processo esteja concluído',
  PLANNING_CYCLE_PROGRESS_HEADING: 'Cópia do ciclo de planeamento iniciada',
  PLANNING_CYCLE_COPIED: 'Ciclo de planeamento copiado',
  PLANNING_CYCLE_COPIED_DESCRIPTION: 'Copiámos o seu ciclo de planeamento {pcName}.',
  PLANNING_CYCLE_COPIED_FAILED: 'A cópia do ciclo de planeamento falhou',
  PLANNING_CYCLE_START_DATE_PROGRESS_HEADING: 'Gravação do ciclo de planeamento em curso',
  PLANNING_CYCLE_START_DATE_PROGRESS_DESCRIPTION:
    'Estamos a guardar alterações ao seu ciclo de planeamento {name}. Não faça alterações ao seu ciclo de planeamento até que este processo esteja concluído',
  CREATE_NEW_CYCLE_ERROR: 'Não é possível criar um novo ciclo de planeamento.',
  CREATE_NEW_CYCLE_SUCCESS: 'Criou o seu novo ciclo de planeamento.',
  NUM_OF_MONTHS: 'Número de meses',
  BATTLECARD_CANVAS_ERROR: 'Não foi possível recuperar os seus cartões de batalha do servidor.',
  BATTLECARD_ERROR: 'Não foi possível recuperar o seu cartão de batalha do servidor.',
  CREATE_NEW_BATTLECARD_ERROR: 'Não é possível criar um novo cartão de batalha.',
  CREATE_NEW_BATTLECARD_SUCCESS: 'Criou o seu novo cartão de batalha.',
  UPDATE_BATTLECARD_SUCCESS: 'Atualização do seu cartão de batalha.',
  UPDATE_BATTLECARD_ERROR: 'Não foi possível atualizar o seu cartão de batalha.',
  UPDATE_BATTLECARD_OWNER_ERROR: 'Não foi possível atualizar o titular do seu cartão de batalha.',
  UPDATE_ACTIVITY_FILES_ERROR: 'Não foi possível atualizar os seus ficheiros de atividade do cartão de batalha.',
  UPDATE_QUOTA_COMPONENT_ERROR: 'Não foi possível atualizar o componente de quota do cartão de batalha.',
  REMOVE_QUOTA_COMPONENT_ERROR:
    'Tem de selecionar os componentes de quota atribuídos ao elemento principal deste cartão de batalha.',
  DISALLOW_REMOVE_QUOTA_COMPONENT:
    'É necessário remover primeiro os componentes de quota referenciados nos cartões de batalha primários ou subordinados agregados antes de poderem ser removidos do cartão de batalha principal.',
  UPDATE_HIERARCHY_TOP_ERROR: 'Não é possível atualizar o topo da hierarquia.',
  UPDATE_NEW_BUSINESS_TARGET_SUCCESS: 'Atualização do novo objetivo comercial.',
  UPDATE_NEW_BUSINESS_TARGET_ERROR: 'Não foi possível atualizar o novo objetivo comercial no seu cartão de batalha.',
  BATTLECARD_MEASURES_ERROR: 'Não foi possível recuperar medidas para o cartão de batalha.',
  UNABLE_TO_RETRIEVE_HIERARCHIES: 'Não foi possível recuperar as suas hierarquias do servidor.',
  PIN_TYPE_ACCOUNT: 'Pinos da conta',
  PIN_TYPE_CUSTOM: 'Outros pinos',
  CLUSTERS: 'Clusters',
  HEAT_AND_PINS: 'Cores e pinos',
  HIDE_ACCOUNTS: 'Ocultar contas',
  DEPLOYMENT_MODEL_ERROR: 'Não foi possível recuperar o seu modelo de implementação do servidor.',
  PINS_ACTIVATED_TOAST_TITLE: 'Pinos ativados',
  PINS_ACTIVATED_TOAST_BODY:
    'Os pinos do cluster foram ativados. Agora pode interagir com os seus pinos neste modo de seleção.',
  CUSTOMER_PINS_ERROR: 'Não é possível recuperar os pinos do cliente.',
  MAP_PINS_ERROR: 'Não é possível recuperar os pinos do mapa.',
  ACCOUNT: 'Conta',
  ACCOUNT_WITH_COUNT: '{totalCount} conta',
  ACCOUNTS: 'Contas',
  ACCOUNTS_WITH_COUNT: '{totalCount} contas',
  ACCOUNT_MOVE_DESCRIPTION: 'Iremos transferir a sua conta {accountName} para {destinationText} em {startDate}.',
  ACCOUNT_MOVE_DESCRIPTION_2:
    'Iremos transferir a sua conta {accountName} de {sourceTerritoryId} para {targetText} em {startDate}.',
  ACCOUNT_MOVE_DIALOG_TITLE: 'Agendar transferência para {accountName}',
  ACCOUNT_MOVE_DIALOG_CONFIRM: 'Agendar transferência',
  ACCOUNT_MOVE_DIALOG_BODY:
    'Reveja o território selecionado e faça alterações conforme necessário. Em seguida, adicione uma data de início para quando esta transferência entrará em vigor.',
  SCHEDULE_ACCOUNT_MOVE: 'Agendar transferência de conta',
  ACCOUNT_MOVE_SCHEDULED: 'Transferência de conta agendada',
  ACCOUNT_EFFECTIVE_DATE_UPDATED: 'Datas efetivas da conta atualizadas',
  UPDATED_EFFECTED_DATES: 'Atualizámos as datas efetivas para as transferências de conta {accountName} agendadas.',
  ORIGINAL_TERRITORY: 'Território original',
  PREVIOUS_TERRITORY: 'Território anterior',
  GAP_DETECTED: 'Lacuna detetada',
  SCHEDULED_MOVE: 'Transferência agendada',
  SCHEDULED_MOVES_WITH_COUNT: '{totalCount} transferências agendadas',
  ONE_SCHEDULED_MOVE: '1 transferência agendada',
  ACCOUNT_NAME: 'Nome da conta',
  UNASSIGNED_TERRITORY: 'Território não atribuído',
  DELETE_REDIRECT: 'Eliminar transferência de conta agendada?',
  DELETE_REDIRECT_DIALOGUE_BODY:
    'Eliminar esta transferência agendada para a conta {accountName} criará uma lacuna e deixará a sua conta como não atribuída. Se optar por eliminar, selecione uma opção para cobrir a lacuna:',
  DELETE_REDIRECT_DIALOGUE_SOLO_DELETE_BODY: 'Se optar por eliminar, a conta permanecerá no seu território original.',
  DELETE_REDIRECT_START_RADIO: 'Ajustar a data de início da próxima transferência de conta agendada:',
  DELETE_REDIRECT_END_RADIO: 'Ajustar a data de fim da transferência de conta anterior:',
  DELETE_REDIRECT_ORIGINAL_RADIO: 'Transferir a conta de volta para o território original:',
  DELETE_REDIRECT_TOAST_TITLE: 'Transferência de conta agendada eliminada',
  DELETE_REDIRECT_TOAST_BODY_ORIGINAL:
    'Eliminámos a transferência de conta agendada para {accountName}. Iremos transferir esta conta de volta para o território original em {startDate}.',
  DELETE_REDIRECT_TOAST_BODY_END: 'Manteremos esta conta em {prevTerritoryName} até {endDate}.',
  DELETE_REDIRECT_TOAST_BODY_START: 'Iremos transferir esta conta para {nextTerritoryName} em {startDate}.',
  DELETE_REDIRECT_ERROR: 'Não é possível eliminar a transferência da conta.',
  CREATE_REDIRECT_ERROR: 'Não é possível transferir a conta.',
  UPDATE_REDIRECT_ERROR: 'Não foi possível atualizar a transferência da conta.',
  EFFECTIVE_DATE: 'Data de entrada em vigor',
  ADD_TERRITORY: 'Adicionar um território',
  ADD_THIS_TERRITORY: 'Adicionar este território',
  EMPTY_PANEL_TEXT: 'Adicionar um território da lista à esquerda',
  ALLOCATE_TOP_DOWN: 'Atribuir (de cima para baixo)',
  QUOTA_TARGET_TOP_DOWN: 'Objetivo da quota (de cima para baixo)',
  TERRITORY: 'Território',
  TERRITORY_WITH_COUNT: '{totalCount} território',
  TERRITORIES: 'Territórios',
  TERRITORIES_WITH_COUNT: '{totalCount} territórios',
  CURRENT_TERRITORY: 'Território atual',
  TERRITORY_TOGGLE_TOOLTIP: 'Isto permite-lhe selecionar os seus territórios existentes.',
  ACCOUNT_TOGGLE_TOOLTIP: 'Isto permite-lhe selecionar as suas contas existentes.',
  PLAN: 'Plano',
  MANAGE: 'Gerir',
  EXISTING: 'Existente',
  TOP_DOWN_TARGET: 'Objetivo de cima para baixo',
  QUOTA_ADJUSTMENTS: 'Ajustamentos de quotas',
  QUOTA: 'Quota',
  ADD_NUMBER: 'Adicionar número',
  BASED_ON: 'De acordo com',
  ALLOCATE_QUOTAS_BASED_ON: 'Atribuir quotas de acordo com',
  PRESERVE_QUOTA_ADJUSTMENTS: 'Preservar ajustamentos de quotas',
  PRESERVE_SET_SEASONALITY: 'Preservar a sazonalidade definida',
  TERRITORY_DEFINE_REFINE: 'Definição de território',
  CREATE_NEW_HIERARCHY_ERROR: 'Não é possível criar uma nova hierarquia.',
  CREATE_NEW_HIERARCHY_SUCCESS: 'Criou a sua nova hierarquia.',
  EDIT_TERRITORY_GROUP: 'Editar grupo territorial',
  DELETE_TERRITORY_GROUP: 'Eliminar grupo territorial',
  ADD_TERRITORY_GROUP: 'Adicionar grupo territorial',
  TERRITORY_GROUPS_ERROR: 'Não é possível recuperar grupos territoriais do servidor.',
  TERRITORY_GROUP_ERROR: 'Não foi possível recuperar as informações de grupo territorial do servidor.',
  COUNT_WITH_NUMBER: 'Contar {number}',
  COUNT_WITH_FRACTION: 'Contar {numerator} / {denominator}',
  AVERAGE: 'Média',
  AVERAGE_WITH_NUMBER: 'Média de {number}',
  STATUS: 'Estado',
  VIEW: 'Ver',
  VIEW_WITH_ELLIPSIS: 'Ver…',
  HIERARCHY_ERROR: 'Não é possível recuperar dados da hierarquia.',
  HIERARCHY_MOVE_ERROR: 'Não é possível transferir a hierarquia.',
  HIERARCHY_MOVE_SUCCESS: 'Hierarquia transferida com sucesso.',
  TOP_DOWN_ALLOCATION_ERROR: 'Não é possível realizar a atribuição.',
  CREATE_TERRITORY: 'Criar território',
  REASSIGN_GEOGRAPHIC_REGION: 'Reatribuir região geográfica',
  CREATE_NEW_TERRITORY_GROUP_SUCCESS: 'Criámos o seu novo grupo territorial.',
  CREATE_NEW_TERRITORY_GROUP_ERROR: 'Não é possível criar um novo grupo territorial.',
  NEW_BUSINESS_TARGET: 'Novo objetivo comercial',
  BUSINESS_TARGET: 'Objetivo comercial',
  PLANNED_TERRITORY_QUOTA: 'Quota planeada para o território',
  UPDATED_QUOTA: 'Quota atualizada',
  UNDERALLOCATED_BY: 'Subatribuído em {amount}',
  OVERALLOCATED_BY: 'Sobreatribuído em {amount}',
  SELECT_BATTLE_CARD_TO_VIEW_TERRITORIES:
    'Selecione um cartão de batalha principal, tipo de grupo territorial ou grupo territorial para ver os territórios',
  EMPTY_GRID: 'De momento não existem dados',
  NEW_BUSINESS_TARGET_MEASURE_PLACEHOLDER: 'Definir objetivo',
  BATTLECARD_MEASURE_PLACEHOLDER: 'Definir valor',
  COINSORT_PROCESS_SUCCESS: 'A ordenação foi executada com sucesso.',
  ACTIVITIES_SORTED_INTO: '{activityCount} atividades foram organizadas por {territoryCount} territórios.',
  ACTIVITIES_UNASSIGNED: '{activityCount} atividades não estão atribuídas.',
  COINSORT_PROCESS_FAILURE: 'Não é possível executar a ordenação de moedas.',
  COINSORT_FETCH_ERROR: 'Não foi possível obter o progresso da ordenação de moedas.',
  COINSORT_CANCEL_ERROR: 'Não é possível cancelar o processo de ordenação de moedas.',
  COINSORT_CHECK_ERROR: 'Não é possível determinar se é possível executar a ordenação de moedas.',
  COINSORT_RUN_ERROR_TITLE: 'A ordenação de moedas falhou',
  COINSORT_CANCEL_ERROR_DESC: 'A ordenação de moedas não está em execução, pelo que não há nada a cancelar.',
  COINSORT_GENERIC_ERROR_DESC:
    'Não foi possível executar a ordenação de moedas porque encontrámos alguns problemas. Atualize a página e tente novamente.',
  COINSORT_RUN_ERROR_DESC:
    'Não é possível executar a ordenação de moedas neste momento porque já existe uma instância de ordenação de moedas em execução. Aguarde pela conclusão da instância atual e tente novamente.',
  COINSORT_MISSING_TERRITORY_GROUP_ERROR_DESC:
    'Ainda não definiu grupos territoriais, por isso não podemos organizar as suas atividades de vendas. Crie alguns grupos territoriais e tente novamente.',
  COINSORT_ACCESS_DENIED_ERROR_DESC:
    'Lamentamos, mas não tem permissão para executar a ordenação de moedas. Contacte o seu administrador do Varicent Sales Planning para obter assistência.',
  COINSORT_MISSING_ACTIVITY_ERROR_DESC:
    'Não adicionou quaisquer dados de atividade de vendas para este cartão de batalha, por isso não temos nada para ordenar. Adicione dados de atividade e tente novamente.',
  COINSORT_MISSING_TERRITORY_RULE_ERROR_DESC:
    'Ainda não tem regras de território, pelo que não podemos organizar as suas atividades de vendas. Crie algumas regras de território e tente novamente.',
  DELETE_NEW_TERRITORY_GROUP_SUCCESS: 'Eliminação do seu grupo territorial.',
  DELETE_NEW_TERRITORY_GROUP_ERROR: 'Não foi possível eliminar o seu grupo territorial.',
  CONFIRM_DELETE: 'Tem a certeza de que pretende eliminar?',
  DELETE_GROUP_WARNING: 'Eliminar irá remover este grupo territorial.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_BODY:
    'Este grupo territorial tem territórios. Transfira os territórios para outro grupo territorial antes da eliminação.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_HEADER: 'Falha ao eliminar o grupo territorial',
  LOGOUT: 'Terminar sessão',
  TERRITORY_REQUIRED_MARK: 'Território *',
  TERRITORY_ID: 'ID do território',
  TERRITORY_ID_WITH_ID: 'ID do território {territoryID}',
  TERRITORY_NAME: 'Nome do território',
  TERRITORY_NAME_REQUIRED_MARK: 'Nome do território *',
  TERRITORY_NAME_WITH_COLON: 'Nome do território:',
  TERRITORY_NAME_WITH_VALUE: 'Nome do território {value}',
  TERRITORY_GROUP_REQUIRED: 'O grupo territorial é obrigatório',
  BALANCED: 'Equilibrado',
  BATTLE_CARD: 'Cartão de batalha',
  TERRITORY_GROUP_REQUIRED_MARK: 'Grupo territorial *',
  ENTER_TERRITORY_ID_FOR_RULE: 'Introduzir ID de território',
  ENTER_TERRITORY_NAME_FOR_RULE: 'Introduzir nome do território',
  CARD_TYPE_TEAM_FUNCTION: 'Tipo de cartão/função da equipa:',
  DEFINE_TERRITORY_GROUP_TYPES: 'Definir tipos de grupos territoriais',
  DEFINE_TERRITORY_GROUP_TYPES_DESCRIPTION:
    'Selecione um ou mais tipos de grupos territoriais, de acordo com as hierarquias carregadas, para definir como pretende organizar os territórios.',
  HIERARCHIES: 'Hierarquias',
  REFER_TO_AS: 'Referir como',
  TERRITORY_GROUP_TYPE: 'Tipo de grupo territorial',
  PRIMARY_TEAM: 'Equipa principal',
  PRIMARY_TEAM_WITH_CONSOLIDATE: 'Equipa principal (consolidar)',
  DIRECT_OVERLAY: 'Sobreposição (sem consolidação)',
  ROLL_UP: 'Agregação (consolidar)',
  COMMAND_CENTER: 'Centro de comando',
  ADMIN: 'Administrador',
  AVAILABLE_WITH_COUNT: '{count} disponível',
  ACCOUNT_OVERRIDES: 'precedências da conta',
  DEFAULT: 'Predefinição',
  DEFAULT_WITH_BRACKETS: '(Predefinição)',
  GEOGRAPHY_RULES_ONLY: 'Apenas regras de região geográfica',
  COMPLEX_RULES: 'Regras complexas',
  CUSTOM_HIERARCHY_RULES: 'regras de hierarquia personalizadas',
  DYNAMIC_HIERARCHY_PLACEHOLDER: '[hierarchy]',
  VISUALIZATION_SETTINGS: 'Visualização de regras',
  AND: 'E',
  OR: 'OU',
  GEOGRAPHY: 'Região geográfica',
  NUMBER_OF_ACCOUNTS: 'Número de contas',
  TOTAL_NUMBER_OF_ACCOUNTS: 'Número total de contas',
  NUMBER_OF_OVERRIDE_ACCOUNTS: 'Número de contas com precedência',
  INCLUDE_OVERRIDE_ACCOUNTS: 'Incluir contas de precedência',
  ADD_HIERARCHY: 'Adicionar hierarquia',
  USER_MANAGEMENT: 'Gestão de utilizadores',
  USERS: 'Utilizadores',
  COPIED_TO_CLIPBOARD: 'Copiada para a área de transferência',
  COPY_TO_CLIPBOARD: 'Copiar para a área de transferência',
  GENERATE_API_KEY: 'Gerar chave API',
  GENERATE_API_KEY_ERROR_MESSAGE: 'Não foi possível gerar chave API',
  GENERATE_KEY: 'Gerar chave',
  GENERATE_API_KEY_DESCRIPTION_1:
    'Utilize esta chave API para ligar a sua conta de canal à sua conta do Sales Planning.',
  GENERATE_API_KEY_DESCRIPTION_2:
    'O Sales Planning não armazena esta chave API. Uma vez gerada, copie e armazene-a num local seguro. Gerar uma nova chave invalidará a chave antiga.',
  API_KEY_GENERATED: 'Chave API gerada',
  API_KEY_COPIED: 'A chave API foi copiada para a área de transferência.',
  API_KEY_GENERATED_DESC_1:
    'O Sales Planning não armazena esta chave API. Copie e guarde esta chave API num local seguro.',
  API_KEY_GENERATED_DESC_2:
    'Depois de clicar em Concluído, esta chave não pode ser recuperada. Gerar uma nova chave invalidará a chave antiga.',
  EMAIL_PLACEHOLDER: 'johnsmith@company.com',
  PASSWORD: 'Palavra-passe',
  PASSWORD_CHANGE_SUCCESS: 'Palavra-passe alterada com sucesso',
  PASSWORD_CHANGE_ERROR: 'Não foi possível atualizar a sua palavra-passe. Tente novamente mais tarde',
  INVALID_INVITATION_ERROR: 'Ligação de convite inválida.',
  ADD_USER_TO_TENANT_SUCCESS: 'Foi adicionado(a) com sucesso à sua organização',
  ADD_USER_TO_TENANT_ERROR:
    'Não foi possível adicioná-lo(a) à sua organização. Contacte o seu administrador para obter um novo convite.',
  GLOBAL_MEASURES: 'Medidas globais',
  PLAN_TARGETS: 'Objetivos do plano',
  EXPANDED_COMPLETION_RATE: 'Taxa de conclusão:',
  QUOTA_APPROVAL_WORKFLOW: 'Fluxo de trabalho da aprovação de quotas',
  QUOTA_WORKFLOW: 'Fluxo de trabalho da quota',
  INITIATE_WORKFLOW_TEXT: 'Iniciar fluxo de trabalho',
  INITIATE_WORKFLOW_ERROR: 'Lamentamos! Falha ao iniciar o fluxo de trabalho.',
  CANCEL_WORKFLOW_TEXT: 'Cancelar fluxo de trabalho',
  CANCEL_WORKFLOW_ERROR: 'Lamentamos! Falha ao cancelar o fluxo de trabalho.',
  TERRITORY_GROUP: 'Grupo territorial',
  TERRIORY_GROUP_LEVEL: 'Nível do grupo territorial',
  AUTH_CHECK_FAILURE: 'Falha ao autenticar. Inicie sessão novamente',
  MEASURE_VALUE_TYPE_ERROR: 'O valor tem de ser um número.',
  QUOTA_COMPONENTS_ONE_OR_MANY_WITH_COLON: 'Componentes de quota:',
  QUOTA_BREAKDOWN_BY: 'Discriminação de quotas por',
  QUOTA_BREAKDOWN_VALIDATION_ERROR: 'Selecione apenas 2 hierarquias.',
  QUOTA_BREAKDOWN_BY_TOOLTIP:
    'Isto permite-lhe visualizar uma discriminação das quotas do seu território de acordo com as hierarquias selecionadas e ajustá-las em conformidade.',
  QUOTA_BREAKDOWN_BY_PLACEHOLDER_TEXT: 'Selecione até 2 hierarquias',
  HIERARCHY_BASED_TARGET_SETTING: 'Definição de objetivo baseado em hierarquia',
  HIERARCHY_BASED_TARGET_SETTING_TOOLTIP:
    'Selecione uma hierarquia a utilizar para definir objetivos de quotas específicas. Só pode selecionar uma hierarquia com {maxMemberCount} ou menos membros',
  ADD_NEW_QC: 'Adicionar novo componente de quota',
  ACTIVITY_FILE: 'Ficheiros de atividade',
  ACTIVITY_FILE_WITH_COLON: 'Ficheiros de atividade:',
  SELECT: 'Selecionar',
  REMOVE: 'Remover',
  REVOKE: 'Revogar',
  INVITE: 'Convidar',
  COMMENT: 'Comentar',
  COMMENT_ERROR: 'Não foi possível atualizar o comentário.',
  DATE: 'Data',
  EMAIL: 'E-mail',
  NUMERIC: 'Numérica',
  QUOTA_ALLOCATION: 'Atribuição de quotas:',
  TERRITORY_GROUPS: 'Grupos territoriais',
  UPDATE: 'Atualizar',
  ADD: 'Adicionar',
  ACTION: 'Ação',
  ACTIONS: 'Ações',
  ACTION_MENU_TOOLTIP:
    'Mostra uma lista de ações disponíveis de acordo com a sua região geográfica ou seleções de territórios.',
  ADD_TO_SELECTION: 'Adicionar à seleção',
  REMOVE_FROM_SELECTION: 'Remover da seleção',
  UPDATE_TERRITORY_GROUP_SUCCESS: 'Atualizou o seu grupo territorial.',
  UPDATE_TERRITORY_GROUP_ERROR: 'Não foi possível atualizar o seu grupo territorial.',
  NO_HIERARCHIES: 'Sem hierarquias',
  USER_MANAGEMENT_DROPDOWN_TOOLTIP: 'Selecione utilizadores com o mesmo tipo de função para enviar convites.',
  USER_MANAGEMENT_LOAD_USERS: 'Carregar utilizadores do ficheiro',
  USER_MANAGEMENT_ADMIN_INVITE: 'Convidar como administrador',
  USER_MANAGEMENT_ADMINS_INVITE: 'Convidar como administradores',
  USER_MANAGEMENT_RESEND_ADMIN_INVITE: 'Reenviar convite de administrador',
  USER_MANAGEMENT_CONTRIBUTOR_INVITE: 'Convidar como contribuidor',
  USER_SELECTION_FRACTION: '{selectionCount} / {totalCount} selecionado(s)',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE_BODY:
    'Tem a certeza de que pretende enviar convites a {selectionCount} utilizadores? Estes utilizadores receberão acesso de contribuidor.',
  USER_MANAGEMENT_ADMINS_INVITE_BODY:
    'Tem a certeza de que pretende enviar convites a {selectionCount} utilizadores? Estes utilizadores receberão acesso de administradores.',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE: 'Convidar como contribuidores',
  USER_MANAGEMENT_RESEND_CONTRIBUTOR_INVITE: 'Reenviar convite de contribuidor',
  USER_MANAGEMENT_MEMBER_REVOKED: 'Acesso de contribuidor revogado para o membro.',
  USER_MANAGEMENT_ADMIN_REVOKED: 'A função do membro foi atualizada para um contribuidor.',
  USER_MANAGEMENT_MEMBER_REVOKED_ERROR: 'Houve um problema ao revogar a função.',
  USER_MANAGEMENT_MEMBER_DELETED: 'O membro foi eliminado.',
  USER_MANAGEMENT_MEMBER_DELETED_ERROR: 'Ocorreu um problema ao eliminar o membro.',
  USER_MANAGEMENT_ADMIN_ACCESS: 'Acesso de administrador concedido. Foi enviado um e-mail ao membro.',
  USER_MANAGEMENT_CONTRIBUTOR_ACCESS: 'Recebeu acesso de contribuidor. Foi enviado um e-mail ao membro.',
  USER_MANAGEMENT_ACCESS_ERROR: 'Ocorreu um problema ao enviar o convite.',
  USER_MANAGEMENT_TABLE_EMPLOYEE_ID: 'ID de colaborador',
  USER_MANAGEMENT_TABLE_JOB_TITLE: 'Cargo',
  USER_MANAGEMENT_TABLE_ROLE_NAME: 'Nome da função',
  USER_MANAGEMENT_REVOKE_ADMIN: 'Revogar acesso de administrador',
  USER_MANAGEMENT_REVOKE_CONTRIBUTOR: 'Revogar acesso de contribuidor',
  USER_MANAGEMENT_DIALOG_REVOKE_HEADLINE: 'Revogar acesso de contribuidor?',
  USER_MANAGEMENT_DIALOG_REVOKE_BODY:
    'O perfil do utilizador e as credenciais de início de sessão permanecerão no sistema. No entanto, não terão acesso a esta organização.',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_HEADLINE: 'Revogar acesso de administrador?',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_BODY:
    'O perfil do utilizador permanecerá no sistema. A sua função será atualizada para contribuidor sem acesso de administrador.',
  USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE: 'Remover perfil do participante',
  USER_MANAGEMENT_DIALOG_REMOVE_BODY:
    'Se concluir esta ação, irá remover imediatamente este perfil de utilizador do sistema. Se este perfil tiver sido atribuído a um território, grupo ou quota, pode ocorrer um estado de erro.',
  USER_MANAGEMENT_DIALOG_INVITE_HEADLINE: 'Convidar membro como contribuidor',
  USER_MANAGEMENT_DIALOG_INVITE_BODY:
    'Isto enviará um convite por e-mail ao membro para que se torne um contribuidor. Deseja concluir esta ação?',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_HEADLINE: 'Convidar membro como administrador',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_BODY:
    'Isto enviará um convite por e-mail ao membro para que se torne um administrador. Deseja concluir esta ação?',
  ROLE_PERMISSIONS_TABLE_VIEW_HEADER_TOOLTIP:
    'A ativação das permissões de visualização dará a esta função acesso apenas à visualização do ciclo de planeamento.',
  ROLE_PERMISSIONS_TABLE_EDIT_HEADER_TOOLTIP:
    'A ativação das permissões de edição dará a esta função acesso à edição deste ciclo de planeamento. Para o separador Quota no Plano, certifique-se de que o fluxo de trabalho da quota está ativado.',
  ROLE_PERMISSIONS_TABLE_VIEW_CHECKBOX_TOOLTIP: 'O acesso à visualização só pode ser concedido ao nível mais alto',
  ROLE_PERMISSIONS_TABLE_EDIT_CHECKBOX_TOOLTIP:
    'Selecionar as permissões de edição também irá ativar as permissões de visualização.',
  TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP: 'Não tem permissão para editar isto.',
  EXPAND_ALL: 'Expandir tudo',
  COLLAPSE_ALL: 'Fechar tudo',
  ROLE_PERMISSIONS_TABLE_QUOTA_SHEET_INFO:
    'As permissões da folha de quotas podem ser modificadas no separador de dados no Centro de comandos',
  ROLES: 'Funções',
  ROLE_PERMISSIONS_UPSERT_ERROR: 'Não foi possível guardar as suas permissões. Tente novamente',
  ROLE_PERMISSIONS_UPSERT_SUCCESS: 'Atualizámos com sucesso as permissões para a função {role}',
  ROLE_PERMISSIONS_GET_ERROR: 'Não foi possível carregar as suas permissões.',
  CHANGE_REQUESTS: 'Pedidos de alteração',
  REVIEW_PENDING_CHANGE_REQUESTS: 'Rever pedidos de alteração pendentes.',
  SUBMIT_CHANGE_REQUEST: 'Enviar pedido de alteração',
  VIEW_CHANGE_REQUEST_LOG: 'Ver registo de pedidos de alteração',
  REQUEST_ID: 'ID do pedido',
  REQUESTED_BY: 'Solicitado por',
  DATE_REQUESTED: 'Data de pedido',
  DATE_REVIEWED: 'Data de revisão',
  APPROVE_CHANGE_REQUEST_DIALOG_BODY:
    'Quer aprovar este pedido de alteração? As alterações aprovadas serão apresentadas no tabuleiro de dados.',
  REJECT_CHANGE_REQUEST_DIALOG_BODY:
    'Tem a certeza de que pretende rejeitar este pedido de alteração? As alterações rejeitadas serão eliminadas.',
  ADD_A_COMMENT: 'Adicionar um comentário',
  APPROVE_CHANGE_REQUEST: 'Aprovar pedido de alteração',
  REJECT_CHANGE_REQUEST: 'Rejeitar pedido de alteração',
  CONTRIBUTOR_ROLES: 'Funções de contribuidor',
  ADMINISTRATOR_ACCESS_DESCRIPTION: 'Esta função de utilizador tem acesso a todas as funcionalidades',
  SEND_INVITATION: 'Enviar convite',
  RESEND_INVITATION: 'Reenviar convite',
  ADD_DESCRIPTION_PLACEHOLDER: 'Adicionar descrição...',
  EDIT_PLANNING_CYCLE: 'Editar ciclo de planeamento',
  EDIT_PLANNING_CYCLE_SUCCESS_TITLE: 'Alterações ao ciclo de planeamento guardadas',
  EDIT_PLANNING_CYCLE_FAILURE_TITLE: 'Falha na atualização do ciclo de planeamento',
  EDIT_NEW_CYCLE_SUCCESS: 'Atualizou o seu ciclo de planeamento.',
  EDIT_NEW_CYCLE_ERROR: 'Não é possível atualizar o ciclo de planeamento.',
  EDIT: 'Editar',
  EDIT_WITH_VALUE: 'Editar {value}',
  LOADING: 'A carregar...',
  STARTING: 'A iniciar...',
  STOPPING: 'A parar...',
  DELETE_BATTLECARD_SUCCESS: 'Eliminou o seu cartão de batalha.',
  DELETE_BATTLECARD_GENERIC_ERROR: 'Não foi possível eliminar o seu cartão de batalha.',
  DELETE_BATTLECARD_TERRITORY_RULE_ERROR:
    'Não é possível eliminar este cartão de batalha neste momento. Elimine todos os territórios do cartão de batalha e tente novamente.',
  NO_RESULTS: 'Sem resultados',
  TERRITORY_OWNER: 'Titular do território:',
  OWNERSHIP_VALUE_RANGE_ERROR: 'O valor deve ser 1-100.',
  PERCENTAGE_MEASURE_VALUE_RANGE_ERROR: 'O valor deve ser 0-100.',
  MEASURES: 'Medidas',
  BUILT_IN_MEASURES: 'Medidas incorporadas',
  CUSTOM_MEASURES: 'Medidas personalizadas',
  DESCRIPTION: 'Descrição',
  NAME: 'Nome',
  NAME_WITH_COLON: 'Nome:',
  TYPE: 'Tipo',
  TYPE_REQUIRED: '* Tipo:',
  ATTRIBUTE_NAME: 'Nome do atributo',
  ADD_CUSTOM_ATTRIBUTE: 'Adicionar atributo personalizado',
  ATTRIBUTE_NAME_MESSAGE: 'Introduza um nome de atributo',
  FORMAT: 'Formato',
  FORMAT_REQUIRED: '* Formato:',
  FORMULA: 'Fórmula',
  DATE_RANGE: 'Intervalo de datas',
  ADD_MEASURE: 'Adicionar medida',
  TERRITORY_OWNER_ERROR: 'Selecione um titular do território.',
  ASSIGNMENT_BUILDER_ADD_SUCCESS: 'A atribuição foi adicionada.',
  ASSIGNMENT_BUILDER_ADD_FAILURE: 'Não foi possível adicionar a tarefa.',
  ASSIGNMENT_BUILDER_UPDATE_SUCCESS: 'A atribuição foi atualizada.',
  ASSIGNMENT_BUILDER_UPDATE_FAILURE: 'Não é possível atualizar a atribuição.',
  TERRITORY_GROUP_TYPE_INCLUDE_MESSAGE: 'Tem de existir pelo menos um tipo de grupo territorial.',
  UPDATE_USER_SUCCESS: 'Perfil de utilizador atualizado com sucesso.',
  UPDATE_USER_ERROR: 'Ocorreu um erro ao atualizar o perfil de utilizador.',
  ADD_TERRITORIES_TOOLTIP:
    'Adicione territórios para poder realizar a atribuição descendente a este grupo territorial.',
  ADD_A_CARD: 'Adicionar um cartão',
  FIVE_HUNDRED: 'Ups! Há um problema com o nosso servidor. Tente novamente mais tarde!',
  FOUR_O_ONE: 'Lamentamos! Não tem autorização para ver a página.',
  FOUR_O_FOUR: 'Ups! A página que procura foi removida ou a ligação está corrompida.',
  INTERNAL_ERROR: 'Ups! Há um problema com a nossa aplicação. Tente novamente mais tarde!',
  UPSERT_TERRITORY_GROUP_OWNER_ERROR: 'Não é possível atualizar o titular do grupo territorial.',
  OWNER: 'Titular',
  OWNER_WITH_COLON: 'Titular:',
  CREATE_ORGANIZATION_TITLE: 'Crie a sua organização',
  NAME_YOUR_ORGANIZATION: 'Dê um nome à sua organização',
  FIRST_NAME: 'Nome próprio',
  LAST_NAME: 'Apelido',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME_PLACEHOLDER: 'Smith',
  ORGANIZATION_NAME_PLACEHOLDER: 'Mais de 8 caracteres',
  CREATE_ORGANIZATION: 'Criar organização',
  TERMS_OF_SERVICE: 'Termos de serviço',
  PRIVACY_POLICY: 'Política de privacidade',
  INVALID_EMAIL: 'E-mail inválido',
  ORGANIZATION_NAME_LENGTH_ERROR: 'O nome da organização deve ter pelo menos 2 caracteres.',
  ENTER_YOUR_ORGANIZATION_NAME: 'Introduza o nome da sua organização',
  BY_CLICKING: 'Ao clicar em {actionText}, concorda com os nossos',
  AND_OUR: 'e com as nossas',
  QUOTA_COMPONENT: 'Componente de quota',
  QUOTA_COMPONENT_WITH_COLON: 'Componente de quota:',
  QUOTA_COMPONENT_WITH_VALUE: '{value} componente de quota',
  QUOTA_COMPONENTS: 'Componentes de quota',
  QUOTA_COMPONENTS_WITH_VALUE: '{value} componentes de quota',
  QUOTA_COMPONENTS_UPDATE_SUCCESS: 'O componente de quota foi atualizado.',
  QUOTA_COMPONENTS_DELETED_SUCCESS: 'O componente de quota foi eliminado.',
  QUOTA_COMPONENTS_UPDATE_ERROR: 'Não foi possível atualizar o componente de quota.',
  NO_QUOTA_COMPONENTS: 'Sem componentes de quota',
  DELETE_QUOTA_COMPONENT: 'Eliminar componente de quota',
  CONFIRM_DELETE_QUOTA_COMPONENT: 'Tem a certeza de que pretende eliminar o componente de quota {name}?',
  NO_ACTIVITY_FILES: 'Sem ficheiros de atividade',
  NO_DATA: 'Sem dados',
  NO_FILE_SELECTED: 'Nenhum ficheiro selecionado',
  INSIGHTS: 'Informações',
  SELECTION_INSIGHTS: 'Informações de seleção',
  CLEAR_SELECTION: 'Limpar seleção',
  CLEAR_ALL: 'Limpar tudo',
  CLEAR: 'Limpar',
  CHOOSE_FILE_FOR_UPLOAD: 'Escolha um ficheiro CSV para carregar (* indica um cabeçalho necessário)',
  CHOOSE_A_FILE: 'Escolher um ficheiro',
  FILE_UPLOAD_VALIDATION_ERROR:
    'O ficheiro que selecionou, “{file}“, não tem uma extensão de ficheiro .csv. Selecione um ficheiro diferente ou adicione a extensão do ficheiro .csv ao nome do ficheiro.',
  BEGIN_UPLOAD_ERROR: 'Não foi possível iniciar o carregamento do ficheiro selecionado.',
  ADD_USERS: 'Adicionar utilizadores',
  PROCESSING_MESSAGE_PENDING: 'O ficheiro está a ser processado.',
  PROCESSING_MESSAGE_COMPLETED: 'O ficheiro foi processado com sucesso.',
  PROCESSING_MESSAGE_ERROR: 'O ficheiro não foi processado com sucesso.',
  PROCESSING_STATUS_PENDING: 'Pendente',
  PROCESSING_STATUS_ERROR: 'Erro',
  RETRIEVE_PROCESSING_STATUS_ERROR: 'Não foi possível recuperar o estado de processamento do ficheiro.',
  GET_FILE_HEADERS_ERROR: 'Não foi possível recuperar cabeçalhos do ficheiro.',
  NOTIFY_SERVER_ERROR: 'Não foi possível notificar o servidor sobre o estado do carregamento.',
  CHECK_REQUIRED_FILE_HEADERS: 'Verifique se os cabeçalhos de coluna necessários estão presentes no seu ficheiro.',
  BEGIN_FILE_UPLOAD: 'Iniciar o carregamento do ficheiro',
  FILE_PROCESSING_SUCCESS: 'Processamento de ficheiros bem-sucedido',
  FILE_PROCESSING_ERROR: 'Erro de processamento do ficheiro',
  FILE_NAME_VALIDATION_ERROR:
    'Não é possível importar este ficheiro porque o nome do ficheiro contém caracteres não suportados',
  FILE_COLUMN_VALIDATION_ERROR:
    'Não é possível importar este ficheiro porque o cabeçalho da coluna do ficheiro contém caracteres não suportados',
  FILE_NAME: 'Nome do ficheiro',
  SUMMARY: 'Resumo',
  IMPORT_DATA: 'Importar dados',
  SEARCH: 'Pesquisar',
  SUBMIT: 'Enviar',
  READONLY: 'Só de leitura',
  REJECT: 'Rejeitar',
  APPROVE: 'Aprovar',
  ADD_TERRITORY_ROW: 'Adicionar linha de território',
  LOAD_TERRITORIES: 'Carregar territórios',
  ADD_TERRITORIES: 'Adicionar territórios',
  ADD_CUSTOM_MEASURE_SUCCESS: 'A medida foi adicionada.',
  ADD_CUSTOM_MEASURE_FAILURE: 'Não é possível adicionar medida.',
  UPDATE_CUSTOM_MEASURE_SUCCESS: 'A medida foi atualizada.',
  UPDATE_CUSTOM_MEASURE_FAILURE: 'Não é possível atualizar a medida.',
  UPDATE_MEASURE: 'Atualizar medida',
  DELETE_CUSTOM_MEASURE_SUCCESS: 'A medida foi eliminada.',
  DELETE_CUSTOM_MEASURE_FAILURE: 'Não é possível eliminar a medida.',
  MEASURE_NAME_REQUIRED: 'Forneça um nome de coluna.',
  MEASURE_TYPE_REQUIRED: 'Selecione uma fonte.',
  MEASURE_FORMAT_REQUIRED: 'Selecione um formato.',
  MEASURE_SOURCE_REQUIRED: 'Selecione um tipo de tabela de dados.',
  FIELD_ID_REQUIRED: 'Selecione uma tabela de dados.',
  FIELD_NAME_REQUIRED: 'Selecione uma coluna.',
  CREATE_NEW_ORGANIZATION_SUCCESS: 'Criou a sua nova organização.',
  CREATE_NEW_ORGANIZATION_ERROR: 'Não é possível criar uma nova organização.',
  TERRITORY_DEFINITIONS: 'Definições de território',
  STALE_DATA_MESSAGE:
    'Está a visualizar dados antigos. Execute a ordenação para atualizar para os seus territórios mais recentes.',
  ASSIGNED_TOOLTIP: 'Incluir as regiões geográficas atribuídas',
  ASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir as contas atribuídas',
  ALL_TOOLTIP: 'Incluir todas as regiões geográficas',
  ALL_TOOLTIP_ACCOUNTS: 'Incluir todas as contas',
  UNASSIGNED_TOOLTIP: 'Incluir as regiões geográficas não atribuídas',
  UNASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir as contas não atribuídas',
  ASSIGNED: 'Atribuídas',
  UNASSIGNED: 'Não atribuídas',
  UNASSIGNED_LOWERCASE: 'não atribuídas',
  WILL_START: '{territoryName} irá começar',
  WILL_END: '{territoryName} irá terminar',
  UNABLE_TO_PROCESS_CONTRIBUTOR: 'Não é possível processar o seu pedido.',
  EDIT_TERRITORY: 'Editar território',
  QUOTA_COMPONENT_VALIDATION_ERROR: 'Selecione pelo menos um componente de quota.',
  EDIT_HIERARCHY: 'Editar hierarquia',
  EDIT_HIERARCHY_ERROR: 'Não é possível editar a sua hierarquia.',
  EDIT_HIERARCHY_SUCCESS: 'A hierarquia foi editada',
  ENTER_HIERARCHY_NAME: 'Introduza um nome para a sua hierarquia',
  HIERARCHY_NAME: 'Nome da hierarquia',
  UNSAVED_CHANGES: 'Tem certeza de que deseja sair? Tem algumas alterações não guardadas.',
  BATTLE_CARD_TITLE_VALIDATION_ERROR: 'Indique um nome para o cartão de batalha.',
  LEVEL_WITH_VALUE: 'Nível {value}',
  MULTIPLE_ACCOUNTS_TITLE: 'Várias contas',
  MULTIPLE_ACCOUNTS_BODY:
    'Esta região geográfica contém várias contas. Marque este cluster com um laço e veja a discriminação das contas no seu cartão de informações.',
  UNASSIGN: 'Anular atribuição',
  REASSIGN: 'Reatribuir',
  GET_ACTIVITIES_FOR_FILE_ERROR: 'Não é possível recuperar as atividades para o ficheiro.',
  GET_ACTIVITIES_FOR_TERRITORY_ERROR: 'Não é possível recuperar as atividades para o território.',
  GET_ACTIVITIES_FOR_TERRITORY_GROUP_ERROR: 'Não é possível recuperar as atividades para o grupo territorial.',
  GET_UNASSIGNED_ACTIVITIES_ERROR: 'Não é possível recuperar as atividades não atribuídas.',
  WORKFLOW_APPROVE_SUCCESS: 'O fluxo de trabalho {actionWorkflowName} foi aprovado!',
  WORKFLOW_APPROVE_FAILURE: 'Não é possível aprovar o fluxo de trabalho {actionWorkflowName}',
  WORKFLOW_REJECT_SUCCESS: 'O fluxo de trabalho {actionWorkflowName} foi rejeitado!',
  WORKFLOW_REJECT_FAILURE: 'Não é possível rejeitar o fluxo de trabalho {actionWorkflowName}',
  APPROVE_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} é responsável por aprovar este grupo territorial e pode estar em processo de alteração de quotas. Tem a certeza de que pretende aprovar?',
  REJECT_TERRITORY_GROUP_DIALOG_MESSAGE:
    '{approver} é responsável por rejeitar este grupo territorial e pode estar em processo de alteração de quotas. Tem a certeza de que pretende rejeitar?',
  APPROVE_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} é responsável por aprovar este cartão de batalha e pode estar em processo de alteração de quotas. Tem a certeza de que pretende aprovar?',
  REJECT_BATTLE_CARD_DIALOG_MESSAGE:
    '{approver} é responsável por rejeitar este cartão de batalha e pode estar em processo de alteração de quotas. Tem a certeza de que pretende rejeitar?',
  APPROVE_DIALOG_TITLE: 'Está a aprovar em nome de outro utilizador',
  REJECT_DIALOG_TITLE: 'Está a rejeitar em nome de outro utilizador',
  AVAILABLE_TERRITORY_ID: '"{territoryId}" está disponível no grupo territorial "{territoryGroup}".',
  UNAVAILABLE_TERRITORY_ID: '"{territoryId}" não está disponível porque já está a ser utilizado.',
  AVAILABLE_WITH_PLANNING_CYCLE_ID: 'está disponível com a ID do ciclo de planeamento',
  UNAVAILABLE_WITH_PLANNING_CYCLE_ID:
    '"{name}" não está disponível porque a ID do ciclo de planeamento "{slugName}" já está a ser utilizada.',
  YOUR_ORGANIZATION_URL_WILL_BE: 'O URL da sua organização será',
  PLANNING_CYCLE_NAME: 'Nome do ciclo de planeamento',
  NEXT: 'Seguinte',
  SIGN_IN: 'Iniciar sessão',
  ARCHIVE: 'Arquivar',
  ARCHIVE_WITH_TITLE: 'Arquivar {title}',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_1: 'Está prestes a arquivar este ciclo de planeamento:',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_2: 'Ficará guardado no arquivo por 30 dias.',
  PLANNING_CYCLE_ARCHIVE_SUCCESS: '{title} está agora arquivado.',
  PLANNING_CYCLE_ARCHIVE_FAILURE: 'Não é possível arquivar {title}',
  VIEW_ALL_ACTIVITIES: 'Ver todas as atividades',
  ADD_A_CHILD: 'Adicionar um elemento subordinado',
  KEY: 'Chave',
  ADDRESS1: 'Linha de endereço 1',
  ADDRESS2: 'Linha de endereço 2',
  CITY: 'Cidade',
  COUNTRY: 'País',
  INDUSTRY: 'Setor de atividade',
  ZIP_OR_POSTAL: 'Código postal',
  STATE_OR_PROVINCE: 'Estado ou província',
  GEOGRAPHIC_REGION: 'Região geográfica',
  GEO_REGION: 'Região geo',
  SELECTED_GEOGRAPHIC_REGIONS: 'Regiões geográficas selecionadas',
  SELECTED_TERRITORIES: 'Territórios selecionados',
  GEOGRAPHIC_REGION_TOGGLE_TOOLTIP:
    'Isto permite-lhe selecionar limites individuais com base no seu nível geográfico escolhido. Por exemplo, países, estados ou códigos postais.',
  SAVE_CHANGES: 'Guardar alterações',
  CONTACT_ADMIN_EMAIL: 'Contacte o seu administrador para alterar o seu endereço de e-mail.',
  REQUIRED_FIELD: 'Campo obrigatório',
  UPDATE_HIERARCHY_ERROR: 'Não é possível atualizar {rootName} "{name}".',
  ADDED_HIERARCHY: 'Adicionou {rootName} "{name}".',
  DETAILS: 'Detalhes',
  DELETE_SUCCESS_WITH_NAMES_QUOTED: '{nameOne} "{nameTwo}" eliminado com sucesso.',
  HIERARCHY_DELETE_ERROR_WITH_NAMES: 'Não é possível eliminar {rootName} "{name}".',
  HIERARCHY_EMPTY: 'Hierarquia vazia',
  CONFIRM_DELETE_HIERARCHY: 'Tem a certeza de que pretende eliminar {name}?',
  OPTIONS: 'Opções',
  DELETE_TERRITORY_GROUP_CONFIRM: 'Tem a certeza de que pretende eliminar este território?',
  DELETE_TERRITORY_SUCCESS: '{territoryId}: {territoryName} o território foi eliminado.',
  DELETE_TERRITORY_ERROR: 'Não é possível eliminar o território {territoryId}: {territoryName}.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE:
    'Não foi possível eliminar este território porque está a ser utilizado por um cartão de batalha sobreposto. Remova as referências a este território do cartão de batalha sobreposto e tente novamente.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE_GENERIC:
    'Não conseguimos eliminar os territórios selecionados porque algumas regras estão a ser utilizadas por um cartão de batalha sobreposto. Remova as referências a territórios selecionados do cartão de batalha sobreposto e tente novamente.',
  DELETE_TERRITORY_TEXT: 'Eliminar território',
  DELETE_TERRITORY_TEXT_WITH_ID_AND_NAME: 'Eliminar território {territoryId}: {territoryName}',
  DELETE_MULTIPLE_TERRITORIES_TEXT: 'Eliminar territórios',
  TERRITORY_DELETE_DIALOG_BODY: 'Pretendia eliminar o {count} território selecionado?',
  TERRITORIES_DELETE_DIALOG_BODY: 'Pretendia eliminar os {count} territórios selecionados?',
  CURRENCIES: 'Moedas',
  CURRENCIES_WITH_COUNT: '{count} moedas',
  CURRENCY: 'Moeda',
  CURRENCY_WITH_COUNT: '{count} moeda',
  REPORTING_CURRENCY: 'Moeda de apresentação',
  REPORTING_CURRENCY_WITH_VALUE: 'Moeda de apresentação ({value})',
  LOCAL_CURRENCY: 'Moeda local',
  LOCAL_CURRENCY_WITH_COLON: 'Moeda local:',
  REPORTING_AND_LOCAL_CURRENCIES: 'Moedas de apresentação e locais',
  AVAILABLE_CURRENCIES: 'Moedas disponíveis',
  REPORTING_CURRENCY_DEFAULT: 'Moeda de apresentação (predefinição)',
  REPORTING_CURRENCY_DESIGNATED: 'Moeda de apresentação (designada)',
  CONSTANT_CURRENCY_EXCHANGE_RATE: 'Taxa de câmbio constante',
  ADD_CURRENCY: 'Adicionar moeda',
  VALUES_SHOWN_IN_REPORTING_CURRENCY: 'Valores apresentados na moeda de apresentação ({value})',
  VALUES_SHOWN_IN_LOCAL_CURRENCY: 'Valores apresentados na moeda local ({value})',
  REPORTING_CURRENCY_IS: 'A moeda de apresentação é {value}',
  LOCAL_CURRENCY_IS: 'A moeda local é {value}',
  EMPTY_HIERARCHY_MESSAGE: 'Selecione um item na hierarquia para ver os respetivos detalhes.',
  ALL: 'Todos',
  SELECT_CURRENCY: 'Selecione uma moeda',
  SELECT_CURRENCY_ERROR_MESSAGE: 'Selecione uma moeda.',
  SELECT_CONVERSION_ERROR_MESSAGE: 'Introduza uma conversão.',
  CURRENCY_IN_USE: 'Moeda em utilização',
  DELETE_CURRENCY: 'Eliminar moeda',
  ADD_TERRITORY_GROUP_TYPE: 'Adicionar tipo de grupo territorial',
  KEY_EXISTS_ERROR: '{value} não está disponível porque já está a ser utilizado.',
  IS_AVAILABLE: 'está disponível.',
  NO_GRID_TERRITORIES: 'Não existem territórios para mostrar.',
  NO_GRID_TERRITORIES_ADDED: 'Ainda não adicionou quaisquer territórios.',
  NO_TERRITORY: 'Nenhum território',
  EQUALS_SIGN: '=',
  INVERT_EXCHANGE_RATES: 'Inverter taxas de câmbio',
  CURRENCY_PAGE_SUCCESS: 'Moedas atualizadas com sucesso',
  CURRENCY_PAGE_ERROR: 'Não foi possível atualizar as moedas.',
  DELETE_REPORTING_CURRENCY: 'Eliminar moeda de apresentação',
  CONFIRM_DELETE_REPORTING_CURRENCY:
    'Tem a certeza de que pretende eliminar esta moeda de apresentação? Todas as moedas locais associadas a esta moeda de apresentação também serão eliminadas.',
  DELETE_LOCAL_CURRENCY: 'Eliminar moeda local',
  CONFIRM_DELETE_LOCAL_CURRENCY: 'Tem a certeza de que pretende eliminar esta moeda local?',
  GET_CONVERSION_RATES_COUNT_ERROR: 'Não foi possível recuperar o número de moedas em utilização.',
  UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX: 'Utilize os seguintes cabeçalhos de coluna no seu carregamento: {value}',
  UPDATE_MEASURE_VALUE_ERROR: 'Não é possível atualizar o valor da medida.',
  FILE_UPLOAD_HEADER_ERROR_SINGLE: 'Falta o seguinte cabeçalho de coluna: {value}',
  FILE_UPLOAD_HEADER_ERROR_PLURAL: 'Faltam os seguintes cabeçalhos de coluna: {value}',
  FILE_UPLOAD_HEADER_ERROR_UNKNOWN: 'Ocorreu um erro ao validar este ficheiro.',
  SET_CONVERSION_RATE: 'Definir taxa de conversão',
  DELETE_HIERARCHY_WITH_NAME: 'Eliminar a {name} hierarquia?',
  DELETE_HIERARCHY_MEMBER_WITH_VALUE: 'Eliminar o {value} membro da hierarquia?',
  DELETE_HIERARCHY_MEMBERS_WITH_VALUE: 'Eliminar os {value} membros da hierarquia?',
  DELETE_HIERARCHY_SUCCESS: 'Hierarquia {name} eliminada com sucesso.',
  DELETE_HIERARCHY_ERROR: 'Não é possível eliminar a hierarquia {name}.',
  MIXED_CLUSTER_TOOLTIP: 'Este cluster contém contas de vários territórios',
  MIXED_CLUSTER_WITH_UNASSIGNED_TOOLTIP: 'Este cluster contém contas de vários territórios ou contas não atribuídas',
  OVERASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas que pertencem implicitamente a 2 ou mais territórios',
  UNASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas de contas não atribuídas',
  ASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas de {territoryId} ({territoryName})',
  TERRITORIES_AND_COUNT: 'Territórios ({count})',
  TERRITORY_ID_AND_NAME: '{territoryId} ({territoryName})',
  PRIOR_YEAR_ACTUAL: 'Resultados finais do ano anterior: ',
  PRIOR_YEAR_SALES: 'Vendas do ano anterior',
  TOTAL: 'Total',
  TOTAL_WITH_LABEL: '{label} no total',
  TOTAL_WITH_VALUE: 'Total {value}',
  TOTAL_WITH_FRACTION: 'Total {numerator} / {denominator}',
  CANCEL_WORKFLOW_CONFIRMATION: 'Tem a certeza de que pretende cancelar o fluxo de trabalho?',
  YES: 'Sim',
  NO: 'Não',
  LATER: 'Mais tarde',
  REPARENTING: 'A atribuir nova hierarquia...',
  NOT_APPLICABLE_ABBREVIATION: 'N/D',
  PIPE_CONFIGURATION_DELETE_PROMPT:
    'Isto irá eliminar permanentemente a configuração do seu canal "{pipeName}, {exportName}". Esta ação não pode ser anulada. Confirmar?',
  CONTRIBUTOR_QUOTA_COMPONENT_SUBMIT_WARNING: 'Antes de enviar, tem de rever {value}',
  CONTRIBUTOR_HIERARCHY_SUBMIT_WARNING: 'Antes de enviar, tem de aprovar todos os territórios subordinados.',
  SHOW_CHANGES: 'Mostrar alterações',
  SHOW_IMPACTED_ROWS_ONLY: 'Mostrar apenas as linhas afetadas',
  REVIEW_CHANGES: 'Rever alterações',
  SHEETS: 'Folhas',
  TABLES: 'Tabelas',
  UNPUBLISHED: 'Não publicado',
  PUBLISHED: 'Publicado',
  PUBLISHING_IN_PROGRESS: 'Publicação em curso',
  IMPORT: 'Importar',
  DATE_CREATED: 'Data de criação',
  DATE_MODIFIED: 'Data de modificação',
  SELLERS: 'Vendedores',
  SELLER: 'Vendedor',
  SELECT_SELLER: 'Selecionar vendedor',
  SELECT_TERRITORY_GROUP: 'Selecionar grupo territorial',
  MOVE: 'Transferir',
  MOVE_ACCOUNTS: 'Transferir contas',
  MOVE_ACCOUNTS_DIALOG_BODY: 'Escolha um território para transferir as contas selecionadas para:',
  OVERRIDE_ACCOUNTS_TITLE: 'Executar precedência de contas?',
  OVERRIDE_REASSIGN_BODY_1: 'Mover as contas selecionadas irá adicionar regras de precedência ao seu território.',
  OVERRIDE_REASSIGN_BODY_2:
    'Se não pretender adicionar precedências, cancele esta ação e atualize a regra de território de base no editor de regras.',
  DONT_SHOW_THIS_AGAIN: 'Não mostrar isto novamente',
  HYPHEN: '-',
  EXPAND: 'Expandir',
  COLLAPSE: 'Fechar',
  ZOOM_IN: 'Ampliar',
  ZOOM_OUT: 'Reduzir',
  CLOSE: 'Fechar',
  LAST_MODIFIED: 'Última modificação: {value}',
  LAST_OPENED: 'Última abertura',
  BATTLE_CARD_REFERENCED: 'Cartão de batalha referenciado',
  SELECT_BATTLE_CARD_REFERENCE: 'Selecione os cartões de batalha e os níveis que fazem referência a esta folha',
  BATTLE_CARD_NAME: 'Nome do cartão de batalha',
  SHEETS_REFERENCED: 'Folhas referenciadas',
  REFERENCE_THIS_SHEET: 'Referenciar esta folha',
  PUBLISH: 'Publicar',
  PUBLISHED_TOOLTIP: 'Cenário publicado: {name}',
  UNPUBLISHED_TOOLTIP: 'Cenário não publicado: {name}',
  SCENARIO_PUBLISHED: 'Cenário publicado',
  SCENARIO_PUBLISHED_MESSAGE: 'Publicámos o seu {name} cenário.',
  SCENARIO_PUBLISHED_FAILURE: 'Falha ao publicar o cenário',
  SCENARIO_DELETE_PROMPT:
    'Isto irá eliminar permanentemente o seu {name} cenário. Esta ação não pode ser anulada. Tem a certeza de que pretende eliminar?',
  SCENARIO_PUBLISHED_ERROR: '{name} está atualmente publicado',
  SCENARIO_PUBLISHED_ERROR_DESCRIPTION:
    'O seu cenário {name} não pode ser eliminado porque está atualmente publicado. Publicar outro cenário e tentar novamente.',
  SCENARIO_DELETED: 'Cenário eliminado',
  SCENARIO_DELETED_FAILURE: 'Falha ao eliminar cenário',
  SCENARIO_DELETED_FAILURE_MESSAGE:
    'Não foi possível eliminar o seu {name} cenário. Atualize a página e tente novamente.',
  PERMISSIONS: 'Permissões',
  APPLY_PER_USER_ROLE: 'Aplicar por função de utilizador',
  USER: 'Utilizador',
  QUOTA_SHEET_COLUMNS: 'Colunas da folha de quotas',
  TERRITORY_SHEET_COLUMNS: 'Colunas da folha de territórios',
  SELLER_SHEET_COLUMNS: 'Colunas da folha de vendedores',
  COLUMNS_DESCRIPTION: 'Gerir campos incorporados e adicionar campos personalizados',
  CREATE_FIELD: 'Criar campo',
  EDIT_FIELD: 'Editar campo',
  SHOW_TOTALS: 'Mostrar totais',
  REPARENT_TO_ROOT: 'Atribuir nova hierarquia para a raiz',
  ADD_HIERARCHY_UNDER_ROOT: 'Adicionar hierarquia sob a raiz',
  PIN_SET: 'Conjunto de pinos',
  PIN_TOOLTIP: 'Ver e adicionar pinos',
  ADD_PIN_SET: 'Adicionar conjunto de pinos',
  PIN_SET_NAME: 'Nome do conjunto de pinos',
  PIN_SET_ICON: 'Ícone do conjunto de pinos',
  PIN_SET_COLOR: 'Cor do conjunto de pinos',
  COLOR_OPTION: 'Opção de cor {value}',
  PIN_NAME_PLACEHOLDER: 'Introduzir nome do pino',
  PIN_SET_DATA_PLACEHOLDER: 'Selecione uma fonte de dados para o seu conjunto de pinos',
  CREATE_PIN_SET_SUCCESS: 'Criámos o seu conjunto de pinos.',
  CREATE_PIN_SET_ERROR: 'Lamentamos, mas não foi possível criar o seu conjunto de pinos.',
  LOCATION_GROUP_UNIQUENESS_ERROR: 'Este nome de tabela de dados já existe',
  MISSING_REQUIRED_LOCATION_GROUP_NAME: 'Introduza um nome de tabela de dados',
  MISSING_REQUIRED_PIN_SET_NAME: 'Introduza um nome de conjunto de pinos',
  PIN_SET_NAME_TOO_LONG: 'Excedeu o limite máximo de caracteres ({maxCharacters})',
  MISSING_REQUIRED_LOCATION_GROUP: 'Selecione uma fonte de dados',
  MISSING_REQUIRED_ICON: 'Selecione um ícone',
  MISSING_REQUIRED_COLOR: 'Selecione uma cor',
  DATA_SOURCE_DUPLICATE_USAGE:
    'Esta fonte de dados já está a ser utilizada por um conjunto de pinos no cartão de batalha. Selecione uma fonte de dados diferente.',
  PIN_SET_NAME_UNIQUENESS_ERROR: 'Este nome de conjunto de pinos já existe.',
  LOCATION_FILE_TYPE: 'Pinos do mapa',
  EXISTING_PIN_SET_TEXT: 'Utilizar dados existentes do conjunto de pinos',
  NEW_PIN_SET_TEXT: 'Importar dados novos do conjunto de pinos',
  PREVIEW: 'Pré-visualizar',
  ADD_USER_ROLE: '+ Adicionar',
  QUOTA_COMPONENTS_REFERENCED: 'Componentes de quota referenciados',
  QUOTA_COMPONENTS_REFERENCED_CHECKBOX_SELECTION_WARNING: 'Refira pelo menos um componente de quota',
  COLUMN_NAME: 'Nome da coluna',
  SOURCE: 'Fonte',
  DATA_TABLE_TYPE_WITH_COLON: 'Tipo de tabela de dados:',
  DATA_TABLE_NAME: 'Nome da tabela de dados*',
  DATA_TABLE_PLACEHOLDER: 'Ex. Autarquias Locais de Portugal 2022',
  DATA_TABLE: 'Tabela de dados',
  COLUMN: 'Coluna',
  GROUP_BY: 'Agrupar por:',
  APPLIED_FILTERS: 'Filtros aplicados:',
  RESET: 'Repor',
  DELETE_FIELD_FAILED: 'Não é possível eliminar campo',
  CREATE_FIELD_SUCCESS: 'Campo criado com sucesso',
  UPDATE_FIELD_SUCCESS: 'Campo atualizado com sucesso',
  DELETE_FIELD_SUCCESS: 'Campo eliminado com sucesso',
  UPDATE_QUOTA_SHEET_SUCCESS: 'Folha de quotas atualizada com sucesso',
  UPDATE_QUOTA_SHEET_FAILED: 'Não é possível atualizar a folha de quotas',
  COLUMN_REORDER_FAILED: 'Não é possível reordenar campos da coluna',
  COLUMN_REORDER_SUCCESS: 'Campos de coluna reordenados com sucesso',
  DISABLED_CHECKBOX_TOOLTIP: 'Esta propriedade não está ativada para este tipo de campo.',
  DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP: 'Este campo não é editável após a criação do ciclo de planeamento.',
  VISIBLE: 'Visível',
  EDITABLE: 'Editável',
  CANCEL_LASSO: 'Cancelar laço',
  LASSO_TOOLTIP: 'Ferramenta de laço',
  LASSO_DROPDOWN_TOOLTIP: 'Filtre a seleção do laço para incluir regiões geográficas atribuídas ou não atribuídas.',
  LASSO_DROPDOWN_TOOLTIP_ACCOUNTS: 'Filtre a seleção do laço para incluir contas atribuídas ou não atribuídas.',
  SELECT_TOOLTIP: 'Ferramenta de seleção',
  UNDO: 'Anular',
  MAP_UNDO_BODY:
    'Está prestes a anular a sua alteração mais recente. Não será possível refazer após a anulação estar concluída. Tem a certeza de que pretende continuar?',
  MAP_UNDO_TOAST_ERROR_TITLE: 'Não é possível anular',
  MAP_UNDO_TOAST_ERROR_BODY: 'Ocorreu um erro. Não foi possível anular a alteração da regra.',
  MAP_UNDO_TOAST_SUCCESS: 'Anulação concluída com sucesso.',
  MODERATE_DEVIATION: 'moderado',
  SIGNIFICANT_DEVIATION: 'significativo',
  MINOR_DEVIATION: 'menor',
  ABOVE_AVERAGE:
    'Pontuação da lacuna: {gapScore} — este valor está acima da média, colocando-o no percentil {percentile}, indicando um desvio {deviationType}.',
  BELOW_AVERAGE:
    'Pontuação da lacuna: {gapScore} — este valor está abaixo da média, colocando-o no percentil {percentile}, indicando um desvio {deviationType}.',
  ON_AVERAGE:
    'Pontuação da lacuna: {gapScore} — este território encontra-se dentro da média de todos os territórios que utilizam a mesma métrica, não apresentando desvios significativos.',
  GAP: 'Lacuna',
  CREATE_NEW_TERRITORY_ERROR: 'Não é possível criar território.',
  DUPLICATE_OVERRIDE_INCLUSION_KEY: 'O código de inclusão na hierarquia já existe noutra regra de precedência',
  CREATE_NEW_TERRITORY_SUCCESS: 'Novo território criado.',
  CREATE_NEW_TERRITORY_DIALOG_TITLE: 'Criar novo território',
  EFFECTIVE_DATING: 'Data efetiva',
  EXCEL: 'Excel',
  USER_PROFILE: 'Perfil',
  PROFILE_PAGE_PERSONAL_INFORMATION: 'Informações pessoais',
  PROFILE_PICTURE: 'Imagem de perfil',
  PROFILE_PICTURE_SUBTITLE: 'Adicione uma fotografia para personalizar a sua conta',
  USE_LASSO_TOOL: 'Utilizar a ferramenta Laço ',
  DRAW_NEW_TERRITORY: ' para desenhar um novo território',
  EMPTY_MAP_GRID_TITLE: 'Não é possível carregar pré-visualizações',
  EMPTY_MAP_TGT_GRID_MESSAGE:
    'Poderá não ter territórios dentro do seu tipo de grupo territorial geográfico. Contacte o seu administrador.',
  EMPTY_MAP_TG_GRID_MESSAGE:
    'Poderá não ter territórios dentro do seu grupo territorial. Contacte o seu administrador.',
  PASSWORD_LAST_CHANGED: 'Última alteração: {date}',
  PASSWORD_STRENGTH: 'Força da palavra-passe: ',
  PASSWORD_STRENGTH_INSTRUCTION:
    'Utilize pelo menos 8 caracteres. Não utilize uma palavra-passe de outro site ou algo demasiado óbvio como o nome do seu animal de estimação.',
  PASSWORD_INSTRUCTION: 'Escolha uma palavra-passe forte e não a reutilize noutras contas.',
  CHANGE_PASSWORD: 'Alterar palavra-passe',
  CONFIRM_EXISTING_PASSWORD_LABEL: 'Reintroduzir palavra-passe',
  NEW_PASSWORD_LABEL: 'Nova palavra-passe',
  CONFIRM_NEW_PASSWORD_LABEL: 'Confirmar nova palavra-passe',
  SET_BALANCING_METRIC: 'Esta é uma métrica de equilíbrio',
  MAKE_THIS_DEFAULT: 'Usar como predefinição',
  BALANCING_MODAL_DELETE_HEADER: 'Remover este território?',
  REBALANCING_COINSORT_HEADER: 'Executar a ordenação de moedas?',
  REBALANCING_COINSORT_BODY:
    'Quer executar a ordenação de moedas agora? Os territórios serão ordenados com as novas atualizações de regras.',
  BALANCING_MODAL_DELETE_BODY: 'Remover este território também irá cancelar as alterações não guardadas.',
  BALANCING_MODAL_BATTLECARD_BODY: 'Fechar este cartão de batalha também irá cancelar as alterações não guardadas.',
  REBALANCING_SUCCESS: 'Os territórios foram reequilibrados com sucesso.',
  UPLOADING: 'A carregar...',
  UPLOAD: 'Carregar',
  DONE: 'Concluído',
  DATA_FILE_DRAG_DROP_TITLE: 'Largue o ficheiro ou clique para carregar',
  DATA_FILE_DRAG_DROP_TEXT: 'Carregue um ficheiro de texto a partir do seu dispositivo local. O formato aceite é: .csv',
  FILE_PUBLISH_ERROR: 'Não é possível publicar o seu ficheiro',
  FILE_HAS_BEEN_PUBLISH_SUCCESS: '{name} foi publicado com sucesso',
  FILE_HAS_BEEN_PUBLISH_ERROR: '{name} não conseguiu publicar',
  SYSTEM_TIMESTAMP: 'Carimbo de data e hora do sistema',
  DATA_FILE_UPLOAD_SEQUENCE_ERROR: 'Lamentamos! Falha ao carregar ficheiro: {message}',
  REMOVE_REBALANCING_TERRITORY: 'Remover este território',
  REMOVE_TERRITORY: 'Remover território',
  ADDITIONAL_REBALANCING_TERRITORY_ERROR: 'Lamentamos! Só pode selecionar dois territórios de cada vez.',
  TERRITORY_RULE: 'Regra do território',
  UPDATE_TERRITORY_SUCCESS: 'O território foi atualizado com sucesso.',
  UPDATE_TERRITORY_ERROR: 'Não foi possível atualizar o território.',
  MENU: 'Menu',
  HELP: 'Ajuda',
  WARNING: 'Aviso',
  OPEN: 'Abrir',
  BUILT_IN_MEASURES_CANNOT_EDIT: 'As medidas incorporadas não podem ser editadas.',
  BEFORE_BALANCING_METRIC_DEFAULT:
    'Esta métrica será utilizada por predefinição. Por exemplo, ao reequilibrar territórios, se tiver várias métricas de equilíbrio, uma funcionalidade que utilize uma métrica de equilíbrio de cada vez utilizará essa métrica por predefinição.',
  DEFAULT_BALANCING_METRIC_WARNING_MESSAGE:
    'Selecione outra métrica de equilíbrio se pretender alterar a predefinição.',
  AUTOMATCH: 'Correspondência automática',
  GET_S3_FILE_ERROR: 'Não é possível recuperar o conteúdo do ficheiro',
  SELECT_A_HIERARCHY: 'Selecionar uma hierarquia',
  SELECT_TERRITORY: 'Selecionar território',
  EDIT_HIERARCHY_NONE: 'Selecione uma hierarquia para editar',
  IMPORT_FROM_CSV: 'Importar do ficheiro .csv',
  IMPORT_FROM_SYMON: 'Importar do canal',
  MAP_DATA_ERROR: 'Ocorreu um erro ao carregar os dados do mapa',
  CANNOT_REBALANCE_CUSTOMER:
    'Não é possível transferir um cliente que pertença a vários territórios ou que tenha atividades não atribuídas em qualquer componente de quota.',
  CANNOT_REBALANCE_GEOGRAPHY:
    'Não é possível transferir uma área geográfica que pertença a vários territórios ou que tenha atividades não atribuídas em qualquer componente de quota.',
  CANNOT_REBALANCE_MISSING_GEO_HIERARCHY:
    'Não é possível transferir a geografia porque falta uma hierarquia. Verifique se as hierarquias carregadas correspondem às presentes nos ficheiros de atividade.',
  TERRITORY_GRID_ERROR:
    'Os territórios não podem ser carregados na grelha. Clique em atualizar para carregar novamente os territórios.',
  ENTER_TERRITORY_GROUP_NAME: 'Introduza um nome para o seu grupo territorial',
  GLOBAL_ERROR_BOUNDARY_TROUBLE: 'Estamos a ter problemas em lidar com todos esses dados. ',
  GLOBAL_ERROR_BOUNDARY_REFRESH: 'Atualize a página, vamos levá-lo de volta ao início.',
  SYMON_AI_SOURCE: 'Fonte do canal',
  NO_RESULTS_FOUND: 'Nenhum resultado encontrado',
  GET_SYMON_PIPENAMES_ERROR: 'Não é possível recuperar nomes de canais.',
  GET_SYMON_EXPORT_NODES_ERROR: 'Não é possível recuperar nós de exportação.',
  MOVE_TERRITORY: 'Transferir território',
  MOVE_TERRITORY_DESCRIPTION: 'Escolha o grupo territorial para o qual transferir o território selecionado:',
  MOVE_TERRITORY_ERROR: 'Não é possível transferir territórios.',
  MOVE_TERRITORY_SUCCESS_NAMED:
    '{territoryId} o território foi transferido com sucesso para o grupo territorial {territoryGroup}',
  MOVE_TERRITORY_SUCCESS_MULTIPLE:
    'Vários territórios foram transferidos com sucesso para o {territoryGroup} grupo territorial',
  MOVE_TERRITORY_DISABLED:
    'Não pode transferir os territórios selecionados porque pertencem a tipos diferentes de grupos territoriais.',
  CARD_TYPE: 'Tipo de cartão: ',
  BATTLECARD_TYPE_OVERLAY: 'Sobreposição',
  BATTLECARD_TYPE_OVERLAY_ICON: 'Ícone de cartão de batalha sobreposto',
  BATTLECARD_TYPE_PRIMARY: 'Primário',
  BATTLECARD_TYPE_PRIMARY_ICON: 'Ícone de cartão de batalha primário',
  BATTLECARD_TYPE_ROLL_UP: 'Agregação',
  CREATE_CHILD_UNDER: 'Criar subordinado sob {value}',
  ROOT: 'raiz',
  INTEGRATIONS: 'Integrações',
  SYMON: 'Symon',
  VIEW_SOURCES: 'Ver fontes',
  MANAGE_TABLES: 'Gerir tabelas',
  BUILD_AND_VISUALIZE: 'Construir e visualizar',
  DASHBOARDS: 'Painéis de controlo',
  CONFIRM: 'Confirmar',
  SYMON_CREDENTIALS_SAVE_SUCCESS: 'Credenciais do canal guardadas com sucesso',
  SYMON_CREDENTIALS_DELETE_SUCCESS: 'Credenciais do canal eliminadas com sucesso',
  SYMON_CREDENTIALS_SAVE_FAILED: 'Falha ao guardar as credenciais do canal',
  SYMON_CREDENTIALS_BEING_VALIDATED: 'As suas credenciais API estão a ser validadas.',
  SYMON_CREDENTIALS_VALID: 'Validámos a sua chave API e a ligação do canal está pronta.',
  SYMON_CREDENTIALS_INVALID: 'Não é possível verificar, certifique-se de que as suas credenciais estão corretas.',
  VALIDATE_API_KEY: 'Validar chave API',
  DELETE_API_KEY: 'Eliminar chave API',
  SYMON_DOMAIN: 'Domínio do canal',
  SAVED_SYMON_DOMAIN_INVALID_TITLE: 'O domínio do canal guardado é inválido.',
  SAVED_SYMON_DOMAIN_INVALID_DESCRIPTION: 'Atualize a sua chave para uma chave do domínio: ',
  SYSTEM_API_KEY: 'Chave API do sistema',
  CONFIGURE_FOR_PLANNING: 'Configurar para planeamento',
  GET_SYMON_ACCOUNT_ERROR: 'Não foi possível recuperar a conta de canal.',
  SYMON_DOMAIN_REQUIRED: 'O domínio do canal é obrigatório.',
  SYSTEM_API_KEY_REQUIRED: 'A chave API do sistema é obrigatória',
  SYMON_API_URL_INVALID: 'URL do canal inválido',
  SYMON_EXPORT_NODE_REQUIRED: 'O nó de exportação do canal é obrigatório.',
  SYMON_PIPE_NAME_REQUIRED: 'A fonte do canal é obrigatória',
  DESTINATION_TABLE_REQUIRED: 'O nome da tabela de destino é obrigatório',
  NEW_TERRITORY: 'Novo território',
  FORWARD_SLASH: '/',
  INPUT_TEXT: 'Texto da introdução',
  TABLE_CONTROLLER_PUBLISHED_STATUS_FAILED_DEFAULT_ERROR:
    'Não foi possível publicar o seu ficheiro. Verifique se o ficheiro importado tem campos que correspondem às colunas adequadas.',
  ACTION_REQUIRED_REVIEW_ERROR: 'Ação necessária: rever erro',
  ACTION_REQUIRED_MATCH_FIELDS: 'Ação necessária: corresponder campos',
  MATCH_FIELDS_TITLE: 'Corresponder campos',
  MATCH_FIELDS_TEXT_RUN_THIS_CONFIGURATION:
    'Selecione as colunas necessárias para que correspondam aos seus campos de dados. Tem de fazer corresponder todas as colunas obrigatórias aos seus campos de dados antes de poder executar esta configuração.',
  MATCH_FIELDS_TEXT_PUBLISH_THIS_FILE:
    'Selecione as colunas necessárias para que correspondam aos seus campos de dados. Tem de fazer corresponder todas as colunas obrigatórias aos seus campos de dados antes de poder publicar este ficheiro.',
  MEMBER_SELECTED_WITH_COUNT: '{count} membro selecionado',
  MEMBERS_SELECTED_WITH_COUNT: '{count} membros selecionados',
  DELETE_MEMBER_WITH_VALUE: 'Eliminar {count} membro',
  DELETE_MEMBERS_WITH_VALUE: 'Eliminar {count} membros',
  DESELECT_ALL: 'Anular seleção de todos',
  DELETE_HIERARCHIES_ERROR: 'Não é possível eliminar as suas hierarquias.',
  CONFIGURATIONS: 'Configurações',
  SYMON_AI_EXPORT_NODE: 'Exportar nó',
  CREATE_CONFIGURATION: 'Criar configuração',
  RUN_CONFIGURATION_AFTER_CREATING: 'Executar esta configuração depois de criar',
  NO_SYMON_PIPES: 'Sem canais',
  UPDATED_HIERARCHY: 'Atualização de {rootName} "{name}".',
  CHECK_FOR_CHANGES: 'Verificar alterações',
  FILE_TYPE: 'Tipo de ficheiro',
  FILE_REQUIREMENT_TEXT: '{fileType} os ficheiros necessitam das seguintes colunas para efetuar a importação:',
  DESTINATION: 'Destino',
  DESTINATION_TABLE: 'Tabela de destino',
  NO_SYMON_EXPORT_NODE_ERROR_MESSAGE:
    'Não existem nós de exportação disponíveis para esta fonte. Selecione uma fonte de canal diferente para criar a configuração.',
  NO_SYMON_AI_SOURCE_ERROR_MESSAGE:
    'Para selecionar uma fonte de canal, primeiro tem de configurar um canal na plataforma.',
  TEXT: 'Texto',
  ACTIVITY: 'Atividade',
  DATA_MAPPING_PANEL_TEXT:
    'Os seus dados ainda não estão no sistema. Esta tabela é apenas uma pré-visualização. Para importar os seus dados e criar uma nova tabela, clique em Executar.',
  DATA_MAPPING_FIELDS_ERROR: 'Verifique se fez corresponder todos os campos às colunas adequadas.',
  CONFIGURATION_CREATION_SUCCESS: 'Configuração criada com sucesso',
  CONFIGURATION_CREATION_FAIL: 'Falha ao criar configuração',
  UNDER_BY: '{value} a menos',
  OVER_BY: '{value} a mais',
  RUN_THIS_CONFIGURATION: 'Executar esta configuração',
  SYMON_PIPE_CONFIGURATIONS_ERROR: 'Não é possível recuperar as configurações do canal.',
  GETTING_PREVIEW_DATA: 'A obter dados de pré-visualização de configuração do canal...',
  UPDATE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuração atualizada com sucesso.',
  UPDATE_SYMON_PIPE_CONFIGURATION_ERROR: 'Não foi possível atualizar a configuração do canal.',
  DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuração eliminada com sucesso.',
  DELETE_SYMON_PIPE_CONFIGURATION_ERROR: 'Falha ao eliminar a configuração.',
  CONFIGURATION: 'Configuração',
  MEMBERS_REPARENTED_SUCCESS: 'Foi atribuída uma nova hierarquia a {count} membros com sucesso',
  SYMON_FILE_IMPORT_IN_PROGRESS: 'Estamos a importar o seu ficheiro de dados.',
  SYMON_FILE_IMPORT_ERROR: 'Não é possível importar o seu ficheiro de dados',
  NOT_STARTED: 'Não iniciado',
  RUNNING: 'Em execução',
  COMPLETED: 'Concluído',
  FAILED: 'Falhou',
  FILE_SUCCESSFULLY_PUBLISHED: 'O seu ficheiro foi publicado com sucesso.',
  MAKE_A_COPY: 'Fazer uma cópia',
  PLANNING_CYCLE_CLONE_FAILURE: 'Não é possível clonar o ciclo de planeamento',
  PLANNING_CYCLE_CLONE_REQUEST_SUCCESS: 'Clone do ciclo de planeamento solicitado com sucesso',
  SELECT_AN_EXISTING_CONFIGURATION: 'Selecione uma configuração existente',
  IMPORT_CONFIG_MESSAGE:
    'Iremos importar o seu ficheiro de dados para a tabela de destino. Poderá visualizá-lo na página Dados.',
  CREATE_NEW_CONFIGURATION: 'Criar nova configuração',
  RUN_EXISTING_CONFIGURATION: 'Executar configuração existente',
  FILE_PREVIEW: 'Pré-visualização do ficheiro',
  REVIEW_IMPORT: 'Rever importação',
  COMMENTS: 'Comentários',
  ALL_GROUPS: 'Todos os grupos',
  ALL_TERRITORIES: 'Todos os territórios',
  NO_COMMENTS_TO_SHOW: 'Sem comentários para mostrar',
  ADD_COMMENTS_MESSAGE: 'Adicionar comentários para dar feedback sobre o planeamento do território',
  ADD_COMMENTS: 'Adicionar comentários',
  NEW_COMMENTS_PLACEHOLDER: 'Utilize @menções para etiquetar uma ID de território ou grupo',
  REPLY: 'Responder',
  MAP_SELECTION_STATS_ERROR: 'Não é possível obter estatísticas para seleção',
  ENTER_TABLE_NAME: 'Introduzir nome da tabela',
  CHOOSE_THE_SOURCE_AND_EXPORT_NODE:
    'Escolha a origem e o nó de exportação a partir dos quais pretende extrair o seu ficheiro de dados.',
  SYMON_AI_SOURCE_AND_EXPORT_NODE: 'Origem do canal e nó de exportação',
  SYMON_REVIEW_DIALOG_CONFIGURATION_TEXT: 'Vamos guardar esta configuração para si na página Configurações.',
  SYMON_REVIEW_DIALOG_EXISTING_CONFIGURATION_TEXT:
    'Vamos importar novamente a sua configuração existente. Isto irá criar uma nova versão da sua tabela de dados {name}.',
  TERRITORY_RULES: 'Regras do território',
  MAP: 'Ver mapa',
  GRID: 'Ver grelha',
  URL_ERROR_MESSAGE_GENERIC: 'Introduza um URL válido',
  INCLUSIONS: 'Inclusões',
  EXCLUSIONS: 'Exclusões',
  VIEW_TERRITORIES: 'Ver territórios',
  VIEW_QUOTAS: 'Ver quotas',
  NO_ACTIVITIES_TOOLTIP: 'Ainda não adicionou quaisquer dados de atividade para este cartão de batalha.',
  COINSORT_COMPLETE_TOOLTIP: 'Executar ordenação de moedas para obter as estatísticas mais exatas',
  COINSORT_RUNNING_TOOLTIP: 'Estamos a ordenar as suas atividades por territórios. Volte a verificar em breve.',
  HIDDEN: 'Oculto',
  NON_MAPPABLE_TOOLTIP: 'Este território contém regras complexas e não pode ser apresentado no seu mapa',
  CREATE_TERRITORY_TOOLTIP_GEO: 'Selecione limites não atribuídos para criar um novo território.',
  CREATE_TERRITORY_TOOLTIP_ACCOUNT: 'Selecione contas não atribuídas para criar um novo território.',
  IMPORT_QUOTAS: 'Importar quotas',
  REQUIRED: 'Obrigatório *',
  OPTIONAL: 'Opcional',
  ADD_RULE: 'Adicionar regra',
  ADD_OVERRIDE: 'Adicionar precedência',
  OVERRIDE_RULE_TOOLTIP_ENABLED:
    'As precedências serão transferidas para este território e removidas das suas regras de território atuais.',
  BASE_RULE: 'Regra base',
  OVERRIDE: 'Executar precedência',
  HIERARCHY_MEMBER_DELETE_SUCCESS: 'Membro da hierarquia eliminado com sucesso.',
  YOUR_FILE_IS_MISSING: 'O seu ficheiro tem, pelo menos, {count} coluna(s) obrigatórias em falta.',
  DOWNLOAD_QUOTA_FILE_TEXT: 'Transferir ficheiro de quota modelo',
  DOWNLOAD_SELLER_ASSIGNMENT_FILE_TEXT: 'Transferir ficheiro de atribuição de vendedor modelo',
  DOWNLOAD_ACTIVITY_FILE_TEXT: 'Transferir ficheiro de atividade modelo',
  DOWNLOAD_LOCATION_TEMPLATE_CSV: 'Transferir ficheiro CSV modelo',
  CONFLICTING_RULES: '{count} regras em conflito',
  CONFLICTING_RULE: 'Regra em conflito',
  LOCKED: 'Bloqueado',
  CONFLICTING_RULES_GRID_TOOLTIP: 'Este território contém regras que entram em conflito com outro território',
  REMOVE_CONFLICT_BUTTON: 'Remover conflitos',
  REMOVE_CONFLICT_ACTION_TOOLTIP: 'Remova o conflito eliminando a região geográfica do território selecionado',
  REMOVE_CONFLICT_DIALOG_TITLE: 'Remover conflitos de regras de território?',
  REMOVE_CONFLICT_DIALOG_TEXT: 'Está prestes a remover os seguintes conflitos de regra de território de {territory}:',
  NO_TERRITORY_RULES:
    'Os seguintes territórios não contêm regras de território, por isso não há nada a mostrar no mapa: {territories}',
  HIDE_GRID: 'Ocultar grelha',
  SHOW_GRID: 'Mostrar grelha',
  CHANGE_COLOR: 'Alterar cor',
  UPLOAD_HIERARCHY: 'Carregar hierarquia',
  UPLOAD_HIERARCHY_NONE: 'Selecione uma hierarquia para a qual carregar',
  UPLOAD_TO: 'Carregar para {name}',
  SET_SEASONALITY: 'Definir sazonalidade',
  APPLY: 'Aplicar',
  FORMULA_EDITOR_TOOLTIP_MESSAGE:
    'Selecione operadores e fontes de dados para construir a sua fórmula ou digite-a nesta caixa de texto.',
  CALCULATED_FIELD_EXAMPLE: 'Exemplo: (Receitas-Despesas)*0,4',
  OPERATORS: 'Operadores',
  CANNOT_VIEW_TERRITORY_GROUP: 'Só pode ver grupos territoriais de que seja titular',
  START_WORKFLOW: 'Iniciar fluxo de trabalho',
  STOP_WORKFLOW: 'Parar fluxo de trabalho',
  START_TERRITORY_WORKFLOW_HEADER: 'Iniciar fluxo de trabalho do território?',
  START_TERRITORY_WORKFLOW_BODY:
    'Pretendia iniciar este fluxo de trabalho do território? Isto irá notificar todos os contribuidores de que o fluxo de trabalho foi iniciado. Os contribuidores poderão ver e deixar comentários sobre os territórios que sejam titulares.',
  STOP_TERRITORY_WORKFLOW_HEADER: 'Parar fluxo de trabalho do território?',
  STOP_TERRITORY_WORKFLOW_BODY:
    'Pretendia parar este fluxo de trabalho do território? Isto irá notificar todos os contribuidores de que o fluxo de trabalho parou. Os contribuidores deixarão de poder aceder ao ciclo de planeamento.',
  RETRIEVE_TERRITORY_WORKFLOW_ERROR:
    'Estamos a ter dificuldades em carregar as suas opções de fluxo de trabalho. Para iniciar ou parar o seu fluxo de trabalho, atualize a página.',
  START_TERRITORY_WORKFLOW_ERROR:
    'Estamos a ter problemas em iniciar este fluxo de trabalho neste momento. Atualize a página e tente novamente.',
  STOP_TERRITORY_WORKFLOW_ERROR:
    'Estamos a ter dificuldades em parar este fluxo de trabalho neste momento. Atualize a página e tente novamente.',
  ENTER_COMMENT: 'Introduza um comentário',
  COMMENT_PANEL_LOADING_ERROR:
    'Lamentamos, mas estamos a ter dificuldades para carregar comentários neste momento. Tente atualizar a página.',
  POST_COMMENT_ERROR:
    'Lamentamos, mas estamos a ter dificuldades para publicar este comentário. Por favor, tente novamente.',
  SELLER_ASSIGNMENT: 'Atribuição de vendedor',
  ADD_MORE: 'Adicionar mais',
  GET_QUOTA_WORKFLOW_STATUS_ERROR: 'Não é possível recuperar o estado do fluxo de trabalho da quota do servidor.',
  SEASONALITY_INSTRUCTION:
    'Introduza os pesos de sazonalidade personalizados ou escolha uma sazonalidade existente para começar. O valor total atribuído deve ser igual a 100%.',
  SET_PC_DURATION_PERIODICITY_WARNING: 'Aviso de duração e periodicidade do plano',
  SET_PC_DURATION_PERIODICITY_WARNING_DETAIL_UPDATED:
    'Reveja a duração do seu plano e o ciclo de intervalos de periodicidade. Não poderá alterá-los depois de criar o seu ciclo de planeamento.',
  IMPORT_SELLER_ASSIGNMENTS: 'Importar atribuições do vendedor',
  MULTIPLE: 'Vários',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE: 'Adicione uma fonte ou valor de dados antes de {operator}',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE_FULL:
    'Adicione uma fonte ou valor de dados antes do operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL:
    'Adicione uma fonte ou valor de dados depois do operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER: 'Adicione uma fonte ou valor de dados depois do operador {operator}',
  MISSING_CLOSED_PARENTHESIS_ERROR:
    'Falta um parêntesis fechado nesta fórmula. Certifique-se de que faz corresponder todos os parêntesis abertos e fechados.',
  INVALID_DATA_SOURCE_ERROR:
    'Esta fórmula contém uma fonte de dados não reconhecida. Verifique as suas fontes de dados quanto à ortografia correta.',
  UNEXPECTED_OPERAND_NO_OPERATOR_ERROR:
    "Operando inesperado '{{value}'}. As fontes de dados e os valores devem ser separados por um operador.",
  OWNERSHIP: 'Titularidade',
  ADD_SELLER: 'Adicionar vendedor',
  EXPORT_TO_CSV: 'Exportar para ficheiro .csv',
  EXPORTING_DATA: 'A exportar dados...',
  EXPORTING_DATA_FAILED: 'Falha ao exportar dados',
  TERRITORY_WORKFLOW_NOT_STARTED: 'O seu administrador não iniciou um fluxo de trabalho de planeamento de território.',
  TERRITORY_TAB_NO_PERMISSIONS: 'Não tem permissões de visualização para este separador.',
  QUOTA_WORKFLOW_NOT_STARTED: 'O seu administrador não iniciou um fluxo de trabalho de planeamento de quotas.',
  DELETE_SELLER_ASSIGNMENT: 'Eliminar atribuição?',
  DELETE_SELLER_ASSIGNMENT_CONFIRMATION_MESSAGE:
    'Pretende eliminar esta atribuição de vendedor? A eliminação não pode ser anulada',
  EDIT_ASSIGNMENT: 'Editar atribuição',
  FORMULA_REQUIRED: 'Forneça uma fórmula válida.',
  DELETE_SELLER_ASSIGNMENT_SUCCESS: 'Atribuição de vendedor eliminada com sucesso.',
  DELETE_SELLER_ASSIGNMENT_ERROR: 'Não é possível eliminar a atribuição de vendedor.',
  DELETE_SELLER_FROM_MULTIPLE_TERRITORIES_ERROR: 'Nem todos os vendedores foram eliminados com sucesso.',
  REMOVE_SELLER_TITLE: 'Vendedor removido',
  REMOVE_SELLERS_TITLE: 'Vendedores removidos',
  REMOVE_SELLER_ERROR_TITLE: 'Falha ao remover vendedores',
  PERCENTAGE: 'Percentagem',
  KEEP: 'Manter',
  ADD_GEO_HIERARCHY_TITLE: 'Adicionar dados de hierarquia das regiões geográficas',
  ADD_GEO_HIERARCHY_DESC:
    'Faça a seleção a partir de uma lista de países suportados para adicionar dados de hierarquia das regiões geográficas ao seu ciclo de planeamento.',
  GEO_GRID_DESC:
    'A seguir, selecione o nível geográfico que pretende utilizar para planear os seus territórios, por exemplo, país, estado ou código postal.',
  NO_IN_USE_GEO_DESC:
    'Tem de adicionar dados da hierarquia das regiões geográficas antes de poder criar territórios no mapa.',
  ADD_GEO_HIERARCHY_DROP_DOWN_LABEL: 'Selecionar países',
  EXTERNAL_DOCS_LINK: 'Clique aqui para saber mais sobre os níveis geográficos',
  RAMP: 'Período de adaptação',
  NO_RAMP: 'Sem período de adaptação',
  RENEW: 'Renovar',
  GEO_HIERARCHY_UPDATED_SUCCESS: 'Hierarquias das regiões geográficas atualizadas',
  GEO_HIERARCHY_UPDATED_ERROR: 'Ocorreu um erro ao atualizar as hierarquias das regiões geográficas',
  GEO_HIERARCHY_GET_ERROR: 'Ocorreu um erro ao obter as hierarquias das regiões geográficas',
  PLAN_TERRITORIES: 'Territórios do plano',
  PLAN_QUOTAS: 'Quotas do plano',
  LOOKUP: 'Procurar',
  SCHEDULE_NAME: 'Nome do calendário',
  ENTER_NAME: 'Introduzir nome',
  MONTH: 'Mês {value}',
  SEASONALITY_SUM_MESSAGE: 'O seu calendário mensal de sazonalidade deve totalizar 100%.',
  RAMP_VALUE_MESSAGE:
    'Um mês no seu calendário do período de adaptação excede os 100%. Ajuste o seu calendário do período de adaptação de modo a que todos os meses estejam entre 0% e 100%.',
  PERCENTAGE_VALUE_MESSAGE: 'Só são aceites valores numéricos superiores ou iguais a 0.',
  NO_END_DATE: 'Sem data de fim',
  NO_START_DATE: 'Sem data de início',
  SCHEDULE_NAME_EXIST_ERROR: 'O nome do calendário já existe.',
  SCHEDULE_NAME_EMPTY_ERROR: 'Introduza um nome de calendário.',
  YOUR_MONTH_SEASONALITY_PLEASE_ADJUST:
    'O seu total do calendário de sazonalidade mensal é de {value}%. Ajuste o seu calendário para que a soma de todos os meses seja igual a 100%.',
  ENTER_AMOUNT: 'Introduzir montante',
  RAMP_SCHEDULES: 'Calendários do período de adaptação',
  SEASONALITY_SCHEDULES: 'Calendários de sazonalidade',
  VIEW_RAMP_DATA: 'Ver dados do período de adaptação',
  VIEW_SEASONALITY_DATA: 'Ver dados de sazonalidade',
  GET_LOOKUP_TABLE_SCHDULES_ERROR: 'Não foi possível recuperar os calendários',
  UPSERT_LOOKUP_ROW_ERROR: 'Não foi possível adicionar o novo calendário',
  SYMON_ERROR_MESSAGE_1: 'Não foi possível carregar esta página.',
  SYMON_ERROR_MESSAGE_2: 'Atualize a página para tentar novamente.',
  REFRESH: 'Atualizar',
  SYMON_IMPORT_THIRD_PARTY_HEADER_OVERRIDE: 'Conectores de dados ({num})',
  RAMP_TABLE_MESSAGE: 'O seu calendário mensal do período de adaptação tem de estar entre 0 e 100.',
  SELLERS_ADDED: 'Vendedores adicionados',
  FAILED_TO_ADD_SELLER: 'Falha ao adicionar vendedores',
  FAILED_TO_ADD_SELLER_MESSAGE:
    'Não foi possível adicionar vendedores ao seu território. Atualize a página e tente novamente.',
  SYMON_UPLOAD_START_MESSAGE: 'Estamos a carregar o seu ficheiro.',
  SYMON_IMPORT_START_MESSAGE: 'Iniciámos a sua importação. Pode aceder-lhe a partir do separador Ver fontes.',
  SYMON_UPLOAD_ERROR: 'Não foi possível carregar o seu ficheiro.',
  SYMON_CONNECTION_UPDATED_TITLE: 'Ligação atualizada',
  SYMON_CONNECTION_UPDATED_BODY: 'A sua ligação {connectionType} foi validada',
  SYMON_CONNECTION_FAILURE_TITLE: 'Credenciais inválidas',
  SYMON_CONNECTION_FAILURE_BODY:
    'Não foi possível validar a sua ligação {connectionType}. Reveja as suas credenciais e tente novamente.',
  IMPORT_ERRORS: 'Erros de importação',
  VIEW_DETAILS: 'Ver detalhes',
  COPY_SUMMARY: 'Copiar resumo',
  ROLE_PERMISSIONS: 'Permissões da função',
  ERROR_SUMMARY_COPIED_TO_CLIPBOARD: 'O resumo dos erros foi copiado para a área de transferência',
  PLEASE_SELECT_A_START_DATE: 'Selecione uma data de início.',
  PLEASE_SELECT_AN_END_DATE: 'Selecione uma data de fim.',
  SEASONALITY_TOOLTIP_MESSAGE:
    'É aqui que aplica o seu calendário de sazonalidade. Utilize estes calendários para dividir as quotas ao longo do seu ciclo de planeamento de acordo com as tendências sazonais.',
  PLANNED_QUOTA_TOOLTIP_MESSAGE:
    'Este é o valor final da quota planeada do seu ciclo de planeamento ativo. Inclui o seu objetivo de quota atribuída (descendente ou importada) mais quaisquer ajustamentos de quotas.',
  ASSIGNMENT_PERCENTAGE_TOOLTIP_MESSAGE:
    'Esta é a percentagem da quota de território atribuída aos vendedores durante as suas datas efetivas.',
  RAMP_TOOLTIP_MESSAGE:
    'É aqui que aplica o seu calendário do período de adaptação. Utilize estes calendários para integrar novos vendedores e adaptá-los até atingirem a produtividade total.',
  HISTORICAL_MEASURE_DATE_RANGE_TOOLTIP_MESSAGE:
    'Selecione uma data de início e de fim para incluir apenas dados de atividade dentro deste intervalo de datas. Se não selecionar uma data, iremos predefinir para a data de início e fim do ciclo de planeamento.',
  MAP_DISABLED_TOOLTIP_MESSAGE:
    'Para utilizar o mapa, adicione a hierarquia das regiões geográficas ao seu cartão de batalha como um tipo de grupo territorial',
  BULK_DELETE_DISABLED_TOOLTIP:
    'A função Eliminar está desativada enquanto "Selecionar tudo" está ativo e os filtros são aplicados.',
  NEW_FIELD: 'Novo campo',
  EXISTING_FIELD: 'Campo existente',
  ADD_EXISTING_FIELD: 'Adicionar campo existente',
  SELECT_MEASURE_NAME_REQUIRED: 'Selecione um nome de coluna.',
  SELECT_COLUMN_NAME: 'Selecione um nome de coluna',
  NO_EXISTING_MEASURES: 'Não existem medidas válidas',
  REMOVE_SELLER_ASSIGNMENT: 'Remover atribuição do vendedor',
  ACTIVATE: 'Ativar',
  ACTIVATE_WITH_NAME: 'Ativar {name}?',
  ACTIVATION_STARTED: 'Ativação iniciada',
  ACTIVATE_PLANNING_CYCLE: 'Ativar ciclo de planeamento',
  SELECT_A_TERRITORY: 'Selecionar um território',
  ACTIVATE_PLANNING_CYCLE_CONFIRMATION:
    'Está preparado(a) para ativar o seu ciclo de planeamento {name}? Isto irá criar uma nova versão de gestão do seu ciclo de planeamento de acordo com a versão final do seu plano. Depois de ativar a versão do seu plano, esta versão continuará disponível, mas deve ser utilizada apenas para referência.',
  ACTIVATE_PLANNING_CYCLE_MANAGE_VERSION:
    'A sua versão de gestão permite um planeamento contínuo ao longo do seu ciclo de planeamento. Utilize-a para adaptar-se às mudanças do mercado ou à rotatividade dos colaboradores e guarde a versão do seu plano para referência.',
  ACTIVATE_PLANNING_CYCLE_BEFORE_MESSAGE:
    'Antes de ativar este ciclo de planeamento, certifique-se de que foi aprovado por todos os interessados.',
  ACTIVATE_PLANNING_CYCLE_WARNING:
    'Só pode ativar o seu ciclo de planeamento uma vez e a ativação não pode ser anulada.',
  ACTIVATE_SCENARIO_CONFIRMATION:
    'Isto irá criar uma nova versão de gestão do seu cenário publicado. Depois de ativar o seu cenário publicado, este cenário continuará disponível, mas deve ser utilizado apenas como referência.',
  ACTIVATE_SCENARIO_MANAGE_VERSION:
    'A sua versão de gestão permite um planeamento contínuo ao longo do seu ciclo de planeamento. Utilize-a para adaptar-se às mudanças do mercado ou à rotatividade dos colaboradores e guarde o cenário publicado para referência.',
  ACTIVATE_SCENARIO_BEFORE_MESSAGE:
    'Antes de ativar este cenário, certifique-se de que foi aprovado por todos os interessados.',
  ACTIVATE_SCENARIO_WARNING:
    'Só pode ativar o cenário publicado para o seu ciclo de planeamento uma vez e a ativação não pode ser anulada.',
  SCENARIO_ACTIVATION_STARTED_MESSAGE:
    'Estamos a ativar o seu cenário {name}. Isto pode demorar alguns minutos. Não faça alterações ao seu ciclo de planeamento até que este processo esteja concluído.',
  ACTIVATION_STARTED_MESSAGE:
    'Estamos a ativar a versão de gestão do seu ciclo de planeamento {name}. Isto pode demorar alguns minutos. Não faça alterações ao seu ciclo de planeamento até que este processo esteja concluído.',
  CREATE_QUOTA_SHEET: 'Criar folha de quotas',
  QUOTA_SHEET_CREATED: 'Folha de quotas criada com sucesso',
  CREATE_QUOTA_SHEET_ERROR: 'Não é possível criar uma folha de quotas.',
  QUOTA_BLANK_NAME_ERROR: 'O nome da folha de quotas não pode estar em branco.',
  EDIT_SHEET_NAME: 'Editar nome da folha',
  SHEET_RENAME_SUCCESS: 'Nome da folha alterado com sucesso',
  REVISED_TERRITORY_QUOTA: 'Quota de território revista',
  ASSIGNED_SELLER_QUOTA: 'Quota de vendedor atribuída',
  CHANGE_HISTORY_EXPORT_STARTED: 'Exportação iniciada',
  CHANGE_HISTORY_EXPORT_ERROR: 'Exportação falhada',
  CHANGE_HISTORY_EXPORT_SUCCESS: 'Registo de auditoria exportado',
  EXPORT_CHANGE_HISTORY: 'Exportar registo de auditoria',
  EXPORT_CHANGE_HISTORY_DISABLED:
    'Estamos a exportar o seu registo de auditoria. Aguarde enquanto este processo é concluído.',
  CHANGE_HISTORY_TOAST:
    'Estamos a exportar o seu registo de auditoria para o ciclo de planeamento {planningCycleName}.',
  CHANGE_HISTORY_EXPORT_SUCCESS_DESCRIPTION:
    'Exportámos o seu registo de auditoria para o ciclo de planeamento {planningCycleName}.',
  ACTIVATION_FAILED: 'A ativação falhou',
  SCENARIO_ACTIVATION_FAILED_MESSAGE:
    'Não foi possível ativar o seu cenário {name}. Atualize a página e tente novamente.',
  ACTIVATION_FAILED_MESSAGE:
    'Não foi possível ativar o seu ciclo de planeamento {name}. Atualize a página e tente novamente.',
  ACTIVATE_PLANNING_CYCLE_COMPLETED: 'Ativação concluída',
  SCENARIO_ACTIVATION_COMPLETED_MESSAGE:
    'Ativámos o seu cenário {name}. Está agora a gerir o seu ciclo de planeamento.',
  ACTIVATION_COMPLETED_MESSAGE:
    'Ativámos o seu ciclo de planeamento {name}. Está agora a gerir o seu ciclo de planeamento.',
  EQUALS: 'Igual a',
  NOT_EQUAL: 'Diferente de',
  LESS_THAN: 'Menor do que',
  LESS_THAN_OR_EQUALS: 'Menor ou igual a',
  GREATER_THAN: 'Maior do que',
  GREATER_THAN_OR_EQUAL: 'Maior ou igual a',
  CONTAINS: 'Contém',
  NOT_CONTAINS: 'Não contém',
  STARTS_WITH: 'Começa com',
  ENDS_WITH: 'Termina com',
  COPY_WITH_NAME: 'Copiar {name}?',
  COPY_PC_MESSAGE:
    'Este ciclo de planeamento está ativo e existe uma versão de gestão. Quando copia este ciclo de planeamento, copiamos os dados da versão original do plano. Isto significa que quaisquer alterações feitas à versão de gestão não serão copiadas. O novo ciclo de planeamento incluirá apenas uma versão do plano até que opte por ativá-la. Pretende continuar?',
  DELETE_HIERARCHY_CONFIRMATION_MESSAGE:
    'Pretendia eliminar esta hierarquia? Isto irá eliminar a hierarquia e todos os seus membros. Esta ação também quebrará quaisquer regras de território que façam referência à hierarquia. A eliminação não pode ser anulada.',
  DELETE_HIERARCHY_MEMBER_CONFIRMATION_MESSAGE:
    'Pretendia eliminar este membro da hierarquia? Isto irá eliminar o membro da hierarquia e todos os seus subordinados. Esta ação também quebrará quaisquer regras de território que façam referência ao membro da hierarquia. A eliminação não pode ser anulada.',
  DELETE_HIERARCHY_MEMBERS_CONFIRMATION_MESSAGE:
    'Pretendia eliminar estes membros da hierarquia? Isto irá eliminar os membros da hierarquia e todos os seus subordinados. Esta ação também quebrará quaisquer regras de território que façam referência a qualquer destes membros da hierarquia. A eliminação não pode ser anulada.',
  DELETE_HIERARCHY_CUSTOM_ATTRIBUTE: 'Esta ação elimina os valores dos atributos de todos os membros.',
  QUOTA_GRID_ERROR:
    'Não foi possível gerar dados em grelha. Execute primeiro a ordenação de moedas para o cartão de batalha e tente novamente.',
  ADD_QUOTA_ADJUSTMENT: 'Adicionar ajustamento de quotas',
  EXCLUDE_COLUMN: 'Excluir coluna',
  DELETE_MULTIPLE_TERRITORIES_DIALOG_TITLE: 'Eliminar territórios?',
  DELETE_BC_TERRITORIES_CONFIRM:
    'Pretendia eliminar {count} territórios deste cartão de batalha? A eliminação não pode ser anulada.',
  DELETE_BC_TERRITORY_CONFIRM:
    'Pretendia eliminar {count} território deste cartão de batalha? A eliminação não pode ser anulada.',
  DELETE_ALL_BATTLECARD_TERRITORIES_CONFIRMATION_MESSAGE:
    'Pretendia eliminar todos os territórios deste cartão de batalha? A eliminação não pode ser anulada.',
  DELETE_BC_TERRITORIES_WITH_EXCEPTION:
    'Pretendia eliminar todos os territórios deste cartão de batalha, exceto os territórios {count} que não estão selecionados? A eliminação não pode ser anulada.',
  DELETE_BC_TERRITORY_WITH_EXCEPTION:
    'Pretendia eliminar todos os territórios deste cartão de batalha, exceto o território {count} que não está selecionado? A eliminação não pode ser anulada.',
  DELETE_TG_TERRITORIES_CONFIRM:
    'Pretendia eliminar {count} territórios deste grupo territorial? A eliminação não pode ser anulada.',
  DELETE_TG_TERRITORY_CONFIRM:
    'Pretendia eliminar {count} território deste grupo territorial? A eliminação não pode ser anulada.',
  DELETE_ALL_TERRITORY_GROUP_TERRITORIES_CONFIRMATION_MESSAGE:
    'Pretendia eliminar todos os territórios deste grupo territorial? A eliminação não pode ser anulada.',
  DELETE_TG_TERRITORIES_WITH_EXCEPTION:
    'Pretendia eliminar todos os territórios deste grupo territorial, exceto os territórios {count} que não estão selecionados? A eliminação não pode ser anulada.',
  DELETE_TG_TERRITORY_WITH_EXCEPTION:
    'Pretendia eliminar todos os territórios deste grupo territorial, exceto o território {count} que não está selecionado? A eliminação não pode ser anulada.',
  HOW_IT_WORKS: 'Como funciona:',
  START_OPTIMIZATION: 'Iniciar a otimização',
  CONTINUE_OPTIMIZATION: 'Continuar a otimização',
  EXPORT_CURRENT_TERRITORY_RULES: 'Exportar regras de território atuais',
  CONSTRAINTS: 'Restrições',
  PARAMETERS_AND_TERRITORY_RULES: 'Parâmetros e regras de território',
  OPTIMIZE_RESULTS: 'Otimizar resultados',
  OPTIMIZE_TERRITORIES: 'Otimizar territórios',
  FAIL_OPTIMIZE_TERRITORIES: 'Falha ao otimizar territórios',
  OPTIMIZATION_SETUP: 'Configuração de otimização',
  OPTIMIZE_TERRITORIES_FOR_TG: 'Otimizar territórios para {territoryGroup}',
  TERRITORY_OPTIMIZATION: 'Otimização do território',
  TERRITORY_OPTIMIZATION_TITLE: 'Otimização — a derradeira solução para melhorar o seu plano de vendas!',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP1:
    'Tem a opção de definir parâmetros e restrições para executar a ferramenta de otimização.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP2:
    'Depois, pode selecionar entre três cenários diferentes de territórios que melhor se alinham com os seus objetivos comerciais.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_WARNING:
    'Depois de publicar os resultados da otimização, as regras do seu território atual serão permanentemente alteradas.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_EXPORT:
    'Tem a opção de reverter as alterações exportando as suas regras de território atuais.',
  FAIL_BULK_DELETE_TERRITORY_TITLE: 'Falha ao eliminar territórios',
  REMOVE_RULE_HAS_OVERLAY_DEPENDENCY:
    'Algumas regras estão a ser utilizadas por uma sobreposição. Remova da sobreposição e tente novamente.',
  REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION:
    'O grupo de regras está atualmente a ser otimizado. Tente novamente após a conclusão da otimização do território.',
  FAIL_BULK_DELETE_TERRITORIES: 'Falha ao eliminar {count} territórios.',
  FAIL_BULK_DELETE_TERRITORY: 'Não foi possível eliminar {count} território.',
  FAIL_BULK_DELETE_TERRITORY_GENERIC: 'Falha ao eliminar os territórios selecionados.',
  START_BULK_DELETE_TERRITORY_TITLE: 'Os territórios estão a ser eliminados',
  START_BULK_DELETE_TERRITORY_GENERIC: 'Estamos a eliminar os territórios selecionados.',
  TERRITORY_DEFINITION_RADIO_LABEL:
    'Pretende que os seus territórios sejam definidos por contas ou por regiões geográficas?',
  RUN_TIME_SELECT_LABEL: 'Escolha um tempo de execução de acordo com o nível de exatidão pretendido.',
  METRIC_OPTIMIZATION_SELECT_LABEL: 'Selecione um atributo ou métrica para otimizar os seus territórios.',
  NUMBER_OF_TERRITORIES: 'Número de territórios',
  GEOGRAPHIC_BASED_RULES: 'Regras de base geográfica',
  CONSTRAINTS_PAGE_DESCRIPTION: 'Pode definir restrições para as suas contas e territórios resultantes.',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_ACCOUNTS: 'Não transfira contas se todas as condições seguintes forem verdadeiras',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_TERRITORIES:
    'Otimize territórios para que todas as condições seguintes sejam verdadeiras',
  CURRENT_STATE: 'Estado atual',
  CURRENT_SCENARIO: 'Cenário atual',
  MOST_BALANCED: 'Mais equilibrados',
  MOST_CONTIGUOUS: 'Mais contíguos',
  OPTIMIZE: 'Otimizar',
  DISRUPTION: 'Perturbação',
  TERRITORY_MAP: 'Mapa do território',
  BATTLE_CARD_1: 'Cartão de batalha 1:',
  BATTLE_CARD_2: 'Cartão de batalha 2:',
  SCENARIO_PLANNING_BC_PICKER_LABEL: 'Cartão de batalha:',
  WITH: 'Com',
  COMPARE: 'Comparar',
  COMPARE_SELECTION: '{scenarioName} / {quotaComponentName} / ',
  SCENARIO_NAME: 'Nome do cenário',
  SEE_ALL_SCENARIOS: 'Ver todos os cenários',
  SCENARIO_RENAMED: 'Nome de cenário alterado',
  SCENARIO_RENAMED_MESSAGE: 'Mudámos o nome do seu cenário para {name}.',
  SCENARIO_NAME_EXISTS: 'O nome de cenário "{name}" já existe.',
  SCENARIO_RENAMED_FAILURE: 'Falha ao mudar o nome do cenário',
  RENAME_SCENARIO: 'Mudar o nome do cenário',
  CHARACTER_COUNT_READOUT: '{currentLength}/{maxLength} caracteres',
  CREATE_SCENARIO: 'Criar cenário',
  DELETING_SCENARIO: 'Eliminar cenário',
  SCENARIO_LIMIT_REACHED: 'Limite de cenários atingido',
  SCENARIO_LIMIT_DIALOG_BODY:
    'Atingiu o limite de {limit} cenários para o seu {name} ciclo de planeamento. Elimine cenários existentes para libertar espaço.',
  SCENARIO_COPY_FAILED: 'Falha ao copiar cenário',
  SCENARIO_CREATE_FAILED: 'Falha ao criar cenário',
  SCENARIO_CREATED: 'Cenário criado',
  SCENARIO_CREATED_MESSAGE: 'Criámos o seu {name} cenário.',
  COPY_SCENARIO: 'Copiar cenário',
  COPY_SCENARIO_NAME: '{name} (cópia)',
  SCENARIO_COPY_STARTED: 'Cópia de cenário iniciada',
  SCENARIO_COPY_MESSAGE:
    'Estamos a copiar o seu {name} cenário. Não faça alterações ao seu cenário até que este processo esteja concluído.',
  SCENARIO_COPIED: 'Cenário copiado',
  SCENARIO_COPIED_MESSAGE: 'Copiámos o seu {name} cenário.',
  SCENARIO_DELETED_MESSAGE: 'Eliminámos o seu {name} cenário.',
  DELETION_STARTED_MESSAGE: 'Estamos a eliminar o seu {name} cenário.',
  BLANK_SCENARIO: 'Cenário em branco',
  NO_RECENT_SCENARIOS: 'Não existem outros cenários abertos recentemente.',
  NAME_REQUIRED: 'Nome *',
  OPEN_RECENT: 'Abrir recente',
  MANAGE_SCENARIOS: 'Gerir cenários',
  ACTIVATE_SCENARIO: 'Ativar cenário',
  SCENARIO_WITH_COLON: 'Cenário:',
  COMPARE_MODE: 'Modo Comparar',
  RENAME: 'Mudar o nome',
  COMPARE_MODE_ACROSS_DM_DIALOG_BODY:
    'Veja, edite e compare mapas de dois cartões de batalha a partir de qualquer cenário no seu ciclo de planeamento.',
  COMPARE_MODE_WITHIN_DM_DIALOG_BODY:
    'Veja, edite e compare mapas de dois cartões de batalha a partir do seu ciclo de planeamento.',
  COMPARE_MODE_TOOLTIP_TITLE: 'Entrou no modo Comparar',
  COMPARE_MODE_TOOLTIP_BODY: 'As alterações feitas no modo Comparar serão aplicadas aos seus cartões de batalha.',
  COMPARE_MODE_ERROR_MISSING_REQUIRED:
    'Selecione um cenário, componente de quota e cartão de batalha para cada mapa que pretende comparar.',
  COMPARE_MODE_ERROR_IDENTICAL_BC: 'Não é possível comparar mapas do mesmo cartão de batalha do mesmo cenário.',
  ACCOUNT_MOVES: 'Transferências de conta',
  OPTIMIZATION_METRICS: 'Métricas de otimização',
  OPTIMIZING_TERRITORIES: 'Estamos a otimizar os seus territórios.',
  OPTIMIZING_TERRITORIES_DESCRIPTION:
    'Este processo pode demorar vários minutos. Receberá uma notificação quando a otimização estiver concluída.',
  OPTIMIZATION_FAILED: 'Falha na otimização',
  OPTIMIZATION_FAILED_DESCRIPTION:
    'Não foi possível otimizar os seus territórios de acordo com os parâmetros e restrições selecionados. Atualize os seus parâmetros ou restrições e tente novamente.',
  OPTIMIZATION_SUCCESS: 'Os seus territórios estão otimizados.',
  OPTIMIZED_TERRITORIES: 'Otimizámos os seus territórios para o seu tipo de grupo territorial {territoryGroup}.',
  BACK_TO_CONSTRAINTS: 'Voltar às restrições',
  VIEW_RESULTS: 'Ver resultados',
  OPTIMIZATION_RESULTS: 'Resultados de otimização para {territoryGroup}',
  MIN_NUMBER_OF_ACCOUNTS: 'Número mínimo de contas',
  MAX_NUMBER_OF_ACCOUNTS: 'Número máximo de contas',
  DISRUPTION_ACCOUNTS_REASSIGNED: 'Perturbação (contas reatribuídas)',
  CURRENT_STATE_DESCRIPTION: 'A sua configuração original de territórios e contas',
  MOST_BALANCED_DESCRIPTION: 'Uma distribuição equitativa do atributo selecionado pelos territórios',
  MOST_CONTIGUOUS_DESCRIPTION: 'Territórios geograficamente contíguos que partilham uma fronteira comum',
  UNSELECT: 'Desmarcar',
  OPTIMIZATION_RESULTS_PAGE_HEADER_DESCRIPTION:
    'Selecione um cenário para publicar nas regras do seu território. Pode pré-visualizar um cenário para ver uma discriminação dos territórios e métricas de otimização.',
  PUBLISH_CHANGES_TO: 'Publicar alterações a {value}',
  PUBLISH_OPTIMIZATION_DESCRIPTION:
    'Está prestes a publicar alterações às suas regras de território que irão ordenar as suas contas de acordo com a otimização executada.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_EMPHASIS:
    'A publicação irá alterar permanentemente as suas regras de território atuais.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_WARNING:
    'É possível reverter as alterações exportando as regras de território atuais. Tem a certeza de que pretende continuar?',
  EXIT_OPTIMIZATION: 'Sair da otimização',
  PUBLISHED_OPTIMIZATION_TITLE: 'Otimizou o seu {territoryGroup} grupo territorial!',
  VIEW_OPTIMIZATION_SUMMARY_TEXT:
    'Enquanto atualizamos as regras do seu território, aqui está um resumo dos seus novos territórios.',
  SCORE_BREAKDOWN: 'Discriminação da pontuação',
  OPTIMIZATION_PUBLISH_SUBTITLE:
    'Os seus territórios estão agora equilibrados de acordo com a métrica selecionada, {selectedOptimizationMetric}.',
  OPTIMIZATION_PUBLISH_SUBTITLE_GENERAL:
    'Os seus territórios estão agora equilibrados de acordo com a métrica selecionada.',
  TERRITORY_QUOTA: 'Quota do território',
  SELLER_QUOTA: 'Quota do vendedor',
  ACCOUNT_QUOTA: 'Quota da conta',
  TERRITORY_BALANCING: 'Equilíbrio do território',
  ACCOUNT_LIST: 'Lista de contas',
  ASSIGNED_SELLERS: 'Atribuímos {count} vendedores a {territory}',
  LOADING_SHEETS_DATA: 'A carregar dados de folhas...',
  BOOK_OF_BUSINESS: 'Livro de registo comercial',
  YEARLY: 'Anualmente',
  SEMI_ANNUAL: 'Semestralmente',
  QUARTERLY: 'Trimestralmente',
  MONTHLY: 'Mensalmente',
  SORTING_COINSORT: 'A ordenar territórios ({currentNumberOfRulesProcessed} de {currentNumberOfRules})...',
  INPUT: 'Inserção',
  HISTORICAL: 'Histórico',
  LOADED: 'Carregado',
  ALLOCATION: 'Atribuição',
  CALCULATED: 'Calculado',
  TERRITORY_GROUP_TYPE_OWNER: 'Titular do tipo de grupo territorial',
  TERRITORY_GROUP_OWNER: 'Titular do grupo territorial',
  TERRITORY_GROUP_AND_GROUP_TYPE_OWNER: 'Titular do tipo de grupo territorial e grupo territorial',
  BATTLE_CARD_OWNER: 'Titular do cartão de batalha',
  ADMINISTRATOR: 'Administrador',
  CONTRIBUTOR: 'Contribuidor',
  NONE: 'Nenhum',
  NAME_IS_A_REQUIRED_FIELD: 'O nome é um campo obrigatório',
  IN_PROGRESS: 'Em curso',
  APPROVED: 'Aprovado',
  SUBMITTED: 'Submetido',
  REJECTED: 'Rejeitado',
  APPROVER: 'Aprovador',
  ACCOUNT_KEY: 'Chave da conta',
  MEMBERS_DELETE_SUCCESS_WITH_COUNT: '{count} membros eliminados com sucesso.',
  MEMBERS_WITH_COUNT: '{count} membros',
  SEND: 'Enviar',
  DELETE_VALUE: 'Eliminar {value}',
  DELETE_SELLER_FROM_TERRITORY_SUCCESS_MESSAGE: 'Removemos o {sellerCount} vendedor do território {terCount}.',
  DELETE_SELLER_FROM_TERRITORIES_SUCCESS_MESSAGE: 'Removemos o {sellerCount} vendedor dos territórios {terCount}.',
  DELETE_SELLERS_FROM_TERRITORY_SUCCESS_MESSAGE: 'Removemos os {sellerCount} vendedores do território {terCount}.',
  DELETE_SELLERS_FROM_TERRITORIES_SUCCESS_MESSAGE: 'Removemos os {sellerCount} vendedores dos territórios {terCount}.',
  ITEMS_PLUS_OTHERS: '{itemsList} (+{otherCount} outros)',
  REMOVE_CONFLICT: 'Removemos com sucesso o conflito {count} de {ruleId}',
  REMOVE_CONFLICTS: 'Removemos com sucesso os conflitos {count} de {ruleId}',
  UPDATE_PC_SUCCESS: 'Guardámos as alterações ao seu ciclo de planeamento {planningCycleName}.',
  UPDATE_PC_FAILURE:
    'Não foi possível guardar as atualizações ao seu ciclo de planeamento {planningCycleName}. Por favor, tente novamente.',
  TERRITORIES_DELETED_TOAST_TITLE: 'Territórios eliminados',
  TERRITORIES_DELETED_TOAST_MESSAGE: 'Eliminámos os {count} territórios.',
  TERRITORIES_DELETED_TOAST_MESSAGE_GENERIC: 'Eliminámos todos os territórios selecionados.',
  ACCOUNT_FILTER: 'Filtro de conta',
  FILTERS: 'Filtros',
  SHOW_SELECTED_TERRITORY_ACCOUNTS: 'Mostrar contas para o território selecionado',
  SHOW_ALL_ACCOUNTS: 'Mostrar todas as contas',
  BASE_OVERRIDE_ACCOUNTS: 'Contas de base e de precedência',
  OVERRIDE_ACCOUNTS_ONLY: 'Apenas contas de precedência',
  FILTER_ACCOUNTS_TERRITORY_HEADER: 'Filtrar contas por território',
  FILTER_ACCOUNTS_TYPE_HEADER: 'Filtrar contas por tipo',
  EMBEDDED_MAP_FALLBACK_HEADER: 'Ups! Ocorreu um erro.',
  EMBEDDED_MAP_FALLBACK_BODY_ERROR: 'Tente novamente mais tarde.',
  EMBEDDED_MAP_FALLBACK_BODY_NO_ACCESS: 'Parece que não tem acesso. Contacte o seu administrador.',
  CONSTRAINT_FIELD_ERROR: 'Preencha o valor da restrição',
  PLEASE_SELECT_A_METRIC: 'Selecione uma métrica',
  ACCOUNT_ADDITIONS: 'Adições de contas',
  ACCOUNT_REMOVALS: 'Remoções de conta',
  EXISTING_ACCOUNTS: 'Contas existentes',
  ZIP_CODE: 'Código postal',
  LETTER_GRADE: 'Classificação por letras',
  SIZE: 'Tamanho',
  ACCOUNT_ID: 'ID da conta',
  TARGET_SETTING: 'Definição de objetivo',
  TARGET_SETTING_DESCRIPTION:
    'Selecione uma hierarquia personalizada para definir objetivos de quota para membros individuais da hierarquia',
  PC_START_DATE_POST_COINSORT_FAILURE: 'Não é possível atualizar a data de início do CP.',
  PC_START_DATE_POST_COINSORT_FAILURE_MESSAGE:
    'Não é possível atualizar a {planningCycleName} data de início. Tente novamente depois de rever os seus territórios e de efetuar uma ordenação de moedas bem-sucedida.',
  FAILED_TO_LASSO_PINS: 'Falha ao marcar pinos com laço. Por favor, tente novamente.',
  EXIT_OPTIMIZATION_CONFIRMATION:
    'As suas alterações não serão guardadas. Tem a certeza de que pretende sair da otimização?',
  EXIT: 'Sair',
  UNABLE_TO_RETRIEVE_OPTIMIZATION_STATUS: 'Não foi possível recuperar o estado da otimização',
  CANNOT_START_OPTIMIZATION_USER_MESSAGE:
    'Não é possível iniciar a otimização porque já existe uma iniciada por outro utilizador.',
  RESTART_OPTIMIZATION: 'Reiniciar otimização',
  RESTART_OPTIMIZATION_DESCRIPTION:
    'O resultado da otimização atual será eliminado. Tem a certeza de que pretende reiniciar a otimização?',
  MAINTENANCE_MODE_TEXT_1: 'Lamentamos! Estamos a trabalhar numa coisa!',
  MAINTENANCE_MODE_TEXT_2:
    'Para garantir que continuamos a proporcionar-lhe uma experiência de utilizador de alto nível, neste momento estamos a efetuar alguns trabalhos de manutenção planeada no nosso sistema. De momento, não é possível aceder ao produto Sales Planning. Tente novamente mais tarde.',
  UNLOCK_TERRITORY: 'Desbloquear território',
  LOCK_TERRITORY: 'Bloquear território',
  SHOW_TERRITORY: 'Mostrar território',
  LOCK_ALL_TERRITORIES: 'Bloquear todos os territórios',
  UNLOCK_ALL_TERRITORIES: 'Desbloquear todos os territórios',
  HIDE_TERRITORY: 'Ocultar território',
  SHOW_ALL_TERRITORIES: 'Mostrar todos os territórios',
  HIDE_ALL_TERRITORIES: 'Ocultar todos os territórios',
  QUOTA_BREAKDOWN_WARNING:
    'Se atualizar as regras do território, os ficheiros de atividade ou as hierarquias selecionadas para a repartição de quotas, terá de voltar a executar a ordenação de moedas e a atribuição descendente para que os dados mais recentes sejam refletidos.',
  TENANT_ACCESS_ERROR: 'Não tem acesso a este inquilino',
  TENANTS_LIST_HEADER: 'Inquilinos em que estou atualmente',
  EMPTY_TENANT_LIST_MESSAGE: 'Contacte o seu administrador de inquilinos para obter um convite.',
  UNASSIGN_GEO_TITLE: 'Anular atribuição do território',
  UNASSIGN_GEO_BODY:
    'Tem a certeza de que pretende anular a atribuição de {hierarchyCount} regiões geográficas de {ruleNamesTruncated}?',
  UNASSIGN_GEO_SUCCESS_TOAST: 'Anulação da atribuição de regiões geográficas efetuada com sucesso.',
  UNASSIGN_GEO_ERROR_TOAST: 'Não foi possível anular a atribuição de regiões geográficas.',
  UNASSIGN_ACCOUNT_TITLE: 'Anular atribuição de precedências',
  UNASSIGN_ACCOUNT_BODY:
    'Tem a certeza de que pretende anular a atribuição de contas {hierarchyCount} de {ruleNamesTruncated}?',
  UNASSIGN_OVERRIDE_SUCCESS_TOAST: 'Atribuição de precedências anulada com sucesso.',
  UNASSIGN_OVERRIDE_ERROR_TOAST: 'Não foi possível anular a atribuição de precedências.',
  UNASSIGN_OVERRIDE_TOOLTIP: 'Isto irá permitir-lhe anular a atribuição de contas de precedência selecionadas.',
  REASSIGN_TITLE: 'Reatribuir a território existente',
  MAP_ACTION_MENU_REASSIGN_GEO: 'Reatribuir regiões geográficas',
  REASSIGN_GEO_BODY: 'Selecione um território ao qual pretende reatribuir as {count} regiões geográficas',
  REASSIGN_GEO_SUCCESS_TOAST: 'Regiões geográficas reatribuídas com sucesso.',
  REASSIGN_GEO_ERROR_TOAST: 'Não foi possível reatribuir as regiões geográficas.',
  MAP_ACTION_MENU_REASSIGN_ACCOUNT: 'Reatribuir contas',
  REASSIGN_ACCOUNT_BODY: 'Selecione um território ao qual pretende reatribuir contas {count}',
  REASSIGN_ACCOUNT_SUCCESS_TOAST: 'Contas reatribuídas com sucesso.',
  REASSIGN_ACCOUNT_ERROR_TOAST: 'Não foi possível reatribuir contas.',
  COMPLEX_RULES_STATUS: 'Regras complexas {status}',
  ON: 'LIGADO',
  OFF: 'DESLIGADO',
  FUNCTIONALITY: 'Funcionalidade',
  MAPS: 'Mapas',
  HIERARCHY: 'Hierarquia',
  CUSTOM: 'Personalizar',
  DATA_SOURCE: 'Fonte de dados',
  FORMAT_CODE: 'Código de formatação',
  INSERT_CALCULATION: 'Inserir cálculo',
  WELCOME_BACK: 'Seja bem-vindo(a)!',
  CHOOSE_A_TENANT: 'Escolha um inquilino para utilizar o Sales Planning.',
  SELECT_TENANT: 'Selecionar inquilino',
  IMPERSONATE_USER: 'Imitar utilizador',
  USER_EMAIL_TO_IMPERSONATE: 'E-mail do utilizador a imitar',
  IMPERSONATE: 'Imitar',
  EXIT_IMPERSONATION: 'Sair da imitação',
  FAILED_TO_GENERATE_IMPERSONATION_TOKEN: 'Falha ao gerar token de imitação. Por favor, tente novamente.',
  CUSTOM_HIERARCHY_FILTER_TITLE: 'Filtro de hierarquia personalizado',
  CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE: 'filtro {hierarchyName}',
  CUSTOM_HIERARCHY_FILTER_KIND_LABEL: 'Tipo de filtro',
  CUSTOM_HIERARCHY_FILTER_IDS_LABEL: 'Selecionar hierarquias',
  CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER: 'Não há hierarquias em utilização',
  COLLECTION_FILTER_CONTAINS_ANY: 'Contém qualquer',
  COLLECTION_FILTER_NOT_CONTAINS_ANY: 'Não contém',
  COLLECTION_FILTER_EQUALS_TO: 'É igual a',
  COLLECTION_FILTER_NOT_EQUALS_TO: 'Não é igual a',
  NO_EDIT_PERMISSION: 'Não tem permissão para editar isto',
  TOO_MANY_SELLERS:
    'Só pode adicionar {maxSellers} vendedores a um território. \nElimine um vendedor antes de adicionar um novo.',
  REMOVE_GEO_HIER_HAS_DEPENDENCY:
    'Não é possível remover regiões geográficas, uma vez que estão a ser utilizadas pelos territórios: {territoryIds}',
  START_FILE_PROCESS_API_KEY_NOT_FOUND:
    'Chave API não encontrada para o utilizador {emailAddress}. O utilizador tem de gerar uma chave API a partir do menu de perfil',
  QUOTA_FILE_UPLOAD_NOTES: 'Algumas notas sobre a utilização do modelo gerado:',
  QUOTA_FILE_UPLOAD_WARNING_1: 'Antes de transferir, tem de executar primeiro a ordenação das moedas.',
  QUOTA_FILE_UPLOAD_WARNING_2:
    'Este modelo destina-se apenas ao componente de quota selecionado. Tem de importar quotas para cada componente de quota no cartão de batalha.',
  QUOTA_FILE_UPLOAD_WARNING_3:
    'A alteração do modelo causará erros de integridade de dados. Atualize apenas a coluna Valor com metas de objetivos.',
  ACCOUNT_TOOLTIP_MULTIPLE_TERRITORIES_MESSAGE: 'Esta conta pertence a {geoCount} territórios',
  REMOVE_DEFAULT_BALANCING_METRIC:
    'Não é possível eliminar esta medida, uma vez que requer uma medida de equilíbrio predefinida.',
  REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY:
    'Não é possível eliminar esta medida porque está a ser utilizada em {measureName}.',
  OTHER_MEASURES: 'outras medidas',
  IMPORT_ACTIVITY_FILE_CALLOUT_MESSAGE:
    'Assumimos que todos os valores de vendas utilizam a moeda de apresentação do seu ciclo de planeamento.',
  MEASURE_NAME_VALIDATION_MESSAGE: 'Os nomes dos campos não podem conter os seguintes caracteres: .,\'"{}',
  ACCOUNT_RULE_GRID_ERROR: 'Não foi possível carregar a lista de contas.',
  ALLOCATE_QUOTAS: 'Atribuir quotas',
  ALLOCATING_TOP_DOWN: 'Atribuição de cima para baixo',
  ALLOCATING_TOP_DOWN_LOADING_MESSAGE: 'Aguarde enquanto atribuímos os seus objetivos ao seu {jobName}',
  SINGLE_ACCOUNT_TERRITORY: 'Território de conta única',
  SINGLE_ACCOUNT_TERRITORY_DISABLE_SAVE_TOOLTIP_TEXT:
    'Não é possível atribuir mais do que uma conta a um único território de conta',
  COPILOT: 'Copiloto',
  ASSISTANT_HEADER_TITLE: 'Copiloto do Sales Planning',
  ASSISTANT_YOU: 'Você',
  ASSISTANT_HEADER_CLEAR: 'Limpar chat',
  ASSISTANT_HEADER_HIDE: 'Ocultar',
  ASSISTANT_INPUT_PLACEHOLDER: 'Posso ajudar em alguma coisa?',
  ASSISTANT_EMPTY_TITLE: 'O que é que posso fazer por si hoje?',
  ASSISTANT_EMPTY_SUBTITLE: 'Pergunte-me qualquer coisa sobre o Sales Planning',
  ASSISTANT_PROMPT_GENERAL: 'Perguntas gerais',
  ASSISTANT_PROMPT_GENERAL_EXAMPLE: 'Exemplo: Onde está alinhada uma conta, vendedor, território?',
  ASSISTANT_PROMPT_GENERAL_INPUT: 'Onde está alinhada [Account]?',
  ASSISTANT_PROMPT_INSIGHTS: 'Informações e análise',
  ASSISTANT_PROMPT_INSIGHTS_EXAMPLE: 'Exemplo: Comparar as principais métricas entre dois mapas',
  ASSISTANT_PROMPT_INSIGHTS_INPUT:
    'Comparar as principais métricas entre os mapas [Battle card name] e [Battle card name]',
  ASSISTANT_PROMPT_ACTIONS_EXAMPLE: 'Exemplo: Transferir uma conta para outro território',
  ASSISTANT_PROMPT_ACTIONS_INPUT: 'Transferir [Account] para [Territory]',
  ASSISTANT_PROMPT_HISTORICAL: 'Pesquisa histórica',
  ASSISTANT_PROMPT_HISTORICAL_EXAMPLE: 'Exemplo: Quem foi o último utilizador a fazer alterações?',
  FILE_UPLOAD_INVALID_FIRST_ROW_ERROR:
    'Confirme se a primeira linha de dados no ficheiro carregado começa por "#". Em caso afirmativo, pode evitar este erro movendo a primeira coluna para uma posição diferente. Caso contrário, contacte a assistência.',
  ASSISTANT_PROMPT_HISTORICAL_INPUT: 'Quem foi o último utilizador a fazer alterações a [Territory]',
  STATUS_MENU_COINSORT_IN_PROGRESS: 'Executar ordenação de moedas para {battleCardName}',
  STATUS_MENU_COINSORT_COMPLETED: 'A ordenação das moedas foi executada para {battleCardName}',
  STATUS_MENU_COINSORT_FAILED: 'Falha ao executar a ordenação de moedas para {battleCardName}',
  COINSORT_COMPLETED_TOAST_MESSAGE: 'Corrigimos a ordenação de moedas para o seu cartão de batalha {battleCardName}.',
  COINSORT_FAILED_TOAST_MESSAGE:
    'Não foi possível efetuar a ordenação de moedas para o componente de quota {quotaComponentName} no seu cartão de batalha {battleCardName}.',
  ALL_DONE: 'Tudo concluído!',
  ALL_TASKS_COMPLETED: 'Não tem quaisquer tarefas em curso.',
  TASKS_IN_PROGRESS: '{numOfTasks} tarefas em curso',
  COIN_SORTING_COMPLETED: 'Ordenação de moedas concluída',
  COIN_SORTING_FAILED: 'Falha na ordenação de moedas',
  VIEW_STATUS: 'Ver estado',
  COIN_SORTING_IN_PROGRESS: 'Ordenação de moedas em curso',
  RUN_COIN_SORT_DISABLED_MESSAGE:
    'Não podemos executar a ordenação de moedas para o componente de quota {quotaComponentName} no seu cartão de {battleCardName} batalha porque já está a decorrer uma ordenação de moedas.',
  RUN_COIN_SORT: 'Executar ordenação de moedas',
  STARTED_BY: 'Iniciada por: {userName}',
  DISABLE_ADD_QUOTA_ADJUSTMENT_TOOLTIP:
    'Adicione a quota planeada antes de adicionar o ajustamento de quotas do território',
  STARTED: 'Iniciada:',
  JUST_NOW: 'agora mesmo',
  A_MINUTE_AGO: 'há um minuto',
  ONE_HOUR_AGO: 'há 1 hora',
  HOURS_AGO: 'há {numHours} horas',
  MINUTES_AGO: 'há {numMinutes} minutos',
  ADJUSTMENT_EFFECTIVE_DATE_WARNING_TOOLTIP:
    'As datas efetivas dos ajustamentos de quotas não coincidem com as datas efetivas do território. Atualize as datas efetivas de ajustamento de quotas de modo a alinhá-las com as datas efetivas do território.',
  ASCENDING: 'Ascendente',
  DESCENDING: 'Descendente',
  START_DATE_REQUIRED_MARK_QUOTA_MOVE: 'Data de início efetiva *',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_1:
    'Adicione um território de destino, uma data de início e uma atribuição de quotas para ',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_2: 'Isto será aplicado a todos os componentes de quota',
  TERRITORY_REQUIRED_MARK_V2: 'Território de destino *',
  QUOTA_REALLOCATION_MARK: 'Método de reatribuição de quotas *',
  MOVE_PRORATED_QUOTA: 'Transferir quota proporcional',
  DONT_MOVE_QUOTA: 'Não transferir quota',
  SEASONALITY_DISABLE_TOOLTIP_TEXT:
    'A sazonalidade não pode ser alterada porque as quotas são definidas ao nível da hierarquia e não ao nível do território.',
  TERRITORY_EFFECTIVE_DATE_DISABLE_TOOLTIP_TEXT:
    'A data efetiva para este território não pode ser alterada porque existem redirecionamentos de conta associados a este território.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_ACCOUNT_VALUE:
    'O método de reatribuição de quotas não está disponível se o valor da quota da conta dos componentes de quota for nulo ou inexistente.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_OVERLAP_REDIRECT_DATE:
    'O método de reatribuição de quotas não está disponível se o período de redirecionamento não coincidir com as datas efetivas das regras de território do destino pretendido.',
  TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN: 'Data efetiva do território: {effectiveDate} – {endDate}',
  ADJUSTMENT_TYPE: 'Tipo de ajuste',
  ACCOUNT_MOVED_FROM: 'Conta transferida de',
  ACCOUNT_MOVED_TO: 'Conta transferida para',
  ALIGNMENT_EFFECTIVE_DATE: 'Data efetiva do alinhamento'
};

export default ptPT;
