import { useMutation, MutationTuple } from '@apollo/client';

import { GenerateAPIKey, GenerateAPIKeyVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GENERATE_API_KEY } from 'app/graphql/mutations/generateApiKey';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';

export const useGenerateApiKey = (): MutationTuple<GenerateAPIKey, GenerateAPIKeyVariables> =>
  useMutation<GenerateAPIKey, GenerateAPIKeyVariables>(GENERATE_API_KEY, {
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast('failed to create api key', 'danger');
    }
  });
