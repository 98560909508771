import { useState } from 'react';

import { useMutation } from '@apollo/client';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  AddFileMetadata,
  AddFileMetadataVariables,
  AddFileMetadata_addFileMetadata,
  DeleteFile,
  DeleteFileVariables,
  FileTypeEnum
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { ADD_FILE_METADATA } from 'app/graphql/mutations/addFileMetadata';
import { DELETE_FILE } from 'app/graphql/mutations/deleteFile';

import { FileType, FileUploadResult } from 'app/models';

import { uploadFileViaS3 } from 'utils/helpers/awsS3Wrapper';
import { validateFile } from 'utils/helpers/fileUploadUtils';
// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseFileUploadValue {
  startUpload: (fileName: string, file: File) => Promise<FileUploadResult>;
  isUploading: boolean;
  isCompleted: boolean;
  error: string;
}

export const useFileUpload = (fileUploadType: FileTypeEnum | FileType): UseFileUploadValue => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { selectedPlanningCycle } = useScope();
  const { selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();

  const [addFileMetadata] = useMutation<AddFileMetadata, AddFileMetadataVariables>(ADD_FILE_METADATA, {
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('BEGIN_UPLOAD_ERROR'), 'danger');
      setError(formatMessage('BEGIN_UPLOAD_ERROR'));
    }
  });

  const [deleteFileMetadata] = useMutation<DeleteFile, DeleteFileVariables>(DELETE_FILE, {
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const handleS3FileUpload = async (fileMetadata: AddFileMetadata_addFileMetadata, fileToUpload: File) => {
    try {
      await uploadFileViaS3({
        ...fileMetadata,
        body: fileToUpload
      });
    } catch (err) {
      console.log('err', err);
      let errorMessage = '';
      if (err instanceof Error) {
        errorMessage = formatMessage('DATA_FILE_UPLOAD_SEQUENCE_ERROR', { message: err.message });
      }
      // eslint-disable-next-line deprecation/deprecation
      showToast(errorMessage, 'danger');
      setError(errorMessage);
      // We don't await the results as the delete failing is not a case we handle
      deleteFileMetadata({ variables: { fileId: fileMetadata.fileId } });
      throw err;
    }
  };

  const startUpload = async (fileName: string, file: File): Promise<FileUploadResult> => {
    try {
      if (validateFile(fileName, null, null)) {
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('FILE_NAME_VALIDATION_ERROR'), 'danger');
        setError(formatMessage('FILE_NAME_VALIDATION_ERROR'));
        throw new Error(formatMessage('FILE_NAME_VALIDATION_ERROR'));
      }
      setIsCompleted(false);
      setIsUploading(true);
      setError('');
      const { data } = await addFileMetadata({
        variables: {
          fileName,
          planningCycleId: selectedPlanningCycle?.id,
          fileType: fileUploadType,
          battlecardId: +selectedBattleCardId,
          quotaComponentId: selectedQuotaComponentId
        }
      });
      await handleS3FileUpload(data.addFileMetadata, file);
      setIsCompleted(true);
      setIsUploading(false);
      const metaData = data.addFileMetadata;
      return {
        ...metaData,
        tableId: metaData.fileId,
        tableName: metaData.fileName,
        tableDataType: metaData.fileType,
        published: false
      };
    } catch (err) {
      setIsUploading(false);
      throw err;
    }
  };

  return {
    startUpload,
    isUploading,
    isCompleted,
    error
  };
};
