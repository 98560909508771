// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EImW0 {\n  height: 48px;\n  background-color: rgb(var(--color-light-gray-5));\n  box-shadow: inset 0 -1px var(--border);\n  display: flex;\n}\n\n  .PHiWE {\n    display: flex;\n    flex-direction: column;\n    max-height: 300px;\n    width: 350px;\n    padding: var(--m);\n    border-radius: var(--s);\n    overflow-y: auto;\n  }\n\n  .sgywQ {\n    display: flex;\n    justify-content: flex-start;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    align-items: center;\n    padding: var(--s) var(--m);\n  }\n\n  .lIY-O {\n    background: white;\n    padding: 2px var(--s);\n    border-radius: 50%;\n    margin-left: var(--xs);\n  }\n\n  .f\\+Nbb {\n    display: flex;\n    justify-content: flex-end;\n  }\n\n  .JueVu {\n    margin-bottom: var(--xs);\n  }\n\n  .DMGwG {\n    padding: 0;\n  }\n\n  .EImW0 .bp3-skeleton {\n    width: 50px;\n    height: 30px;\n    border-radius: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryBalancingGridRuleFilter": "EImW0",
	"TerritoryBalancingGridRuleFilter_filterWrapper": "PHiWE",
	"TerritoryBalancingGridRuleFilter_container": "sgywQ",
	"TerritoryBalancingGridRuleFilter_filterCount": "lIY-O",
	"TerritoryBalancingGridRuleFilter_buttonContainer": "f+Nbb",
	"TerritoryBalancingGridRuleFilter_menuTitle": "JueVu",
	"TerritoryBalancingGridRuleFilter_colorByTerritoryToastButton": "DMGwG"
};
export default ___CSS_LOADER_EXPORT___;
