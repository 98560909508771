import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Account, InventoryManagement, OverflowMenuHorizontal } from '@carbon/icons-react';

import Popover from 'components/Popover/Popover';

import { useScope } from 'app/contexts/scopeProvider';

import { GetAllHierarchiesVariables } from 'app/graphql/generated/apolloTypes';

import { CommandCenterDrawerState, ICellRendererParamsWithContext } from 'app/models';

import block from 'utils/bem-css-modules';
// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

import { CommandCenterHierarchyGridContext } from './CommandCenterHierarchyPanel';
import ConfirmDeleteHierarchyModal from './ConfirmDeleteHierarchyModal';
import style from './HierarchyNodeCellRenderer.module.pcss';
import { useUpdateHierarchyParent } from './hooks/useUpdateHierarchyParent';

const b = block(style);

interface HierarchyNodeCellRendererProps extends ICellRendererParams {
  params: ICellRendererParamsWithContext<CommandCenterHierarchyGridContext>;
  rootHierarchy;
  setSelectedNode: Dispatch<SetStateAction<ICellRendererParams>>;
  setCommandCenterDrawerState: Dispatch<SetStateAction<CommandCenterDrawerState>>;
  setInitialBlock;
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  getAllHierarchiesVariables: GetAllHierarchiesVariables;
}

export const HierarchyNodeCellRenderer: React.FC<HierarchyNodeCellRendererProps> = ({
  params,
  rootHierarchy,
  setSelectedNode,
  setCommandCenterDrawerState,
  setInitialBlock,
  searchString,
  setSearchString,
  getAllHierarchiesVariables
}) => {
  const myRef = useRef(null);
  const { selectedPlanningCycle } = useScope();
  const [showDeleteHierarchyDialog, setShowDeleteHierarchyDialog] = useState<boolean>(false);
  const [updateHierarchyParent] = useUpdateHierarchyParent(null, null, null, setInitialBlock);

  const { isReadOnly } = params.context;

  const isInMultiSelectMode = params.columnApi?.['checkbox'];
  const isInFilteredView = searchString !== '';

  useEffect(() => {
    if (!isReadOnly) {
      params.registerRowDragger(myRef.current);
    }
  }, []);

  const handleExpand = () => {
    const prevSelectedNode = Object.values(params?.api?.['selectionService']?.selectedNodes)?.filter(
      (node) => node !== undefined
    )?.[0];
    prevSelectedNode?.['setSelected'](false);
    params.node?.setSelected(true);
    setSelectedNode(params);
    setCommandCenterDrawerState(CommandCenterDrawerState.EXPAND);
  };

  const handleDelete = () => {
    setShowDeleteHierarchyDialog(true);
  };

  const handleAddChild = () => {
    const newNode = {
      data: {
        hierarchyId: null,
        name: '',
        key: '',
        parentKey: params?.data?.key,
        address1: '',
        address2: '',
        city: '',
        country: '',
        industry: '',
        zipPostal: '',
        stateProvince: '',
        parent: params
      }
    } as ICellRendererParams;
    const prevSelectedNode = Object.values(params?.api?.['selectionService']?.selectedNodes)?.filter(
      (node) => node !== undefined
    )?.[0];
    prevSelectedNode?.['setSelected'](false);
    params.node?.setSelected(true);
    setSelectedNode(newNode);
    setCommandCenterDrawerState(CommandCenterDrawerState.EXPAND);
  };

  const handleReparentToRoot = () => {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('REPARENTING'), 'warning');
    updateHierarchyParent({
      variables: {
        hierarchyIds: [params?.data?.hierarchyId],
        newParentHierarchyId: rootHierarchy?.rootHierarchyId,
        planningCycleId: selectedPlanningCycle?.id
      }
    });
  };

  const renderOverflowMenu = () => {
    return (
      <div onClick={(e) => e.stopPropagation()} className={b('overflowMenu')}>
        <Popover content={renderMenuContent()} placement={'bottom-end'}>
          <div className={b('overflowIcon')}>
            <OverflowMenuHorizontal aria-label={formatMessage('OPTIONS')} data-testid="overflow-icon" />
          </div>
        </Popover>
      </div>
    );
  };

  const renderMenuContent = () => {
    if (isReadOnly) {
      return (
        <Menu>
          <MenuItem text={formatMessage('VIEW')} onClick={handleExpand} data-testid="menu-view-hierarchy" />
        </Menu>
      );
    }

    return (
      <Menu>
        <MenuItem text={formatMessage('EDIT')} onClick={() => handleExpand()} data-testid="menu-edit-hierarchy" />
        <MenuItem
          text={formatMessage('ADD_A_CHILD')}
          onClick={() => handleAddChild()}
          data-testid="menu-add-hierarchy"
        />
        {!params?.data?.hasChildren && !isInFilteredView && (
          <MenuItem
            text={formatMessage('REPARENT_TO_ROOT')}
            onClick={() => handleReparentToRoot()}
            data-testid="menu-move-hierarchy-to-root"
            disabled={params?.data?.parentKey === rootHierarchy?.rootKey}
          />
        )}
        <MenuItem
          style={{ color: 'rgb(var(--color-red-3))', borderTop: '1px solid rgb(var(--color-light-gray-4))' }}
          text={formatMessage('DELETE')}
          onClick={() => handleDelete()}
          data-testid="menu-delete-hierarchy"
        />
      </Menu>
    );
  };
  return (
    <div className={b('node')} data-testid="hierarchy-node-cell" ref={myRef}>
      <div className={b('nodeIcon')} data-testid="hierarchy-node-icon">
        {params?.data?.hasChildren ? <InventoryManagement /> : <Account />}
      </div>
      <div className={b('nodeContent')}>
        <div className={b('nodeTitle')} data-testid="hierarchy-node-name">
          {params?.data?.name}
        </div>
        {!isInMultiSelectMode && renderOverflowMenu()}
      </div>
      <ConfirmDeleteHierarchyModal
        selectedNode={params}
        rootHierarchy={rootHierarchy}
        showDeleteHierarchyDialog={showDeleteHierarchyDialog}
        setShowDeleteHierarchyDialog={setShowDeleteHierarchyDialog}
        setSelectedNode={setSelectedNode}
        setInitialBlock={setInitialBlock}
        searchString={searchString}
        setSearchString={setSearchString}
        getAllHierarchiesVariables={getAllHierarchiesVariables}
      />
    </div>
  );
};

export default HierarchyNodeCellRenderer;
