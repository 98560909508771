import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { ServerSideStoreType } from '@ag-grid-community/core';
import { useQuery } from '@apollo/client';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import buildTerritoryBalancingGridColumnDef from 'app/components/AdvancedGrid/GridHelpers/TerritoryBalancingGrid/buildTerritoryBalancingGridColumnDef';
import CoinsortProgress from 'app/components/CoinsortProgress/CoinsortProgress';

import { BALANCING_CELL_HEIGHT, BALANCING_HEADER_CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE, SHOW_LOADING_AFTER } from 'app/global/variables';

import {
  GetSheetMeasures,
  GetSheetMeasuresVariables,
  GetTerritoryRulesForRebalancing,
  GetTerritoryRulesForRebalancingVariables,
  SortableGetTerritoryRulesGridCols,
  SortDirection
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_SHEET_MEASURES } from 'app/graphql/queries/getSheetMeasures';
import { useGetTerritoryRulesForRebalancingLazy } from 'app/graphql/queries/getTerritoryRulesForRebalancing';

import useTreatment from 'app/hooks/useTreatment';

import {
  DefinitionFilters,
  Group,
  JobStatus,
  PendingSelectedBattleCardId,
  SheetDefinitionType,
  SourcePanel
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatNumberByMeasureType } from 'utils/helpers/currencyHelpers';
import { fallbackRebalancingMetric } from 'utils/helpers/fallbackRebalancingMetric';
import { formatMessage } from 'utils/messages/utils';

import BalancingPanel from './BalancingPanel/BalancingPanel';
import BalancingPanelDialog from './BalancingPanel/BalancingPanelDialog';
import BattleCardChangeDialog from './BalancingPanel/BattleCardChangeDialog';
import CoinsortDialog from './BalancingPanel/CoinsortDialog';
import EmptyPanel from './EmptyPanel/EmptyPanel';
import style from './TerritoryBalancingGrid.module.pcss';
import TerritoryBalancingGridRuleFilter, { CustomHierarchiesFilters } from './TerritoryBalancingGridRuleFilter';

const b = block(style);

interface TerritoryBalancingGridProps {
  setSaveStatus: Dispatch<SetStateAction<boolean>>;
  onSave: () => void;
}

const TerritoryBalancingGrid: React.FC<TerritoryBalancingGridProps> = ({ setSaveStatus, onSave }) => {
  const {
    battleCardLookupMap,
    setSelectedBattleCardId,
    selectedBattleCardId,
    selectedQuotaComponentId,
    pendingSelectedBattleCardId,
    setPendingSelectedBattleCardId,
    selectedBattleCardMeasures
  } = useBattleCard();
  const {
    selectedRebalancingRows,
    setSelectedRebalancingRows,
    selectedRebalancingMetric,
    setSelectedRebalancingMetric,
    setRebalancingMetricDropdownItems,
    setMetrics,
    isMoved,
    setIsMoved,
    isSaved,
    setIsSaved,
    selectedData,
    setSelectedData,
    sourcePanel,
    setSourcePanel
  } = useRebalancing();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { selectedDeploymentModelId } = useScope();
  const { defaultReportingCurrency } = useLocalization();

  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [showLeftConfirmDialog, setShowLeftConfirmDialog] = useState(false);
  const [showRightConfirmDialog, setShowRightConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [columnDefs, setColumnDefs] = useState(null);
  const [_, setDefinitionFilters] = useState<DefinitionFilters | null>(null);
  const { coinsortProgressLookupMap } = useCoinsort();

  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);
  const [baseRuleFiltersEnabled] = useTreatment(SplitFeatures.BASE_RULE_FILTERS);

  const containerRef = useRef(null);

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;
  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const [getTerritoryRules, { data: territoryRulesData, loading: territoryRulesDataLoading, fetchMore }] =
    useGetTerritoryRulesForRebalancingLazy({
      fetchPolicy: 'network-only',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    });

  const territoryRules = territoryRulesData?.getTerritoryRules?.territoryRules || [];
  const measureValueMean = territoryRulesData?.getTerritoryRules?.measureValueMean || 0;
  const territoryCount = territoryRulesData?.getTerritoryRules?.totalCount || 0;
  const totalAccountsMean = territoryRulesData?.getTerritoryRules?.totalAccountsMean || 0;

  // balancingMetricDropdownItems
  const { data, loading: sheetMeasuresDataLoading } = useQuery<GetSheetMeasures, GetSheetMeasuresVariables>(
    GET_SHEET_MEASURES,
    {
      fetchPolicy: 'network-only',
      variables: {
        deploymentModelId: selectedDeploymentModelId,
        battlecardId: +selectedBattleCardId,
        quotaComponentId: selectedQuotaComponentId
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const sheetMeasures = data?.getDeploymentModelSpec?.sheetMeasures || [];
  let balancingMetricDropdownItems;
  // filter and extract info from metrics list
  useEffect(() => {
    if (sheetMeasures.length > 0) {
      // only metric with sheetDefinitionType !== N/A can be used to perform rebalancing
      balancingMetricDropdownItems = sheetMeasures.filter(
        (item) => item.sheetDefinitionType !== SheetDefinitionType.NOT_BALANCING_METRIC
      );
      balancingMetricDropdownItems.forEach((item, index) => {
        balancingMetricDropdownItems[index] = {
          key: item.measureId,
          value: item.measureName
        };
      });
      setRebalancingMetricDropdownItems(balancingMetricDropdownItems);

      const fallbackBalancingMetric = fallbackRebalancingMetric({ measures: selectedBattleCardMeasures });

      const defaultBalancingMetric = sheetMeasures.find(
        (item) => item.sheetDefinitionType === SheetDefinitionType.DEFAULT_BALANCING_METRIC
      );
      if (defaultBalancingMetric) {
        setSelectedRebalancingMetric({
          key: defaultBalancingMetric.measureId,
          value: defaultBalancingMetric.measureName
        });
      } else {
        setSelectedRebalancingMetric(fallbackBalancingMetric);
      }
    }
  }, [sheetMeasures, selectedBattleCardId]);

  const rebalancingMetric = selectedRebalancingMetric?.key;

  const getTerritoryRulesParams = {
    variables: {
      input: {
        quotaComponentId: selectedQuotaComponentId,
        battlecardId: +selectedBattleCardId || null,
        territoryGroupId: +selectedPillId || null,
        measureId: +rebalancingMetric,
        sorting: {
          colId: SortableGetTerritoryRulesGridCols.territoryId,
          sort: SortDirection.asc
        },
        startRow: 1,
        endRow: BLOCK_SIZE
      }
    }
  };

  const selectedBattleCardCoinsortStatus = coinsortProgressLookupMap[selectedBattleCardId]?.coinsortStatus;

  // This useEffect is used to refetch the territory rules after coinsort completes
  useEffect(() => {
    if (
      selectedBattleCardId &&
      selectedQuotaComponentId &&
      rebalancingMetric &&
      selectedBattleCardCoinsortStatus === JobStatus.COMPLETED
    ) {
      getTerritoryRules(getTerritoryRulesParams);
    }
  }, [selectedBattleCardCoinsortStatus]);

  // This useEffect is used for the initial call of getTerritoryRules when the rebalancing gird loads
  useEffect(() => {
    if (selectedBattleCardId && selectedQuotaComponentId && rebalancingMetric) {
      getTerritoryRules(getTerritoryRulesParams);
    }
  }, [selectedBattleCardId, selectedQuotaComponentId, rebalancingMetric, selectedPillId]);

  useEffect(() => {
    if (isSaved) {
      closeLeftPanel();
      closeRightPanel();
    }
  }, [isSaved]);

  useEffect(() => {
    return () => {
      closeLeftPanel();
      closeRightPanel();
      setSelectedData([]);
      setSourcePanel(null);
      setIsMoved(false);
      setIsSaved(false);
    };
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (territoryRulesData) {
      setMetrics({
        totalAccountsCount: territoryRulesData?.getTerritoryRules?.totalAccountsCount || 0,
        totalAccountsMean: territoryRulesData?.getTerritoryRules?.totalAccountsMean || 0,
        measureValueMean: territoryRulesData?.getTerritoryRules?.measureValueMean || 0,
        measureValueStandardDeviation: territoryRulesData?.getTerritoryRules?.measureValueStandardDeviation || 0
      });
    }
  }, [territoryRulesData]);

  useEffect(() => {
    setSelectedRebalancingMetric(null);
  }, [selectedBattleCardId]);

  // only enable save button once two panels are open
  useEffect(() => {
    setSaveStatus(isLeftPanelOpen && isRightPanelOpen);
  }, [isLeftPanelOpen, isRightPanelOpen]);

  useEffect(() => {
    const timeoutTerritory = setTimeout(() => {
      if (territoryRulesDataLoading || sheetMeasuresDataLoading) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }, SHOW_LOADING_AFTER);

    return () => {
      clearTimeout(timeoutTerritory);
    };
  }, [territoryRulesDataLoading, sheetMeasuresDataLoading]);

  const measureType = useMemo(() => {
    return (
      selectedRebalancingMetric &&
      sheetMeasures.find((measure) => measure.measureId === selectedRebalancingMetric.key)?.measureFormatType
    );
  }, [sheetMeasures, selectedRebalancingMetric]);

  useEffect(() => {
    if (sheetMeasures.length) {
      setColumnDefs(
        buildTerritoryBalancingGridColumnDef(measureType, currency, memoToggleLeftDialog, memoToggleRightDialog)
      );
    }
  }, [battleCardLocalCurrencyCode, defaultReportingCurrency, measureType]);

  useEffect(() => {
    setIsLeftPanelOpen(selectedRebalancingRows[0] !== null);
    setIsRightPanelOpen(selectedRebalancingRows[1] !== null);
  }, [selectedRebalancingRows]);

  const switchBattleCard = () => {
    setSelectedBattleCardId(
      pendingSelectedBattleCardId === PendingSelectedBattleCardId.NONE ? null : pendingSelectedBattleCardId
    );
    setPendingSelectedBattleCardId(null);
  };

  const closeLeftPanel = () => {
    resetLeftSelectedRows();
    setIsLeftPanelOpen(false);
    setShowLeftConfirmDialog(false);
    if (isMoved || sourcePanel === SourcePanel.LEFT) {
      resetData();
      if (isRightPanelOpen) {
        setIsRightPanelOpen(false);
        resetRightSelectedRows();
      }
    }
  };

  const resetLeftSelectedRows = () => {
    setSelectedRebalancingRows((prevState) => {
      const newState = [...prevState];
      newState[0] = null;
      return newState;
    });
  };

  const toggleLeftDialog = () => {
    const shouldShowLeftConfirmDialog = (selectedData?.length && sourcePanel === SourcePanel.LEFT) || isMoved;
    if (shouldShowLeftConfirmDialog) {
      setShowLeftConfirmDialog((showLeftDialog) => !showLeftDialog);
    } else {
      closeLeftPanel();
    }
  };

  // function memoized to keep instance for rendering during tests
  const memoToggleLeftDialog = useMemo(
    () => toggleLeftDialog,
    [showLeftConfirmDialog, selectedData, isMoved, sourcePanel]
  );

  const closeRightPanel = () => {
    resetRightSelectedRows();
    setIsRightPanelOpen(false);
    setShowRightConfirmDialog(false);
    if (isMoved || sourcePanel === SourcePanel.RIGHT) {
      resetData();
      if (isLeftPanelOpen) {
        closeLeftPanel();
        resetLeftSelectedRows();
      }
    }
  };

  const resetRightSelectedRows = () => {
    setSelectedRebalancingRows((prevState) => {
      const newState = [...prevState];
      newState[1] = null;
      return newState;
    });
  };

  const toggleRightDialog = () => {
    const shouldShowRightConfirmDialog = (selectedData?.length && sourcePanel === SourcePanel.RIGHT) || isMoved;
    if (shouldShowRightConfirmDialog) {
      setShowRightConfirmDialog((showRightDialog) => !showRightDialog);
    } else {
      closeRightPanel();
    }
  };

  const memoToggleRightDialog = useMemo(
    () => toggleRightDialog,
    [showRightConfirmDialog, selectedData, isMoved, sourcePanel]
  );

  const resetData = () => {
    setSelectedData([]);
    setSourcePanel(null);
    setIsMoved(false);
  };

  const handleDefinitionFiltersUpdate = (selectedFilters: CustomHierarchiesFilters): void => {
    const groups: Group[] = [
      {
        clauses: Object.keys(selectedFilters)
          .map((key) => ({
            rootHierarchyId: parseInt(key),
            operator: selectedFilters[key].kind,
            ids: selectedFilters[key].items.map((item) => item.hierarchyId)
          }))
          .filter(({ ids }) => ids.length)
      }
    ];

    const definitionFilters = { groups };

    setDefinitionFilters(definitionFilters);
  };

  const handleModelUpdate = (gridEvent) => {
    const measureValue = formatNumberByMeasureType(measureValueMean, measureType, currency);
    const pinnedData = {
      measureValue: formatMessage('AVERAGE_WITH_NUMBER', { number: measureValue }),
      numberOfAccounts: formatMessage('AVERAGE_WITH_NUMBER', { number: Math.round(totalAccountsMean) })
    };
    gridEvent.api.setPinnedBottomRowData([pinnedData]);
  };

  const gridProps = {
    headerHeight: BALANCING_HEADER_CELL_HEIGHT,
    rowHeight: BALANCING_CELL_HEIGHT,
    onModelUpdated: handleModelUpdate,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    cacheBlockSize: BLOCK_SIZE,
    serverSideDatasource: {
      getRows: async (params) => {
        if (params?.request?.endRow === BLOCK_SIZE) {
          // no need to query for the first block as we already fetched it in the initial load
          params?.success({ rowData: territoryRules, rowCount: territoryCount });
        } else {
          // ag-grid starts row count from 0 and the backend pagination starts from 1
          if (getPaginationCheck(params.request.startRow, territoryCount)) {
            const fetchMoreTerritoryRules = await fetchMore<
              GetTerritoryRulesForRebalancing,
              GetTerritoryRulesForRebalancingVariables
            >({
              variables: {
                input: {
                  startRow: params?.request?.startRow + 1,
                  endRow: params?.request?.endRow,
                  territoryGroupId: +selectedPillId || null,
                  battlecardId: +selectedBattleCardId,
                  measureId: +selectedRebalancingMetric?.key,
                  quotaComponentId: selectedQuotaComponentId,
                  sorting: {
                    colId: SortableGetTerritoryRulesGridCols.territoryId,
                    sort: SortDirection.asc
                  }
                }
              }
            });
            const aggregatedList = fetchMoreTerritoryRules?.data?.getTerritoryRules?.territoryRules || [];
            params.success({ rowData: aggregatedList, rowCount: territoryCount });
          }
        }
      }
    }
  };

  return baseRuleFiltersEnabled ? (
    <div className={b('mainContainer')} data-testid="territory-grid-wrapper-with-filters">
      <TerritoryBalancingGridRuleFilter
        data-testid="territory-grid-header"
        handleDefinitionFiltersUpdate={handleDefinitionFiltersUpdate}
      />
      <div className={b('gridsWrapper')}>
        <div className={b('gridContainer')} ref={containerRef}>
          {isLoading || territoryRules?.length > 0 ? (
            <AdvancedGrid
              gridProps={gridProps}
              data-testid="territory-balancing-grid"
              columnDefs={columnDefs}
              gridHeight={containerRef?.current?.offsetHeight}
              gridWidth={containerRef?.current?.offsetWidth}
              showGridLoading={isLoading}
            />
          ) : (
            <AdvancedGrid
              data-testid="territory-balancing-empty-grid"
              columnDefs={columnDefs}
              gridHeight={containerRef?.current?.offsetHeight}
              gridWidth={containerRef?.current?.offsetWidth}
              headerHeight={BALANCING_HEADER_CELL_HEIGHT}
              noDataMessage={formatMessage('NO_GRID_TERRITORIES')}
            />
          )}
        </div>
        {isLeftPanelOpen ? (
          <BalancingPanel
            data-testid="balancing-panel-left"
            onClose={memoToggleLeftDialog}
            isLeftPanel={true}
            measureType={measureType}
          />
        ) : (
          <EmptyPanel />
        )}
        {isRightPanelOpen ? (
          <BalancingPanel
            data-testid="balancing-panel-right"
            onClose={memoToggleRightDialog}
            isLeftPanel={false}
            measureType={measureType}
          />
        ) : (
          <EmptyPanel />
        )}
        {(showLeftConfirmDialog || showRightConfirmDialog) && (
          <BalancingPanelDialog
            data-testid="balancing-panel-dialog"
            onClose={showLeftConfirmDialog ? memoToggleLeftDialog : memoToggleRightDialog}
            onConfirm={showLeftConfirmDialog ? closeLeftPanel : closeRightPanel}
          />
        )}
        {isSaved && <CoinsortDialog data-testid="coinsort-dialog" onClose={() => setIsSaved(false)} />}
        {!matchingEngineEnabled && <CoinsortProgress data-testid="coin-sort-progress" />}
        {pendingSelectedBattleCardId && (
          <BattleCardChangeDialog
            data-testid="battlecard-change-dialog"
            onClose={switchBattleCard}
            onConfirm={onSave}
          />
        )}
      </div>
    </div>
  ) : (
    <div className={b('gridsWrapper')} data-testid="grids-wrapper-no-filters">
      <div className={b('gridContainer')} ref={containerRef}>
        {isLoading || territoryRules?.length > 0 ? (
          <AdvancedGrid
            gridProps={gridProps}
            data-testid="territory-balancing-grid"
            columnDefs={columnDefs}
            gridHeight={containerRef?.current?.offsetHeight}
            gridWidth={containerRef?.current?.offsetWidth}
            showGridLoading={isLoading}
          />
        ) : (
          <AdvancedGrid
            data-testid="territory-balancing-empty-grid"
            columnDefs={columnDefs}
            gridHeight={containerRef?.current?.offsetHeight}
            gridWidth={containerRef?.current?.offsetWidth}
            headerHeight={BALANCING_HEADER_CELL_HEIGHT}
            noDataMessage={formatMessage('NO_GRID_TERRITORIES')}
          />
        )}
      </div>
      {isLeftPanelOpen ? (
        <BalancingPanel
          data-testid="balancing-panel-left"
          onClose={memoToggleLeftDialog}
          isLeftPanel={true}
          measureType={measureType}
        />
      ) : (
        <EmptyPanel />
      )}
      {isRightPanelOpen ? (
        <BalancingPanel
          data-testid="balancing-panel-right"
          onClose={memoToggleRightDialog}
          isLeftPanel={false}
          measureType={measureType}
        />
      ) : (
        <EmptyPanel />
      )}
      {(showLeftConfirmDialog || showRightConfirmDialog) && (
        <BalancingPanelDialog
          data-testid="balancing-panel-dialog"
          onClose={showLeftConfirmDialog ? memoToggleLeftDialog : memoToggleRightDialog}
          onConfirm={showLeftConfirmDialog ? closeLeftPanel : closeRightPanel}
        />
      )}
      {isSaved && <CoinsortDialog data-testid="coinsort-dialog" onClose={() => setIsSaved(false)} />}
      {!matchingEngineEnabled && <CoinsortProgress data-testid="coin-sort-progress" />}
      {pendingSelectedBattleCardId && (
        <BattleCardChangeDialog data-testid="battlecard-change-dialog" onClose={switchBattleCard} onConfirm={onSave} />
      )}
    </div>
  );
};

export default TerritoryBalancingGrid;
