export const white = '#ffffff';
export const black = '#000000';
const grey = '#b8b8b8';
export const MapStyleTokens = {
  colors: {
    assigned: '#c8a5f6',
    unassigned: white,
    nonInteractive: grey,
    lines: black,
    selectedLine: '#10239E',
    lasso: 'rgba(43, 79, 244, 0.5)',
    highwayColor: '#081F91',
    cluster: {
      text: white,
      outline: black,
      fill: '#0F437F',
      strokeColor: white
    },
    heatMap: {
      background: 'rgba(33, 102, 172, 0)',
      fill_level_1: '#e8f2fc',
      fill_level_2: '#bbd7f7',
      fill_level_3: '#3286e7',
      fill_level_4: '#1561b7',
      fill_level_5: '#0f437f'
    }
  },
  fonts: {
    pins: ['Open Sans Semibold', 'Arial Unicode MS Bold']
  },
  icons: {
    pinPrefix: 'map-pin-'
  },
  zooms: {
    continent: 2,
    country: 3.7,
    district: 5,
    state: 6.2,
    region: 9.3,
    city: 11
  }
} as const;

export const SourceIds = {
  account: {
    heat: 'source/heat',
    cluster: 'source/cluster'
  },
  customPinSet: 'source/custom-pin-set'
} as const;
export const LayerIds = {
  account: {
    heat: {
      heat: 'layer/account/heat/heat',
      circle: 'layer/heat/circle'
    },
    cluster: {
      symbol: 'layer/account/cluster/symbol',
      interactiveCircle: 'layer/account/cluster/circle--interactive'
    }
  },
  customPinSet: {
    symbol: 'layer/custom-pin-set/symbol'
  },
  mapboxLayers: {
    highways: 'layer/highways'
  }
} as const;

// These IDs are referenced in Mapbox studio styles (defined at config.MAPBOX_STYLE_URL)
export const PolygonSourceIds = {
  admin0: 'boundaries_admin_0',
  admin1: 'boundaries_admin_1',
  admin2: 'boundaries_admin_2',
  postal3: 'boundaries_postal_3',
  postal4: 'boundaries_postal_4'
} as const;

const usWorldViewLabelFilter = ['match', ['get', 'worldview'], ['all', 'US'], true, false];

export const originalLabelLayerFilters = [
  {
    id: 'country-label',
    filter: [
      'match',
      ['get', 'class'],
      'country',
      usWorldViewLabelFilter,
      'disputed_country',
      ['all', ['==', ['get', 'disputed'], 'true'], usWorldViewLabelFilter],
      false
    ]
  },
  {
    id: 'state-label',
    filter: [
      'match',
      ['get', 'class'],
      'state',
      usWorldViewLabelFilter,
      'disputed_state',
      ['all', ['==', ['get', 'disputed'], 'true'], usWorldViewLabelFilter],
      false
    ]
  },
  {
    id: 'settlement-major-label',
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 3],
      [
        'match',
        ['get', 'class'],
        'settlement',
        usWorldViewLabelFilter,
        'disputed_settlement',
        ['all', ['==', ['get', 'disputed'], 'true'], usWorldViewLabelFilter],
        false
      ],
      [
        'step',
        ['zoom'],
        false,
        8,
        ['<', ['get', 'symbolrank'], 11],
        10,
        ['<', ['get', 'symbolrank'], 12],
        11,
        ['<', ['get', 'symbolrank'], 13],
        12,
        ['<', ['get', 'symbolrank'], 15],
        13,
        ['>=', ['get', 'symbolrank'], 11],
        14,
        ['>=', ['get', 'symbolrank'], 13]
      ]
    ]
  },
  {
    id: 'settlement-minor-label',
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 3],
      [
        'match',
        ['get', 'class'],
        'settlement',
        usWorldViewLabelFilter,
        'disputed_settlement',
        ['all', ['==', ['get', 'disputed'], 'true'], usWorldViewLabelFilter],
        false
      ],
      [
        'step',
        ['zoom'],
        true,
        8,
        ['>=', ['get', 'symbolrank'], 11],
        10,
        ['>=', ['get', 'symbolrank'], 12],
        11,
        ['>=', ['get', 'symbolrank'], 13],
        12,
        ['>=', ['get', 'symbolrank'], 15],
        13,
        ['>=', ['get', 'symbolrank'], 11],
        14,
        ['>=', ['get', 'symbolrank'], 13]
      ]
    ]
  },
  {
    id: 'settlement-subdivision-label',
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        'settlement_subdivision',
        usWorldViewLabelFilter,
        'disputed_settlement_subdivision',
        ['all', ['==', ['get', 'disputed'], 'true'], usWorldViewLabelFilter],
        false
      ],
      ['<=', ['get', 'filterrank'], 4]
    ]
  },
  {
    id: 'poi-label',
    filter: ['<=', ['get', 'filterrank'], ['+', ['step', ['zoom'], 0, 16, 1, 17, 2], 1]]
  }
];
export const lassoStyle = [
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': MapStyleTokens.colors.lasso,
      'fill-outline-color': MapStyleTokens.colors.lasso
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 8,
      'circle-color': MapStyleTokens.colors.lasso
    }
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': MapStyleTokens.colors.lasso,
      'line-width': 1
    }
  },
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': MapStyleTokens.colors.lasso,
      'line-width': 1
    }
  }
];
