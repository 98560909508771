import {
  ColumnFunctionCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IServerSideDatasource,
  RowClassParams,
  ValueGetterParams,
  ValueSetterParams
} from '@ag-grid-community/core';
import { ApolloQueryResult } from '@apollo/client';
import dayjs from 'dayjs';
import capitalize from 'lodash.capitalize';
import clonedeep from 'lodash.clonedeep';

import DateRangeCellEditor from 'app/components/AdvancedGrid/CellEditors/DateRangeCellEditor/DateRangeCellEditor';
import DropdownMenuCellEditor from 'app/components/AdvancedGrid/CellEditors/DropdownMenuCellEditor/DropdownMenuCellEditor';
import FormatNumbersCellEditor from 'app/components/AdvancedGrid/CellEditors/FormatNumbersCellEditor/FormatNumbersCellEditor';
import SellerAssignmentCellEditor from 'app/components/AdvancedGrid/CellEditors/SellerAssignmentCellEditor/SellerAssignmentCellEditor';
import EditableFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableFieldCellRenderer/EditableFieldCellRenderer';
import ExpandableHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExpandableHeaderCellRenderer/ExpandableHeaderCellRenderer';
import LookupInfoHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/LookupInfoHeaderRenderer/LookupInfoHeaderRenderer';
import MessageTooltipHeaderRenderer from 'app/components/AdvancedGrid/CellRenderers/MessageTooltipHeaderRenderer/MessageTooltipHeaderRenderer';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';

import { MONTH_PRESET_ARRAY } from 'app/constants/DataTrayConstants';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { SplitFeatures } from 'app/global/features';
import {
  BLOCK_SIZE,
  MEASURES_GRID_COLUMN_WIDTH,
  MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_SMALL
} from 'app/global/variables';

import {
  GetTerritoryRulesForFields,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules,
  GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields,
  ISheetDefinitionDef,
  LookupTypeEnum,
  Owner
} from 'app/graphql/generated/apolloTypes';
import { UPSERT_TERRITORY_OWNERS } from 'app/graphql/mutations/upsertTerritoryOwners';

import {
  FieldIdsLookUpTable,
  GridHeaders,
  Lookup,
  MeasureFieldType,
  MeasureFormatType,
  MeasureSource,
  MonthMap,
  NumberInputFormatStyle,
  SellerSheetDefinitionsOrderLookupMap,
  SellerSheetGridColumnName,
  UpsertTerritoryFields
} from 'app/models';

import { getLocaleDateStringFromLocalISOStr } from 'utils/helpers/dateHelpers';
// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatCurrency, formatMessage, formatNumber } from 'utils/messages/utils';

export const getMonthlyMeasureName = (measureName: string, planDurationMonths: Record<string, number>): string => {
  const splitName = measureName.split(' ')[2];
  const planningCycleMonth = planDurationMonths[`Month ${splitName}`];
  return !!MonthMap[planningCycleMonth] ? MonthMap[planningCycleMonth] : measureName;
};

//Sort sheetDefinitions data to match with order of measures in design
export const getSortedSheetDefinitions = (sheetDefinitions: ISheetDefinitionDef[]): ISheetDefinitionDef[] => {
  const effectiveDateSheetColumn = { measureName: SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES };
  const sellerSheetColumn = { measureName: SellerSheetGridColumnName.SELLERS };
  const assignmentPercentageColumn = {
    measureName: SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE,
    measureFormatType: MeasureFormatType.PERCENTAGE
  };

  //Add extra measureName into sheetDefinitions to generate extra columns for value coming from getTerritoryRules api
  sheetDefinitions.push(effectiveDateSheetColumn);
  sheetDefinitions.push(sellerSheetColumn);
  sheetDefinitions.push(assignmentPercentageColumn);

  return sheetDefinitions.sort((firstMeasure, secondMeasure) => {
    const firstMeasureName = firstMeasure.measureName;
    const secondMeasureName = secondMeasure.measureName;
    return (
      SellerSheetDefinitionsOrderLookupMap[firstMeasureName] - SellerSheetDefinitionsOrderLookupMap[secondMeasureName]
    );
  });
};

export const getFormattedSheetDefinitions = (
  sheetDefinitions: ISheetDefinitionDef[],
  planningCycleDuration: number,
  planningCycleStartDate: string
): ISheetDefinitionDef[] => {
  //Separate between measure and monthly measures to reorder data from BE to match the order of measures in design
  const actualSellerQuotaDefinition = sheetDefinitions.filter((definition) => {
    return definition.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA;
  });

  const rampDefinitions = sheetDefinitions.filter((definition) => definition.measureName.includes('Month'));
  const sortedRampDefinitions = [...rampDefinitions].sort((a, b) => {
    // We need to use split function here because BE returns seasonality month as 'Ramp Month 1'
    const monthA = parseInt(a.measureName.split(' ')[2]);
    const monthB = parseInt(b.measureName.split(' ')[2]);
    return monthA - monthB;
  });

  const nonRampDefinitions = sheetDefinitions.filter((definition) => {
    return (
      !definition.measureName.includes('Month') &&
      definition.measureName !== SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA &&
      definition.measureName in SellerSheetDefinitionsOrderLookupMap
    );
  });

  //Format monthly measureName to match designer header for rendering
  const formattedRampDefinitions = sortedRampDefinitions.map((definition) => {
    const planDurationMonths = getPlanDurationMonths(planningCycleStartDate, planningCycleDuration);
    const formattedMeasureName = getMonthlyMeasureName(definition?.measureName, planDurationMonths);
    return { ...definition, measureName: formattedMeasureName };
  });

  //Filter out custom measure for ordering purpose
  const customDefinitions = sheetDefinitions.filter((definition) => {
    return (
      !definition.measureName.includes('Month') && !(definition.measureName in SellerSheetDefinitionsOrderLookupMap)
    );
  });

  const sortedNonRampDefinitions = getSortedSheetDefinitions(nonRampDefinitions);

  const combinedDefinitions = [
    ...sortedNonRampDefinitions,
    ...customDefinitions,
    ...actualSellerQuotaDefinition,
    ...formattedRampDefinitions
  ];
  return combinedDefinitions;
};

export const getPlanDurationMonths = (
  planningCycleStartDate: string,
  planningCycleDuration: number
): Record<string, number> => {
  const startMonth = new Date(planningCycleStartDate).getUTCMonth();
  const monthValues = {};
  for (let i = 1; i <= planningCycleDuration; i++) {
    monthValues[`Month ${i}`] = startMonth + i === 12 ? 12 : (startMonth + i) % 12;
  }

  return monthValues;
};

const getFormattedPercentage = (number) => {
  return formatNumber(number, { minimumFractionDigits: 2 });
};

export const getFormattedCellValue = (
  measureName: string,
  params: ValueGetterParams,
  currency = '',
  isSellerAssignmentTwoDecimalEnabled: boolean = false
): string | number => {
  const value = getCellValue(measureName, params);
  if (value) {
    if (measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE || measureName === GridHeaders.OWNERSHIP) {
      return isSellerAssignmentTwoDecimalEnabled ? `${getFormattedPercentage(value)}%` : `${value}%`;
    } else if (
      measureName === SellerSheetGridColumnName.RAMP ||
      measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES ||
      measureName === GridHeaders.EFFECTIVE_DATE
    ) {
      return value;
    } else {
      return formatCurrency(value as number, currency, 2);
    }
  } else if (measureName === SellerSheetGridColumnName.RAMP && params.data.isSingleSeller) {
    return formatMessage('NO_RAMP');
  }
  return null;
};

export const getCellValue = (measureName: string, params: ValueGetterParams): number | string => {
  if (params?.data?.owners?.length && params?.data?.isSingleSeller) {
    if (measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE || measureName === GridHeaders.OWNERSHIP) {
      return params.data.owners[0]?.ownership;
    } else if (
      measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES ||
      measureName === GridHeaders.EFFECTIVE_DATE
    ) {
      return getEffectiveDateValue(params);
    }
  }

  if (params?.data?.fields?.length) {
    const index = params?.data?.fields?.map((response) => response?.fieldName).indexOf(measureName);
    const value = params?.data?.fields[index]?.fieldValue;

    if (value) {
      return value;
    }
  }

  return null;
};

export const getEffectiveDateValue = (params: ValueGetterParams): string | null => {
  const effectiveStartDate = params?.data?.owners?.[0]?.effectiveStartDate;
  const effectiveEndDate = params?.data?.owners?.[0]?.effectiveEndDate;
  const hasStartDate = !!effectiveStartDate;
  const hasEndDate = !!effectiveEndDate;

  if (hasStartDate || hasEndDate) {
    return `${
      hasStartDate ? getLocaleDateStringFromLocalISOStr(effectiveStartDate) : formatMessage('NO_START_DATE')
    } - ${hasEndDate ? getLocaleDateStringFromLocalISOStr(effectiveEndDate) : formatMessage('NO_END_DATE')}`;
  } else {
    return null;
  }
};

// only $Value and %Value are right aligned  --> cellClassNames[0]
// only editable field have hover background --> cellClassNames[1]
export const getCellClass = (
  measureFormatType: string,
  isColumnEditable: boolean,
  cellClassNames: string[]
): string[] => {
  const classNames = [];
  const shouldCellRightAligned =
    measureFormatType === MeasureFormatType.CURRENCY ||
    measureFormatType === MeasureFormatType.NUMERIC ||
    measureFormatType === MeasureFormatType.PERCENTAGE;
  if (shouldCellRightAligned) classNames.push(cellClassNames[0]);
  if (isColumnEditable) classNames.push(cellClassNames[1]);

  return classNames;
};

export const formatRowData = (
  rowData: GetTerritoryRulesForFields_getTerritoryRules_territoryRules[],
  fieldIdsLookUpTable: FieldIdsLookUpTable
): GetTerritoryRulesForFields_getTerritoryRules_territoryRules[] => {
  const clone = clonedeep(rowData);
  const formattedTerritoryRules = clone?.map((rule) => {
    if (rule?.fields?.length) {
      rule.fields.map((field) => {
        field['fieldName'] = fieldIdsLookUpTable[field?.fieldId];
        // only show single seller data, territory rule with multiple seller their data will shows up in its own row
        if (
          (field.fieldName === SellerSheetGridColumnName.RAMP ||
            field.fieldName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT) &&
          rule?.owners?.length > 1
        ) {
          field.fieldValue = '';
        }

        return field;
      });
    }
    return { ...rule, isSingleSeller: rule?.owners?.length === 1 };
  });

  return formattedTerritoryRules;
};

export const generateSingleSellerFields = (
  aggregateFields: GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields[],
  ownerId: number
): GetTerritoryRulesForFields_getTerritoryRules_territoryRules_fields[] => {
  const sellerFields = [];
  aggregateFields?.forEach((aggregateField) => {
    if (aggregateField?.owners?.length) {
      const sellerField = { ...aggregateField };
      sellerField.owners = null;
      sellerField.fieldValue = aggregateField.owners.find((owner) => owner.ownerId === ownerId)?.fieldValue;
      sellerFields.push(sellerField);
    }
  });
  return sellerFields;
};

// header style for Monthly quota breakdown and actual seller quota are handled in ExpandableHeaderCellRenderer
// column header normally left aligned --> headerClassNames[0]
// column with $Value and %Value, header are right aligned --> headerClassNames[1]
export const getHeaderClass = (column: ISheetDefinitionDef, headerClassNames: string[]): string => {
  const isMonthlyQuotaColumn = isMonthlyBreakdownColumn(column.measureName);
  if (isMonthlyQuotaColumn || column.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA) {
    return null;
  }
  const measureFormatType = column.measureFormatType;
  if (
    measureFormatType === MeasureFormatType.CURRENCY ||
    measureFormatType === MeasureFormatType.NUMERIC ||
    measureFormatType === MeasureFormatType.PERCENTAGE
  ) {
    return headerClassNames[1];
  } else {
    return headerClassNames[0];
  }
};

export const getCellParams = (columnName: string, params?: ICellRendererParams): unknown => {
  if (columnName === SellerSheetGridColumnName.SELLERS) {
    return { header: SellerSheetGridColumnName.SELLERS, params };
  } else if (
    columnName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE ||
    columnName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES ||
    columnName === GridHeaders.OWNERSHIP ||
    columnName === GridHeaders.EFFECTIVE_DATE
  ) {
    return { hasBeenExpanded: false, measureName: columnName };
  } else if (columnName === SellerSheetGridColumnName.RAMP) {
    return { measureName: SellerSheetGridColumnName.RAMP };
  } else if (columnName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT) {
    return { measureName: SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT };
  }
  return null;
};

export const getCellComponent = (columnName: string): unknown => {
  if (columnName === SellerSheetGridColumnName.SELLERS) {
    return SellerAssignmentCellEditor;
  } else if (
    columnName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE ||
    columnName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES ||
    columnName === SellerSheetGridColumnName.RAMP ||
    columnName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT
  ) {
    return EditableFieldCellRenderer;
  }
  return null;
};

export const getCellEditorComponent = (column: ISheetDefinitionDef): unknown => {
  const { measureFieldType, measureSource, measureName } = column;
  if (measureFieldType === MeasureFieldType.INPUT && measureSource === MeasureSource.LOOKUP) {
    return DropdownMenuCellEditor;
  } else if (measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES) {
    return DateRangeCellEditor;
  } else if (
    measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE ||
    measureName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT
  ) {
    return FormatNumbersCellEditor;
  }

  return null;
};

export const getCellEditorParams = (
  column: ISheetDefinitionDef,
  lookups: Lookup[],
  params: ICellEditorParams,
  currency: string
): unknown => {
  const { measureFieldType, measureSource, measureName } = column;
  if (measureFieldType === MeasureFieldType.INPUT && measureSource === MeasureSource.LOOKUP) {
    const lookup = lookups.find((lookup) => lookup.lookupId === column?.fieldId);
    const selectMenuItems = (!!lookup?.metadata?.tableData ? Object.keys(lookup.metadata.tableData) : []).map(
      (lookupVal) => ({
        key: lookupVal,
        value: lookupVal
      })
    );
    return {
      items: selectMenuItems
    };
  } else if (measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES) {
    return {
      name: 'territoryEffectiveDates',
      effectiveStartDate: params?.data?.owners?.[0]?.effectiveStartDate,
      effectiveEndDate: params?.data?.owners?.[0]?.effectiveEndDate
    };
  } else if (measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE) {
    const value = getCellValue(measureName, params as unknown as ValueGetterParams);
    return {
      formatStyle: NumberInputFormatStyle.PERCENTAGE,
      placeHolder: formatMessage('ENTER_AMOUNT'),
      value,
      maximumFractionDigits: 2
    };
  } else if (measureName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT) {
    const value = getCellValue(measureName, params as unknown as ValueGetterParams);
    return {
      formatStyle: NumberInputFormatStyle.CURRENCY,
      placeHolder: formatMessage('ENTER_AMOUNT'),
      value,
      currency
    };
  }

  return null;
};

export const getColumnWidth = (column?: ISheetDefinitionDef): number => {
  if (column.measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES) {
    return MEASURES_GRID_COLUMN_WIDTH_EXTRA_LARGE;
  } else if (column.measureName === SellerSheetGridColumnName.SELLERS) {
    return MEASURES_GRID_COLUMN_WIDTH_LARGE;
  } else if (column.measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE) {
    return MEASURES_GRID_COLUMN_WIDTH_SMALL;
  }
  return MEASURES_GRID_COLUMN_WIDTH;
};

export const getHeaderComponent = (column: ISheetDefinitionDef): unknown => {
  const isMonthlyQuotaColumn = isMonthlyBreakdownColumn(column.measureName);
  if (column.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA || isMonthlyQuotaColumn) {
    return ExpandableHeaderCellRenderer;
  } else if (column.measureName === SellerSheetGridColumnName.RAMP && SplitFeatures.TQM) {
    return LookupInfoHeaderRenderer;
  } else if (column.measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE) {
    return MessageTooltipHeaderRenderer;
  }
  return null;
};

export const getHeaderParams = (column: ISheetDefinitionDef, previewDialogOpener: () => void): unknown => {
  const isMonthlyQuotaColumn = isMonthlyBreakdownColumn(column.measureName);

  if (column.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA || isMonthlyQuotaColumn) {
    return { areColumnsHidden: isMonthlyQuotaColumn };
  } else if (column.measureName === SellerSheetGridColumnName.RAMP && SplitFeatures.TQM) {
    return {
      displayName: GridHeaders.RAMP,
      hover: formatMessage('VIEW_RAMP_DATA'),
      tooltipMessage: formatMessage('RAMP_TOOLTIP_MESSAGE'),
      onButtonClicked: previewDialogOpener
    };
  } else if (column.measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE) {
    return {
      displayName: SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE,
      tooltipMessage: formatMessage('ASSIGNMENT_PERCENTAGE_TOOLTIP_MESSAGE')
    };
  }
  return null;
};

export const setCellValueForSellerEffectiveDates = (params: ValueSetterParams): boolean => {
  const newEffectiveStartDate = params.newValue.effectiveStartDate
    ? dayjs(params.newValue.effectiveStartDate).format('YYYY-MM-DD')
    : null;
  const newEffectiveEndDate = params.newValue.effectiveEndDate
    ? dayjs(params.newValue.effectiveEndDate).format('YYYY-MM-DD')
    : null;
  const isValueChanged =
    newEffectiveStartDate !== params.data.owners?.[0].effectiveStartDate ||
    newEffectiveEndDate !== params.data.owners?.[0].effectiveEndDate;

  params.data.owners[0].effectiveStartDate = newEffectiveStartDate;
  params.data.owners[0].effectiveEndDate = newEffectiveEndDate;
  params.data['isEffectiveDatesValueChanged'] = isValueChanged;

  return isValueChanged;
};

export const setCellValueForAssignmentPercentage = (params: ValueSetterParams): boolean => {
  const isValueChanged = +params.data.owners?.[0].ownership !== +params.newValue;
  params.data.owners[0].ownership = +params.newValue;
  params.data['isOwnershipValueChanged'] = isValueChanged;
  return isValueChanged;
};

export const setCellValueForRampAndQuotaAdjustment = (
  measureName: string,
  params: ValueSetterParams,
  isTQM: boolean,
  fieldIdsLookUpTable?: FieldIdsLookUpTable,
  fieldId?: number
): boolean => {
  const newValue = params.newValue;
  const oldValue = params.oldValue;
  const isFieldHasValue = params.data?.fields?.some((field) => field.fieldName === measureName);

  if (isFieldHasValue) {
    const field = params.data.fields.find((field) => field.fieldName === measureName);
    const currentFieldValue = field.fieldValue;
    field.isUpsertValue = currentFieldValue !== newValue;
    field.fieldValue = newValue;
  } else {
    const getFieldId = isTQM
      ? Object.keys(fieldIdsLookUpTable).find((key) => fieldIdsLookUpTable[key] === measureName)
      : fieldId;
    if (!params.data?.fields) {
      params.data.fields = [];
    }
    params.data.fields.push({
      fieldName: measureName,
      fieldValue: newValue,
      fieldId: +getFieldId,
      isUpsertValue: true
    });
  }

  params.data['isFieldValueChanged'] = newValue !== oldValue;
  return true;
};

export const setCellValue = (
  measureName: string,
  params: ValueSetterParams,
  isTQM: boolean,
  fieldIdsLookUpTable?: FieldIdsLookUpTable,
  fieldId?: number
): boolean => {
  if (!params?.newValue) {
    return false;
  }
  if (
    (measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES || measureName === GridHeaders.EFFECTIVE_DATE) &&
    params.data.owners
  ) {
    return setCellValueForSellerEffectiveDates(params);
  } else if (measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE || measureName === GridHeaders.OWNERSHIP) {
    return setCellValueForAssignmentPercentage(params);
  } else if (
    measureName === SellerSheetGridColumnName.RAMP ||
    measureName === SellerSheetGridColumnName.SELLER_QUOTA_ADJUSTMENT
  ) {
    return setCellValueForRampAndQuotaAdjustment(measureName, params, isTQM, fieldIdsLookUpTable, fieldId);
  } else {
    return false;
  }
};

export const upsertTerritoryOwners = async (
  tenantId: number,
  territoryId: number,
  owners: Owner[],
  quotaComponentId: number,
  errorMessage?: string
): Promise<boolean> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: UPSERT_TERRITORY_OWNERS,
      variables: {
        tenantId,
        territoryId,
        owners,
        quotaComponentId
      }
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(errorMessage, 'danger');
    return false;
  }
};

export const getTerritoryFieldIds = (fieldIdsLookUpTable: FieldIdsLookUpTable): number[] => {
  return Object.keys(fieldIdsLookUpTable)
    .map((str) => {
      return Number(str);
    })
    .filter((number) => !isNaN(number));
};

export const isMonthlyBreakdownColumn = (columnName: string): boolean => {
  return !!MONTH_PRESET_ARRAY.includes(columnName);
};

export const isEditableColumn = (
  column: ISheetDefinitionDef,
  selectedQuotaComponentId: number,
  params: ColumnFunctionCallbackParams | RowClassParams
): boolean => {
  if (!params?.data?.owners) {
    return false;
  }

  const { measureName, measureFieldType, measureSource, quotaComponents } = column;
  const isAssignmentPercentageColumn = measureName === SellerSheetGridColumnName.ASSIGNMENT_PERCENTAGE;
  const isSellerEffectiveDatesColumn = measureName === SellerSheetGridColumnName.SELLER_EFFECTIVE_DATES;
  const isALookupInputColumn = measureFieldType === MeasureFieldType.INPUT && measureSource === MeasureSource.LOOKUP;
  // For now, since TQM quota components do not account for roles so all roles have the same editable value, so FE will only check the first role
  const isMeasureEditable =
    quotaComponents?.find((qc) => qc.quotaComponentId === selectedQuotaComponentId)?.roles?.[0]?.editable === 'Y';
  if (isAssignmentPercentageColumn || isSellerEffectiveDatesColumn || isALookupInputColumn || isMeasureEditable) {
    return true;
  }
  return false;
};

export const flashColumnsWithUpdatedValue = (event: ICellRendererParams): void => {
  // TODO: implement flashing and instant update
  const gridApi = event.api;
  gridApi?.refreshServerSideStore({ purge: false });
};

export const renameColumnHeaders = (name: string, isTQM?: boolean): string => {
  if (name === SellerSheetGridColumnName.ACTUAL_TERRITORY_QUOTA) {
    name = isTQM ? SellerSheetGridColumnName.REVISED_TERRITORY_QUOTA : formatMessage('REVISED_TERRITORY_QUOTA');
  } else if (name === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA) {
    name = isTQM ? SellerSheetGridColumnName.ASSIGNED_SELLER_QUOTA : formatMessage('ASSIGNED_SELLER_QUOTA');
  }
  return capitalize(name);
};

export const generateDataSource = (
  fetchMore: (variables) => Promise<ApolloQueryResult<GetTerritoryRulesForFields>>,
  fetchMoreVariables: UpsertTerritoryFields,
  isTQM: boolean,
  rampFieldId?: number
): IServerSideDatasource => {
  const { quotaComponentId, battlecardId, measureId, sorting, totalCount, fieldIdsLookUpTable, fieldIds } =
    fetchMoreVariables;

  const dataSource = {
    getRows: async (params) => {
      if (params?.request?.groupKeys?.length > 0) {
        const parentRuleId = params?.parentNode?.data?.ruleId;
        const singleSellerRowsData = params?.parentNode?.data?.owners?.map((owner) => {
          return {
            ruleId: parentRuleId,
            territoryId: '',
            owners: [owner],
            fields: generateSingleSellerFields(params?.parentNode?.data?.fields, owner.ownerId),
            isSingleSeller: true
          };
        });
        params?.success({
          rowData: singleSellerRowsData.slice(params.request.startRow, params.request.endRow),
          rowCount: singleSellerRowsData.length
        });
      } else if (getPaginationCheck(params.request.startRow, totalCount) || params.request.endRow === BLOCK_SIZE) {
        const fetchMoreTerritoryRules = await fetchMore({
          variables: {
            startRow: params?.request?.startRow + 1,
            endRow: params?.request?.endRow,
            quotaComponentId,
            battlecardId,
            measureId,
            sorting,
            fieldIds
          }
        });

        const moreTerritoryRules = fetchMoreTerritoryRules?.data?.getTerritoryRules?.territoryRules || [];
        const rowData = formatRowData(
          moreTerritoryRules,
          isTQM ? fieldIdsLookUpTable : { [rampFieldId]: SellerSheetGridColumnName.RAMP }
        );
        params?.success({ rowData, rowCount: totalCount });
      }
    }
  };

  return dataSource;
};

export const upsertTerritoryOwnersHelper = async (
  params: ICellRendererParams,
  selectedQuotaComponentId: number,
  tenantId: number
): Promise<boolean> => {
  const data = params.data;
  const rowNode = params.node;
  const singleOwner = data?.territoryId;
  const ownersData = singleOwner ? data?.owners : rowNode?.parent?.data?.owners;
  const territoryId = singleOwner ? data?.ruleId : rowNode?.parent?.data?.ruleId;
  const owners = ownersData.map((owner) => ({
    ownerId: owner?.ownerId,
    memberId: owner?.memberId,
    ownership: owner?.ownership,
    effectiveStartDate: owner?.effectiveStartDate,
    effectiveEndDate: owner?.effectiveEndDate
  }));

  const isUpsertTerritoryOwnersSuccessful = await upsertTerritoryOwners(
    tenantId,
    territoryId,
    owners,
    selectedQuotaComponentId
  );

  return !!isUpsertTerritoryOwnersSuccessful;
};

export const getRampLookUpsItems = (lookups: Lookup[]): unknown => {
  const lookup = lookups.find((lookup) => lookup.lookupType === LookupTypeEnum.Ramp);
  const selectMenuItems = (!!lookup?.metadata?.tableData ? Object.keys(lookup.metadata.tableData) : []).map(
    (lookupVal) => ({
      key: lookupVal,
      value: lookupVal
    })
  );
  return {
    items: selectMenuItems
  };
};
