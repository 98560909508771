import { KeyValue } from 'components/models';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  CreateBattlecardVariables,
  UpdateBattlecardVariables,
  UpsertBattlecardVariables
} from 'app/graphql/generated/apolloTypes';
import { ADD_QUOTA_COMPONENT_TO_BATTLE_CARD } from 'app/graphql/mutations/addQuotaComponentToBattleCard';
import { REMOVE_QUOTA_COMPONENT_FROM_BATTLE_CARD } from 'app/graphql/mutations/removeQuotaComponentFromBattleCard';

import {
  HierarchyTopWithMemberId,
  HierarchyWithTypeAndFirstLevelMemberCount,
  MapLevelType,
  HierarchyType
} from 'app/models';

// eslint-disable-next-line no-restricted-imports
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export interface BattleCardDesignerDialogFormValues {
  cardName: string;
  currency: KeyValue<string>;
  cardType: KeyValue<string>;
  battleCardOwner: KeyValue<string> | null;
  quotaComponents: KeyValue<string>[];
  activityFiles: KeyValue<string>[];
  reorderableListItems: {
    hierarchies: string;
    hierarchyId: number;
    hierarchyTop: KeyValue<string>;
    hierarchyType: HierarchyType;
    id: number;
    referToAs: string;
    owner: KeyValue<string> | null;
  }[];
  mapLevel: KeyValue<MapLevelType>;
  quotaBreakdowns: HierarchyWithTypeAndFirstLevelMemberCount[];
  hierarchyBasedTarget: HierarchyWithTypeAndFirstLevelMemberCount;
}

export const addQuotaComponentToBattleCard = async (battleCardId: number, quotaComponentId: number): Promise<void> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: ADD_QUOTA_COMPONENT_TO_BATTLE_CARD,
      variables: { battlecardId: battleCardId, quotaComponentId }
    });
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('UPDATE_QUOTA_COMPONENT_ERROR'), 'danger');
  }
};

export const removeQuotaComponentFromBattleCard = async (
  battleCardId: number,
  quotaComponentId: number
): Promise<void> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    await apolloClient.mutate({
      mutation: REMOVE_QUOTA_COMPONENT_FROM_BATTLE_CARD,
      variables: { battlecardId: battleCardId, quotaComponentId }
    });
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('UPDATE_QUOTA_COMPONENT_ERROR'), 'danger');
  }
};

export const getCreateUpdateBCVariables = (
  isCreateMode: boolean,
  deploymentModelId: number,
  parentBCId: number,
  hierarchies: Partial<HierarchyTopWithMemberId>[],
  formSubmitValues: BattleCardDesignerDialogFormValues,
  isHierarchyBasedTargetEnabled: boolean,
  editingBattleCardId?: number
): CreateBattlecardVariables | UpdateBattlecardVariables | UpsertBattlecardVariables => {
  let variables;
  const quotaDistributionHierarchyIds = formSubmitValues.quotaBreakdowns.map((quotaBreakdown) => +quotaBreakdown.value);
  if (isCreateMode) {
    variables = {
      deploymentModelId,
      battlecardName: formSubmitValues.cardName,
      battlecardType: formSubmitValues.cardType.value,
      battlecardParentId: parentBCId,
      currencyCode: formSubmitValues?.currency?.value,
      quotaComponent: formSubmitValues.quotaComponents
        ? formSubmitValues.quotaComponents.map((quotaComponent) => {
            return quotaComponent.value;
          })
        : [],
      teams: [],
      hierarchies,
      fileIds:
        formSubmitValues?.activityFiles?.map((file) => {
          return file.value;
        }) ?? [],
      memberId: formSubmitValues.battleCardOwner?.value,
      mapLevel: formSubmitValues.mapLevel.value
    };
  } else {
    variables = {
      battlecardId: editingBattleCardId,
      deploymentModelId,
      battlecardName: formSubmitValues.cardName,
      battlecardType: formSubmitValues.cardType.value,
      currencyCode: formSubmitValues?.currency?.value,
      battlecardParentId: parentBCId,
      mapLevel: formSubmitValues.mapLevel.value
    };
  }

  if (!isHierarchyBasedTargetEnabled) {
    variables = { ...variables, quotaDistributionHierarchyIds };
  } else {
    const quotaDistributionHierarchies = quotaDistributionHierarchyIds.map((hierarchyId) => ({
      hierarchyRootId: hierarchyId,
      isAllocationTarget: false
    }));
    const validHierarchyBasedTarget =
      formSubmitValues.hierarchyBasedTarget &&
      quotaDistributionHierarchies.find(
        (hierarchy) => hierarchy.hierarchyRootId === +formSubmitValues.hierarchyBasedTarget.value
      );
    if (validHierarchyBasedTarget) {
      validHierarchyBasedTarget.isAllocationTarget = true;
    }
    variables = {
      input: {
        ...variables,
        quotaDistributionHierarchies
      }
    };
  }

  return variables;
};
