import React, { Dispatch, SetStateAction, useRef } from 'react';

import { Classes, MenuItem } from '@blueprintjs/core';
import {
  AddAlt,
  Calculation,
  ChevronDown,
  Currency,
  Calendar,
  StringInteger,
  Percentage,
  StringText,
  Code
} from '@carbon/icons-react';
import { diagnosticCount } from '@codemirror/lint';
// eslint-disable-next-line no-restricted-imports
import { Button, DropdownMenu, IconOnlyButton, Tag } from '@varicent/components';
import { ComputedColumnsEditor } from '@varicent/computed-columns-ui';
import { Field, useFormikContext } from 'formik';
import parser from 'prettier/plugins/babel';
import prettier from 'prettier/standalone';

import IconButton from 'components/Buttons/IconButton/IconButton';
import FormulaEditor from 'components/FormulaEditor/FormulaEditor';
import Popover from 'components/Popover/Popover';

import { calculationItems } from 'app/constants/ComputedColumnsConstants';

import { useData } from 'app/contexts/dataProvider';

import { SplitFeatures } from 'app/global/features';
import { DROPDOWN_MAX_HEIGHT, FORMULA_EDITOR_MIN_HEIGHT } from 'app/global/variables';

import useTreatment from 'app/hooks/useTreatment';

import { MeasureFormatType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldFormFields.module.pcss';
import { checkDiagnosticCount, handleFormatCode } from './SheetsAddFieldFormUtils';

const b = block(style);

const AddAltIcon = () => <AddAlt size={32} />;
const ChevronDownIcon = () => <ChevronDown size={32} />;

interface CalculatedFieldsPageProps {
  isEditable: boolean;
  setFormulaErrorCount: Dispatch<SetStateAction<number>>;
}

const iconMap = {
  Currency: <Currency />,
  Date: <Calendar />,
  Numeric: <StringInteger />,
  Percentage: <Percentage />,
  Text: <StringText />
};

const CalculatedFieldsPage: React.FC<CalculatedFieldsPageProps> = ({
  isEditable,
  setFormulaErrorCount
}: CalculatedFieldsPageProps) => {
  const { sheetDefinitions } = useData();
  const editorRef = useRef(null);
  const { values, errors, setFieldError, setFieldValue, setFieldTouched } = useFormikContext();
  const [useComputedColumnsLib] = useTreatment(SplitFeatures.IS_SHARED_COMPUTED_COLUMN_LIB_ENABLED);
  const [showPeriodicity] = useTreatment(SplitFeatures.PERIODICITY_CALC_COLUMN);

  const getSourceFields = () => {
    const sourceColumns = [];
    const sheetDefinitionWithPeriodicities = sheetDefinitions?.map((sheetDefinition) => {
      return sheetDefinition;
    });

    sheetDefinitionWithPeriodicities
      ?.filter((sheetDef) => sheetDef.measureId !== values['measureId'])
      .forEach((sheetDefinition) => {
        const { measureName, measureFormatType } = sheetDefinition;

        if (measureFormatType !== MeasureFormatType.DATE && measureFormatType !== MeasureFormatType.TEXT) {
          sourceColumns.push(
            useComputedColumnsLib
              ? {
                  columnName: measureName,
                  columnType: measureFormatType === MeasureFormatType.PERCENTAGE ? 'Percentage' : measureFormatType,
                  years: sheetDefinition['years']
                }
              : measureName
          );
        }
      });
    return sourceColumns;
  };

  const calculationSectionList = Array.from(new Set(calculationItems.map((item) => item.sectionName)));

  const renderCalculationItems = (sectionName) => {
    const calculationList = calculationItems.filter((item) => item.sectionName === sectionName);

    return calculationList.map((item) => {
      return (
        <DropdownMenu.Item
          key={item.name}
          text={item.name}
          data-testid={`insert-calc-menu-item-${item.name}`}
          label={
            item.template ? (
              <Tag
                style={{
                  fontFamily: 'IBM Plex Mono, monospace'
                }}
                className="vds-highlight-05"
              >
                {item.value}
              </Tag>
            ) : (
              item.value
            )
          }
          description={item.description}
          onClick={() => {
            if (item.template) {
              editorRef.current?.insertFunctionAtCursor(item.template);
            } else {
              editorRef.current?.insertTextAtCursor(item.value, item.value === '()' ? 1 : 0);
            }
          }}
        />
      );
    });
  };

  const columnItems = getSourceFields();

  const renderColumnNames = () => {
    const columnList = columnItems;

    return columnList.map((item) => {
      return (
        <React.Fragment key={`${item.columnName}`}>
          <MenuItem
            text={item.columnName}
            icon={iconMap[item.columnType]}
            intent="primary"
            data-testid={`menu-item-${item.columnName}`}
          >
            {item.years && (
              <>
                <MenuItem
                  text={item.columnName}
                  onClick={() => editorRef.current?.insertTextAtCursor(`"${item.columnName}"`)}
                  data-testid={`submenu-item-${item.columnName}`}
                />
                {item.years.map((periodicObj) => {
                  const handleSubmenuClick = (text) => {
                    editorRef.current?.insertTextAtCursor(`"${text}"`);
                  };
                  return (
                    <React.Fragment key={`sub-${item.columnName}-${periodicObj.year}`}>
                      {(periodicObj.periodicities as string[]).map((periodicity) => (
                        <MenuItem
                          key={`${item.columnName}.${periodicObj.year}.${periodicity}`}
                          text={`${item.columnName}.${periodicObj.year}.${periodicity}`}
                          onClick={() => handleSubmenuClick(`${item.columnName}.${periodicObj.year}.${periodicity}`)}
                          data-testid={`submenu-item-${item.columnName}-${periodicObj.year}-${periodicity}`}
                        />
                      ))}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </MenuItem>
        </React.Fragment>
      );
    });
  };

  const formatCode = (code) => {
    try {
      const result = prettier.format(code, {
        parser: 'babel',
        plugins: [parser],
        semi: false
      });
      return result;
    } catch {
      return code;
    }
  };

  const customActions = (
    <>
      <div>
        <DropdownMenu.Menu
          target={
            <IconOnlyButton
              icon={Calculation}
              minimal
              text={formatMessage('INSERT_CALCULATION')}
              data-testid="insert-calc-menu-btn"
            />
          }
          placement="bottom-start"
          content={
            <div
              style={{
                maxHeight: DROPDOWN_MAX_HEIGHT,
                overflow: 'auto'
              }}
            >
              {calculationSectionList.map((sectionName) => {
                return (
                  <div key={sectionName}>
                    <DropdownMenu.Heading data-testid={`insert-calc-menu-section-${sectionName}`}>
                      {sectionName}
                    </DropdownMenu.Heading>
                    {renderCalculationItems(sectionName)}
                  </div>
                );
              })}
            </div>
          }
        />
      </div>
      <div>
        <Popover
          content={<div>{renderColumnNames()}</div>}
          placement={'bottom-end'}
          className={b('menuCellPopover')}
          portalClassName={b()}
        >
          <Button
            icon={AddAltIcon}
            minimal
            rightIcon={ChevronDownIcon}
            text={formatMessage('DATA_SOURCE')}
            type="button"
            data-testid="insert-data-source-menu-btn"
          />
        </Popover>
      </div>
      <IconButton
        icon={<Code />}
        minimal
        onClick={() => handleFormatCode(editorRef, diagnosticCount, formatCode)}
        testId={'format-code-btn'}
        tooltipText={formatMessage('FORMAT_CODE')}
        type="button"
      />
    </>
  );

  const createCalculationItems = () => {
    const columnItems = getSourceFields();
    const durationItems = [];
    columnItems.forEach((item) => {
      item.years.forEach((periodicObj) => {
        periodicObj.periodicities.forEach((periodicity) => {
          durationItems.push({
            columnName: `${item.columnName}.${periodicObj.year}.${periodicity}`,
            columnType: item.columnType
          });
        });
      });
    });
    const calculationItemsWithPeriodicity = [...columnItems, ...durationItems];
    return calculationItemsWithPeriodicity;
  };

  return (
    <div className={b('calculatedFieldFormField')} data-testid="calculated-fields-page">
      <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('FORMULA')}</label>
      {useComputedColumnsLib ? (
        <Field
          name="calculatedFieldFormula"
          component={ComputedColumnsEditor}
          calculationItems={calculationItems}
          columnItems={showPeriodicity ? createCalculationItems() : getSourceFields()}
          showDefaultActions={!showPeriodicity}
          editorMinHeight={FORMULA_EDITOR_MIN_HEIGHT}
          readOnly={!isEditable}
          editorRef={editorRef}
          wrapDataSource={true}
          useDefaultExtensions={true}
          actions={showPeriodicity ? customActions : null}
          infoTooltipText={`${formatMessage('FORMULA_EDITOR_TOOLTIP_MESSAGE')}\n${formatMessage(
            'CALCULATED_FIELD_EXAMPLE'
          )}`}
          onUpdate={(view) => {
            if (view.docChanged) {
              setFieldError('calculatedFieldFormula', '');
              setFieldValue('calculatedFieldFormula', view.state.doc.toString(), false);
              setFieldTouched('calculatedFieldFormula', true, false);
            }
            checkDiagnosticCount(editorRef, diagnosticCount, setFormulaErrorCount);
          }}
        />
      ) : (
        <Field
          name="calculatedFieldFormula"
          component={FormulaEditor}
          columns={getSourceFields()}
          isEditable={isEditable}
        />
      )}
      <div className={b('validationMessage')} data-testid="calculated-field-formula-error">
        {errors?.['calculatedFieldFormula']}
      </div>
    </div>
  );
};

export default CalculatedFieldsPage;
