import { useMemo } from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { CustomHierarchyQuantifierEnum, VisualizationTerritoryGroupTypeEnum } from 'app/graphql/generated/apolloTypes';
import { useGetMapVisualizationSettings } from 'app/graphql/queries/getMapVisualizationSettings';

import useTreatment from 'app/hooks/useTreatment';

import { MapCustomHierarchySetting } from 'app/models';

export const useMapVisualizationSettings = (): {
  savedCustomHierarchyPreference: MapCustomHierarchySetting;
  shouldShowAccountFirstMap: boolean;
} => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);

  const { data } = useGetMapVisualizationSettings({
    fetchPolicy: 'network-only',
    skip: !isAccountFirstMapOn || !selectedBattleCardId || !selectedDeploymentModelId,
    variables: {
      input: {
        battlecardId: +selectedBattleCardId,
        deploymentModelId: selectedDeploymentModelId
      },
      planningCycleId: selectedPlanningCycle?.id
    }
  });

  const mapVisualizationSettings = data?.getDeploymentModelSpec?.battlecards?.[0]?.mapVisualizationSettings;
  const rootHierarchies = data?.getRootHierarchies;
  const shouldShowAccountFirstMap =
    mapVisualizationSettings?.territoryGroupType === VisualizationTerritoryGroupTypeEnum.CustomerAccountHierarchy;

  const savedCustomHierarchyPreference = useMemo(() => {
    if (!mapVisualizationSettings || !rootHierarchies) {
      return null;
    }
    switch (mapVisualizationSettings.customHierarchyQuantifier) {
      case CustomHierarchyQuantifierEnum.SINGULAR: {
        const { rootHierarchyId, rootKey, rootName } = rootHierarchies.find(
          (hierarchy) => hierarchy.rootHierarchyId === mapVisualizationSettings.customHierarchyId
        );
        return {
          quantity: CustomHierarchyQuantifierEnum.SINGULAR,
          details: {
            rootHierarchyId,
            rootKey,
            rootName
          }
        };
      }
      case CustomHierarchyQuantifierEnum.ALL:
      case CustomHierarchyQuantifierEnum.NONE:
        return {
          quantity: mapVisualizationSettings.customHierarchyQuantifier
        };
    }
  }, [mapVisualizationSettings, rootHierarchies]);

  return { savedCustomHierarchyPreference, shouldShowAccountFirstMap };
};
